@import '../../assets/styles/variables';

.container {
    width: 100%;
    max-width: 1752px;
    display: flex;
    justify-content: center;
    column-gap: 40px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;

    .containerLeft {
        width: 100%;
        max-width: 370px; //25%;
        -webkit-user-select: none; //Chrome all / Safari all
        -moz-user-select: none; //Firefox all */
        -ms-user-select: none; // IE 10+ */
        user-select: none; // Likely future */

        .menu {
            display: flex;
            flex-direction: column;
            max-width: 335px;
            width: 100%;
            height: auto;
            border-radius: 5px;
            background-color: #ffffff;
            border: 1px solid #e5e5e5;

            margin-bottom: 20px;
            padding: 20px;

            .menuTitle {
                font-size: 18px;
                letter-spacing: 0px;
                text-transform: uppercase;
                color: #000000;
                font-weight: 500;
                font-family: "Roboto";
                margin-bottom: 15px;
            }

            .point {
                width: 100%;
                display: flex;
                flex-direction: column;
                // margin-left: 20px;

                .myLink {
                    //width: 100%;
                    width: auto;
                    display: inline-block;
                    font-size: 16px;
                    letter-spacing: 0px;
                    line-height: 18px;
                    color: #141414;
                    font-weight: 400;
                    font-family: "Roboto";
                    cursor: pointer;
                    // text-decoration: underline;
                    // text-decoration-color: transparent;
                    // transition: 0.3s all ease-in-out;
                    margin: 8px 0 4px 0;
                    padding: 0 0 4px 0;

                    @include underline-hover(#141414);

                    // &:hover {
                    //     text-decoration-color: #141414;
                    // }
                }

                .title {
                    width: 100%;
                    display: flex;
                    white-space: nowrap;
                    font-size: 16px;
                    letter-spacing: 0px;
                    line-height: 30px;
                    color: #141414;
                    font-weight: 400;
                    font-family: "Roboto";
                    transition: 0.3s all ease-in-out;
                    cursor: pointer;

                    &:hover {
                        color: #a30005;
                    }

                    .char {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        font-size: 16px;
                        letter-spacing: 0px;
                        line-height: 24px;
                        color: #e1131a;
                        font-weight: 250;
                        font-family: "Roboto";
                        margin-right: 10px;

                        .open {
                            &::before {
                                content: "-";
                                padding-right: 2px;
                            }

                        }

                        .close {
                            &::before {
                                content: "+";
                            }
                        }
                    }
                }

                .titleOpen {
                    color: #e0131a;
                    font-weight: 500;

                    &:hover {
                        color: #e0131a;
                    }
                }

                .titleClose {
                    color: #141414;
                }

                .subPoints {
                    display: flex;
                    flex-flow: column;
                    width: calc(100% - 5px);
                    margin-left: 5px;
                    align-items: flex-start;
                    transition: height 0.5s ease-in-out;
                    height: 0;
                    overflow: hidden;

                    .subPoint {
                        display: inline-block;
                        font-size: 15px;
                        letter-spacing: 0px;
                        line-height: 25px;
                        color: #292929;
                        font-weight: 400;
                        font-family: "Roboto";
                        cursor: pointer;
                        padding: 5px 0 0 0;

                        @include underline-hover(#292929);

                        // text-decoration: underline;
                        // text-decoration-color: transparent;
                        // transition: 0.3s all ease-in-out;

                        // &:hover {
                        //     text-decoration-color: #292929;
                        // }
                    }
                }
            }
        }

        .filter {
            width: 100%;

            .fTitle {
                font-size: 18px;
                letter-spacing: 0px;
                text-transform: uppercase;
                color: #141414;
                font-weight: 400;
                font-family: "Roboto";
            }

            .filterContainer {
                //height: 500px;
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                padding-left: 15px;
                padding-right: 20px;
                overflow-y: hidden;
                transition: 1.0s all ease-in-out;

                .category {
                    margin-top: 5px;
                    padding-bottom: 5px;
                    margin-bottom: 5px;
                    border-bottom: 1px solid #e0e0e0;

                    .title {
                        font-size: 14px;
                        letter-spacing: 0px;
                        color: #141414;
                        font-weight: 500;
                        font-family: "Roboto";
                        cursor: pointer;
                        position: relative;

                        &.titleMark {
                            &::after {
                                content: "+";
                                color: #e0131a;
                                font-weight: 300;
                                position: absolute;
                                top: 0;
                                right: 5px;
                            }

                            &.active::after {
                                content: "-";
                            }
                        }
                    }



                    .categoryContainer {
                        display: flex;
                        flex-wrap: wrap;
                        overflow: hidden;
                        height: 45px; //37px; //195px;
                        //max-height: 195px;
                        transition: 0.3s height ease-in-out;

                        .categoryElement {
                            width: calc(100%/2 - 5px);
                            margin-top: 5px;
                        }
                    }
                }

                .price {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .pTitle {
                        font-size: 14px;
                        letter-spacing: 0px;
                        color: #141414;
                        font-weight: 400;
                        font-family: "Roboto";
                    }

                    .slider {
                        width: calc(100% - 10px);
                        margin-bottom: 10px;
                    }
                }

                .filtersButton {
                    width: 100%;
                    display: flex;
                    margin-top: 15px;
                    margin-bottom: 30px;
                }
            }
        }

        .new {
            display: flex;
            flex-direction: column;
            border-top: 1px solid #c3c3c3;
            padding-top: 20px;

            .title {
                font-size: 18px;
                text-transform: uppercase;
                color: #141414;
                font-weight: 400;
                font-family: "Roboto";
                border: none;
            }
        }

        .smallItem {
            border-top: 1px solid #e0e0e0;
        }

        .smallItem:nth-child(2) {
            border-top: none;
        }

        .newButton {
            width: 100%;
            display: flex;
            margin-top: 15px;
            margin-bottom: 30px;
        }

        .last {
            display: flex;
            flex-direction: column;
            border-top: 1px solid #c3c3c3;
            margin-top: 20px;
            padding-top: 20px;

            .title {
                font-size: 18px;
                text-transform: uppercase;
                color: #141414;
                font-weight: 400;
                font-family: "Roboto";
                border: none;
            }
        }

    }

    .containerRight {
        width: 100%;
        max-width: 1265px;

        .containerTop {
            width: 100%;
            padding-left: 30px;
            display: flex;
            flex-direction: column;

            .info {
                display: flex;
                column-gap: 50px;

                .description {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .title {
                        font-size: 30px;
                        letter-spacing: -1px;
                        color: #141414;
                        font-weight: 500;
                        font-family: "Roboto";
                        margin-top: 20px;
                        margin-bottom: 10px;
                    }

                    .text {
                        font-size: 13px;
                        letter-spacing: 0px;
                        line-height: 21px;
                        color: #5e5d5d;
                        font-weight: 400;
                        font-family: "Roboto";

                        p {
                            font-size: 13px;
                            letter-spacing: 0px;
                            line-height: 21px;
                            color: #5e5d5d;
                            font-weight: 400;
                            font-family: "Roboto";
                        }
                    }
                }

                .imgContainer {
                    display: flex;
                    width: 500px;
                    padding-right: 90px;
                    justify-content: flex-end;
                    align-items: center;
                }

                .img {
                    width: 250px;
                    height: 250px;
                    background-position: center;
                    background-size: 250px 250px;
                    background-repeat: no-repeat;
                    flex-shrink: 0;
                    -webkit-user-select: none; //Chrome all / Safari all
                    -moz-user-select: none; //Firefox all */
                    -ms-user-select: none; // IE 10+ */
                    user-select: none; // Likely future */
                }
            }

            .category {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 50px;

                .title {
                    width: auto;
                    font-size: 18px;
                    letter-spacing: 0px;
                    color: #383838;
                    font-weight: 400;
                    font-family: "Roboto";
                }

                .list {
                    display: flex;
                    width: 100%;
                    margin-top: 30px;

                    .subcategory {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        cursor: pointer;
                        width: 100%;
                        min-width: 0px;
                        transition: 0.3s all ease-in-out;

                        &:hover {
                            transform: scale(1.05);
                        }

                        .img {
                            width: 100px;
                            height: 110px;
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                        }

                        .title {
                            width: 70%;
                            font-size: 16px;
                            letter-spacing: 0px;
                            color: #050505;
                            font-weight: 500;
                            font-family: "Roboto";
                            text-align: center;
                            word-break: break-all;
                            overflow: hidden;
                            margin: 10px;

                        }
                    }
                }

            }
        }

        .containerCenter {
            width: 100%;
            border-top: 1px solid #989898;
            margin-top: 10px;
            padding-top: 20px;

            .filterTop {
                display: flex;
                flex-direction: row;
                align-items: center;

                .left {
                    display: flex;
                    align-items: center;
                    column-gap: 10%;
                    width: calc(100% - 100% / 3); //550px;
                    flex-shrink: 0;
                    user-select: none;

                    .sortButton {
                        flex-shrink: 0;
                        width: 25px; //25
                        height: 19px; //19
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        cursor: pointer;
                        transition: 0.5s all ease-in-out;

                        &:hover {
                            transform: scale(1.2);
                        }
                    }

                    .vertical {
                        background-image: url("../../assets/img/buttons/sort.png");
                    }

                    .horizontal {
                        background-image: url("../../assets/img/buttons/sort2.png");
                    }

                    .count {
                        display: flex;
                        column-gap: 10px;

                        .text2 {
                            font-size: 16px;
                            line-height: 18px;
                            color: #484848;
                            font-weight: 400;
                            font-family: "Roboto";
                        }

                        .number {
                            display: flex;
                            flex-direction: row;
                            //column-gap: 10px;
                            align-items: center;
                            justify-content: center;

                            .text {
                                width: 20px;
                                height: 20px;
                                font-size: 16px;
                                line-height: 18px;
                                color: #8b8989;
                                font-weight: 400;
                                font-family: "Roboto";
                                cursor: pointer;
                                transition: 0.2s all;
                                margin-left: 5px;
                                margin-right: 5px;

                                &:hover {
                                    font-size: 17px;
                                }
                            }

                            .textActive {
                                color: #484848;
                                text-decoration: underline;
                                transition: 1.0s all;

                                &:hover {
                                    font-size: 16px;
                                }
                            }
                        }

                    }
                }

                .right {
                    width: calc(100% / 3);
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .filterCenter {
                display: flex;
                flex-direction: column;

                .top {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    white-space: nowrap;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    .info {
                        width: 100%;
                        font-size: 16px;
                        letter-spacing: 0px;
                        line-height: 18px;
                        color: #484848;
                        font-weight: 400;
                        font-family: "Roboto";
                    }

                    .info>span {
                        padding-right: 3px;
                    }

                    // .navigation {
                    //     width: 100%;
                    // }

                    .empty {
                        width: 100%;
                    }
                }

                .filterList {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    column-gap: 20px;
                    row-gap: 30px;
                    position: relative;

                    .cover {
                        display: flex;
                        justify-content: center;
                        width: 51%;
                        height: 50%;
                        background-color: #ffffff;
                        position: absolute;
                        top: 25%;
                        left: 25%;
                        padding-top: 150px;
                        opacity: 0;
                        transition: 0.5s opacity ease-in-out;
                        transform: scale(2); //for biger animation
                        pointer-events: none;
                        z-index: 10;
                    }

                    .hide {
                        display: none;
                    }

                    .opacityFull {
                        opacity: 1;
                    }

                    .noPadding {
                        padding-top: 10px;
                    }
                }
            }

            .filterBot {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;

                .left {
                    width: 100%;
                }

                .center {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    column-gap: 10px;
                    white-space: nowrap;
                }

                .right {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }

        .containerBottom {
            margin-top: 100px;
            margin-bottom: 100px;

            .title {
                font-size: 16px;
                line-height: 18px;
                color: #383838;
                font-weight: 400;
                font-family: "Roboto";
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .text {
                font-size: 14px;
                line-height: 18px;
                color: #383838;
                font-weight: 400;
                font-family: "Roboto";

                h2 {
                    font-size: 16px;
                    line-height: 18px;
                    color: #383838;
                    font-weight: 400;
                    font-family: "Roboto";
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 14px;
                    line-height: 18px;
                    color: #383838;
                    font-weight: 400;
                    font-family: "Roboto";
                }

            }



        }
    }

}

.breadcrumb {
    padding-left: 30px;
}

.navigation {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 18px;
    line-height: 18px;
    color: #989898;
    font-weight: 500;
    font-family: "Roboto";
    user-select: none;

    .text {
        color: #989898;
        pointer-events: none;
        transition: 0.5s all;
    }

    .textActive {
        font-size: 19px;
        color: #383838;
        pointer-events: all;
    }

    .textUnderline {
        text-decoration: underline;
    }

    .button {

        cursor: pointer;
        user-select: none;
        padding: 4px 0 2px 0;

        @include underline-hover(#383838);
        // &:hover {
        //     transform: scale(1.025);
        // }
    }
}

.compareButton {
    width: 234px;
    height: 42px;
    border-radius: 15px;
    background-color: #e11319;
    border: 1px solid #e5e5e5;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 18px;
    line-height: 14px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Roboto";
    white-space: nowrap;

    user-select: none; // Likely future */

    transition: 0.5s all;

    &:hover {
        box-shadow: 1px 2px 15px 4px rgba(225, 19, 25, 0.25)
    }
}

.menuButton {
    width: 342px;
    height: 43px;
    border-radius: 5px;
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #1d1d1d;
    font-weight: 400;
    font-family: "Roboto";

    cursor: pointer;
    transition: 0.5s all;

    -webkit-user-select: none; //Chrome all / Safari all
    -moz-user-select: none; //Firefox all */
    -ms-user-select: none; // IE 10+ */
    user-select: none; // Likely future */

    &:hover {
        box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.2)
    }
}

.smallItem {
    display: flex;
    margin-right: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    white-space: nowrap;
    cursor: pointer;
    transition: 0.8s all ease-in-out;

    &:hover {
        box-shadow: 10px 5px 20px rgba(#0e0e0e, 0.2);
    }

    .left {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        .img {
            width: 100px;
            height: 100px; //19
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            border: 1px solid #e0e0e0;
            // cursor: pointer;
            // transition: 1.0s all ease-in-out;

            // &:hover {
            //     transform: scale(1.05);
            //     box-shadow: 1px 1px 8px rgba(#000000, 0.1);
            // }
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        width: 70%;

        .text {
            font-size: 14px;
            letter-spacing: 0px;
            line-height: 18px;
            color: #484848;
            font-weight: 400;
            font-family: "Roboto";
        }

        .price {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .priceNormalNet {
                font-size: 18px;
                letter-spacing: 0px;
                line-height: 18px;
                color: #000000;
                font-weight: 400;
                font-family: "Roboto";
            }

            .priceNormalGross {
                font-size: 14px;
                letter-spacing: 0px;
                line-height: 24px;
                color: #666666;
                font-weight: 400;
                font-family: "Roboto";
            }

            .priceOld {
                font-size: 14px;
                letter-spacing: 0px;
                line-height: 24px;
                color: #c5c5c5;
                font-weight: 400;
                font-family: "Roboto";
                text-decoration: line-through;
            }

            .priceNew {
                font-size: 18px;
                letter-spacing: 0px;
                line-height: 18px;
                color: #c41218;
                font-weight: 500;
                font-family: "Roboto";

            }
        }
    }
}

.checkbox {
    display: block;
    position: relative;

    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 14px;
    letter-spacing: 0px;
    color: #444a4b;
    font-weight: 400;
    font-family: "Roboto";

    padding: 2px 0;
    padding-left: 20px;

    word-break: break-word;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    border-radius: 2px;
    background-color: #fdfdfd;
    border: 1px solid rgba(69, 69, 69, 0.66);
}

/* Create */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* On mouse color*/
// .checkbox:hover input~.checkmark {
//     background-color: #e1131aa1;
// }

/* if check color */
.checkbox input:checked~.checkmark {
    background-color: #e11319;
    border: 1px solid #e41017;
}

/* Show symbol */
.checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator */
.checkbox .checkmark:after {
    left: 5px;
    top: 1px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.bottomMenu {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;

    .top {
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
    }

    .smallItem {
        width: 100%;
    }

    .bot {
        width: 100%;
    }

    .title {
        font-size: 18px;
        text-transform: uppercase;
        color: #141414;
        font-weight: 400;
        font-family: "Roboto";
        border: none;
        margin: 10px;
    }
}

.menuContainer {
    display: none;
    position: absolute;
    width: 100%;
    max-width: 410px;
    height: 100%;
    margin-top: 56px;
    left: 0;
    pointer-events: none
}

.filterMenuContainer {
    display: flex;
    flex-flow: row;
    width: 100%;
    max-width: 410px;
    overflow: hidden;
    z-index: 1020;
    height: 100vh;
    pointer-events: all;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.filterButton {
    background-image: url("../../assets/img/buttons/sort.png");
    background-size: 32px 28px;
    background-repeat: no-repeat;
    background-position: center;
    width: 32px;
    height: 28px;
    padding: 25px;
    border-radius: 15px;
    background-color: #efefef;
    border: 1px solid #e5e5e5;
    cursor: pointer;

    position: absolute;
    right: 0px;
    top: 0;
    z-index: 2000;
    margin-top: 10px;
    opacity: 0.6;
    transition: 1.0s opacity;
    user-select: none;

    &:hover {
        opacity: 1;
    }
}

.filterMenu {
    width: 100%;
    max-width: 350px;
    height: 100vh;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    user-select: none;
    padding: 0 15px 100px 15px;

    .menu {
        display: flex;
        flex-direction: column;

        width: 100%;
        height: auto; //462px;
        border-radius: 5px;
        background-color: #ffffff;

        margin-bottom: 20px;

        margin-bottom: 20px;
        padding: 20px;

        .menuTitle {
            font-size: 18px;
            letter-spacing: 0px;
            text-transform: uppercase;
            color: #000000;
            font-weight: 500;
            font-family: "Roboto";
            margin-bottom: 15px;
        }

        .point {
            width: 100%;
            display: flex;
            flex-direction: column;

            .myLink {
                //width: 100%;
                width: auto;
                display: inline-block;
                font-size: 16px;
                letter-spacing: 0px;
                line-height: 18px;
                color: #141414;
                font-weight: 400;
                font-family: "Roboto";
                cursor: pointer;
                // text-decoration: underline;
                // text-decoration-color: transparent;
                // transition: 0.3s all ease-in-out;
                margin: 10px 0;

                @include underline-hover(#141414);

                // &:hover {
                //     text-decoration-color: #141414;
                // }
            }

            .title {
                width: 100%;
                display: flex;
                white-space: nowrap;

                font-size: 16px;
                letter-spacing: 0px;
                line-height: 30px;
                color: #141414;
                font-weight: 400;
                font-family: "Roboto";

                cursor: pointer;

                .char {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    font-size: 16px;
                    letter-spacing: 0px;
                    line-height: 24px;
                    color: #e1131a;
                    font-weight: 250;
                    font-family: "Roboto";
                    margin-right: 10px;

                    .open {
                        &::before {
                            content: "-";
                            padding-right: 2px;
                        }

                    }

                    .close {
                        &::before {
                            content: "+";
                        }
                    }
                }
            }

            .titleOpen {
                color: #e0131a;
                font-weight: 500;
            }

            .titleClose {
                color: #141414;
            }

            .subPoints {
                display: flex;
                flex-flow: column;
                width: calc(100% - 5px);
                margin-left: 5px;
                align-items: flex-start;
                transition: height 0.5s ease-out;
                height: 0;
                overflow: hidden;

                .subPoint {
                    font-size: 16px;
                    letter-spacing: 0px;
                    line-height: 30px;
                    color: #292929;
                    font-weight: 400;
                    font-family: "Roboto";
                    cursor: pointer;
                }
            }
        }
    }

    .menuBorder {
        border: 1px solid #e5e5e5;
    }

    .menuMobile {
        border-bottom: 1px solid #e5e5e5;
        padding: 20px 5px;
    }

    .filter {
        width: 100%;

        .fTitle {
            font-size: 18px;
            letter-spacing: 0px;
            text-transform: uppercase;
            color: #141414;
            font-weight: 400;
            font-family: "Roboto";
            margin-left: 5px;
        }

        .filterContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            padding-left: 10px;
            padding-right: 10px;

            .category {
                margin-top: 5px;
                padding-bottom: 5px;
                margin-bottom: 5px;
                border-bottom: 1px solid #e0e0e0;

                .title {
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #141414;
                    font-weight: 500;
                    font-family: "Roboto";
                    cursor: pointer;
                    position: relative;

                    &.titleMark {
                        &::after {
                            content: "+";
                            color: #e0131a;
                            font-weight: 300;
                            position: absolute;
                            top: 0;
                            right: 5px;
                        }

                        &.active::after {
                            content: "-";
                        }
                    }
                }

                .categoryContainer {
                    display: flex;
                    flex-wrap: wrap;
                    overflow-y: hidden;
                    height: 30px; //195px;
                    max-height: 195px;
                    overflow-x: hidden;
                    transition: 1.0s height ease-in-out;

                    .categoryElement {
                        width: calc(100%/2 - 5px);
                        margin-top: 5px;
                    }
                }
            }

            .price {
                width: 100%;
                display: flex;
                flex-direction: column;

                .pTitle {
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #141414;
                    font-weight: 400;
                    font-family: "Roboto";
                }

                .slider {
                    width: calc(100% - 10px);
                    margin-bottom: 10px;

                    .someText {
                        font-size: 14px;
                    }
                }
            }

            .filtersButton {
                width: 100%;
                display: flex;
                margin-top: 15px;
                margin-bottom: 30px;
                user-select: none;
            }
        }
    }
}

.filterTransition {
    transition: all 1.0s ease-in-out;
}

.show {
    display: block;

}

.menuOpen {
    pointer-events: all;
    transform: translate(0px, 0);
}

.menuClose {
    transform: translate(-350px, 0);
}

.buttonOpen {
    right: 10px;
    margin: unset;
    opacity: 1;
}

.buttonClose {
    right: 0px;
    margin-top: 10px;
}

@media screen and (max-width: 1500px) {
    .container {
        .containerRight {
            .containerTop {
                .info {
                    .imgContainer {
                        width: 300px;
                        padding-right: 60px;
                    }

                    .img {
                        background-size: 200px 200px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1350px) {
    .container {

        .containerLeft {
            width: calc(370px * 0.85);

            .menu {
                font-size: 14px;

                .point {
                    .title {
                        font-size: 14px;
                    }

                    .subPoints {
                        .subPoint {
                            font-size: 14px;
                        }
                    }
                }
            }

            .filter {
                .fTitle {
                    font-size: 16px;
                }
            }

            .new {

                .title {
                    font-size: 16px;

                }
            }

            .last {
                .title {
                    font-size: 16px;
                }
            }
        }

        .containerRight {
            width: calc(100% - 300px);

            .containerCenter {
                .filterCenter {
                    .top {
                        .info {
                            font-size: 14px;
                        }
                    }
                }
            }

        }
    }

    .compareButton {
        width: calc(234px * 0.9); //234px;
        height: calc(42px * 0.9); //42px;
        font-size: 14px;
    }

    .smallItem {
        margin: 0;
        width: calc(100% + 10px);

        .right {
            .text {
                font-size: 13px;
            }

            .price {

                .priceNormalNet {
                    font-size: 16px;
                }

                .priceNormalGross {
                    font-size: 12px;
                }

                .priceOld {
                    font-size: 12px;
                }

                .priceNew {
                    font-size: 16px;
                }
            }
        }
    }

    .navigation {
        font-size: 14px;
    }
}

//no left menu
@media screen and (max-width: 1200px) {
    .container {
        column-gap: 0px;
        margin: unset;
        width: 100%;

        .containerLeft {
            display: none;
        }

        .containerRight {
            width: 100%;

            .containerTop {
                padding-left: 0;

                .info {
                    .imgContainer {
                        padding-right: unset;
                    }
                }

            }

            .containerCenter {

                .filterTop {
                    flex-wrap: wrap-reverse;
                }

                .filterCenter {
                    .top {
                        .info {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .breadcrumb {
        padding-left: 0;
    }

    .smallItem {

        .right {
            .text {
                font-size: 13px;
            }

            .price {

                .priceNormalNet {
                    font-size: 18px;
                }

                .priceNormalGross {
                    font-size: 14px;
                }

                .priceOld {
                    font-size: 14px;
                }

                .priceNew {
                    font-size: 18px;
                }
            }
        }
    }

    .navigation {
        justify-content: center;
        font-size: 16px;
    }

    .menuContainer {
        display: flex;
    }
}

//small item more center
@media screen and (max-width: 800px) {
    .container {
        .containerRight {
            .containerTop {
                .info {
                    .title {
                        align-self: center;
                    }

                    .imgContainer {
                        width: 100%;
                        justify-content: center;
                    }
                }
            }

            .containerCenter {
                .filterTop {
                    .left {
                        align-items: flex-start;
                        flex-wrap: wrap-reverse;
                        row-gap: 25px;
                        width: 100%;

                        .sortButton {
                            align-self: center;
                            //margin-left: 20%;
                            width: 30px;
                            margin-left: auto;
                            margin-right: auto;
                        }

                        .count {
                            width: 100%;
                            justify-content: center;
                        }
                    }

                    .right {
                        width: 100%;
                        justify-content: center;
                    }
                }

                .filterBot {
                    flex-wrap: wrap;

                    .center {
                        margin-bottom: 20px;
                    }

                    .right {
                        justify-content: center;
                    }
                }
            }
        }
    }

    .compareButton {
        min-width: 280px;
        width: 50%; //234px;
        height: 42px; //42px;
        font-size: 18px;
        margin-bottom: 20px;
        align-self: center;
    }

    .bottomMenu {
        .smallItem {
            width: 300px;
            margin-left: 10px;
            margin-right: 10px;

            &:hover {
                box-shadow: 10px 5px 20px rgba(#0e0e0e, 0.15);
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .containerRight {
        .containerTop {
            .category {
                .list {
                    justify-content: center;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .container {
        .containerRight {
            .containerCenter {
                .filterCenter {
                    .top {
                        flex-wrap: wrap;

                        .empty {
                            width: 0px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .container {
        .containerRight {
            .containerTop {
                .info {
                    .description {
                        .text {
                            margin-top: 20px;
                            width: calc(100vw - 40px);
                        }
                    }
                }
            }
        }
    }

    .navigation {
        width: 100%;
        justify-content: center;
        margin-top: 10px;
    }

    .menuContainer {
        margin-top: 130px;
    }
}

@media screen and (max-width: 500px) {
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 480px) {
    .container {
        .containerRight {
            .containerTop {
                // .info {
                //     .description {
                //         .title {
                //             font-size: 26px;
                //         }
                //     }
                // }

                .category {
                    .list {
                        .subcategory {
                            .title {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .smallItem {
        .left {

            .img {
                width: 80px;
                height: 80px; //19
            }
        }

        .right {
            .text {
                font-size: 13px;
            }

            .price {

                .priceNormalNet {
                    font-size: 16px;
                }

                .priceNormalGross {
                    font-size: 12px;
                }

                .priceOld {
                    font-size: 12px;
                }

                .priceNew {
                    font-size: 16px;
                }
            }
        }
    }
}

//smaller menu, 410 because button have 10px margin
@media screen and (max-width: 410px) {
    .container {
        .containerRight {
            .containerTop {
                .info {
                    .description {
                        .title {
                            font-size: 26px;
                        }
                    }
                }
            }
        }
    }

    .filterMenuContainer {
        width: 100%; //310px;
    }

    .filterMenu {
        max-width: 360px;
        width: calc(100vw - 50px); //270px;

        .menu {
            font-size: 14px;

            .point {
                .myLink {
                    font-size: 14px;
                }

                .title {
                    font-size: 14px;
                }

                .subPoints {
                    font-size: 14px;
                }
            }
        }

        .filter {
            .fTitle {
                font-size: 16px;
            }
        }
    }

    .filterButton {
        background-size: 22px 19px;
        width: 22px; //32px;
        height: 19px; //28px;
        padding: 19px;
        border-radius: 15px;
        //right: -5px;
    }

    .menuClose {
        transform: translate(calc((100vw - 50px) * -1), 0);
    }
}

@media screen and (max-width: 400px) {
    .container {
        .containerRight {
            .containerCenter {
                .filterTop {
                    .left {
                        .sortButton {
                            width: calc(80% - 175px);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .bottomMenu {
        width: calc(100% + 20px);
        position: relative;
        left: -10px;
    }

    .smallItem {
        width: 220px;
        margin-right: 0;

        .left {

            .img {
                width: 75px;
                height: 75px;
            }
        }

        .right {
            .text {
                font-size: 13px;
            }

            .price {

                .priceNormalNet {
                    font-size: 15px;
                }

                .priceNormalGross {
                    font-size: 12px;
                }

                .priceOld {
                    font-size: 12px;
                }

                .priceNew {
                    font-size: 15px;
                }
            }
        }
    }
}