.carousel {
  width: 100%;
  display: flex;
  margin-top: 50px;
  
}

.arrow {
  background-image: url("../../../../assets//img/other/arrowRightGray.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 25px;
  outline: unset;
  border: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff; //transparent;
  cursor: pointer;
  transition: 0.5s opacity 0.5s transform;
}

.arrow:disabled {
  opacity: 0;
  pointer-events: none;
}

.leftArrowThum {
  left: -50px;
  transform: rotate(180deg) translateY(50%);
  transition: transform 0.5s;

  &:hover {
    transform: rotate(180deg)translateY(50%) scale(1.2);
  }
}

.rightArrowThum {
  right: -50px;
  transition: transform 0.5s;

  &:hover {
    transform: scale(1.2) translateY(-40%);
  }
}

.item {
  display: flex;
  justify-content: center;
}

.track{
  width: 100%;
  padding: 15px;
}

@media screen and (max-width: 1100px) {
  .leftArrowThum {
    left: -40px;
  }

  .rightArrowThum {
    right: -40px;
  }
}