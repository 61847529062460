.text{
    font-size: 24px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 500;
    text-align: center;
    font-family: "Roboto";
    padding-bottom: 15px;
    user-select: none;
    margin: 50px 0;
}