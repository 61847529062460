.breadcrumbContainer {
    display: flex;
    justify-content: flex-start;
    box-shadow: inset 2.5px 4.33px 2px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    margin-bottom: 10px;
}

.breadcrumb {
    width: 100%;
    max-width: 1770px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px 115px 10px 115px;
    margin-left: auto;
    margin-right: auto;
    appearance: none;
    box-shadow: inset 2.5px 4.33px 2px rgba(0, 0, 0, 0.04);
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .containerMain {
        max-width: 1752px;
        width: 100%;
        display: flex;
        padding: 0 40px;

        .containerLeft {
            width: 100%;
            max-width: 370px;
            display: flex;
            flex-direction: column;

            .menu {
                display: flex;
                flex-direction: column;
                max-width: 335px;
                width: 100%;
                height: auto;
                border-radius: 5px;
                background-color: #ffffff;
                border: 1px solid #e5e5e5;

                margin-bottom: 20px;
                padding: 20px;

                .menuTitle {
                    font-size: 18px;
                    letter-spacing: 0px;
                    text-transform: uppercase;
                    color: #000000;
                    font-weight: 500;
                    font-family: "Roboto";
                    margin-bottom: 15px;
                }

                .point {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    // margin-left: 20px;

                    .myLink {
                        //width: 100%;
                        width: auto;
                        display: inline-block;
                        white-space: nowrap;
                        font-size: 16px;
                        letter-spacing: 0px;
                        line-height: 30px;
                        color: #141414;
                        font-weight: 400;
                        font-family: "Roboto";
                        cursor: pointer;
                        text-decoration: underline;
                        text-decoration-color: transparent;
                        transition: 0.3s all ease-in-out;

                        &:hover {
                            text-decoration-color: #141414;
                        }
                    }

                    .title {
                        width: 100%;
                        display: flex;
                        white-space: nowrap;
                        font-size: 16px;
                        letter-spacing: 0px;
                        line-height: 30px;
                        color: #141414;
                        font-weight: 400;
                        font-family: "Roboto";
                        transition: 0.3s all ease-in-out;
                        cursor: pointer;

                        &:hover {
                            color: #000000;
                        }

                        .char {
                            width: 100%;
                            display: flex;
                            justify-content: flex-end;
                            font-size: 16px;
                            letter-spacing: 0px;
                            line-height: 24px;
                            color: #e1131a;
                            font-weight: 250;
                            font-family: "Roboto";
                            margin-right: 10px;

                            .open {
                                &::before {
                                    content: "-";
                                    padding-right: 2px;
                                }

                            }

                            .close {
                                &::before {
                                    content: "+";
                                }
                            }
                        }
                    }

                    .titleOpen {
                        color: #e0131a;
                        font-weight: 500;

                        &:hover {
                            color: #e0131a;
                        }
                    }

                    .titleClose {
                        color: #141414;
                    }

                    .subPoints {
                        display: flex;
                        flex-flow: column;
                        width: calc(100% - 5px);
                        margin-left: 5px;
                        align-items: flex-start;
                        transition: height 0.5s ease-in-out;
                        height: 0;
                        overflow: hidden;

                        .subPoint {
                            display: inline-block;
                            font-size: 15px;
                            letter-spacing: 0px;
                            line-height: 30px;
                            color: #292929;
                            font-weight: 400;
                            font-family: "Roboto";
                            cursor: pointer;

                            border-bottom: 1px solid transparent;
                            transition: 0.3s all ease-in-out;

                            &:hover {
                                border-bottom: 1px solid #141414;
                            }
                        }
                    }
                }
            }
        }

        .containerRight {
            width: 100%;
            display: flex;

            .imgCert {
                width: 423px;
                height: 199px;
                background-image: url("../../assets/img/Information/cert.jpg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin: auto;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .container .containerMain .containerLeft {
        display: none;
    }
}