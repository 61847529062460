@mixin checkbox($width: 21px) {
  .checkbox {
    width: $width;
    height: $width;
    display: flex;
    -webkit-appearance: none;
    appearance: none;
    border: unset;
    position: relative;
    flex-shrink: 0;
    z-index: 1;
    user-select: none;
    cursor: pointer;
  }

  .checkbox::after {
    content: "";
    width: $width;
    height: $width;
    flex-shrink: 0;
    background-color: #ffffff;
    border: 1px solid #dfdfdf;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.1s all ease-in-out;
  }

  .checkbox:checked::after {
    content: "";
    width: $width;
    height: $width;
    background-color: #e11319;
    border-color: #e41017;
    position: absolute;
    top: 0;
    left: 0;
  }

  .checkbox::before {
    content: "";
    width: $width;
    height: $width;
    flex-shrink: 0;
    background-color: transparent;
    transition: 0.1s all ease-in-out;
    z-index: 5;
    scale: 0.8;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    position: relative;
    top: 0px;
    left: 1px;
  }

  .checkbox:checked::before {
    background-color: #ffffff;
  }
}

@mixin underline-hover($color: #ffffff) {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(90deg, $color, $color);
  background-size:
    100% 2px,
    0 2px;
  background-position:
    100% 100%,
    0 100%;
  background-repeat: no-repeat;
  transition: 0.3s ease-in-out;

  &:hover {
    background-size:
      0 2px,
      100% 2px;
  }

  &.active {
    font-weight: 600;
    background-size:
      0 2px,
      100% 2px;
  }
}

@mixin left-right-hover($colorHover: #e1131a, $color: #ffffff) {
  .text {
    color: transparent;
    background-image: linear-gradient(90deg, $colorHover 50%, $color 50%);
    background-position: 100%;
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    transition: background-position 0.5s ease-in-out;
  }

  &:hover {
    .text {
      background-position: 0;
    }
  }
}

@mixin ellipsis($lines: 3, $line-height: 24px ){
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  line-height: $line-height;
  max-height: calc($lines * $line-height);
}