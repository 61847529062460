.container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px;

    .mainContainer {
        max-width: 1600px;
        width: 100%;
        display: flex;

        .list {
            max-width: 1450px;
            width: 100%;
            display: flex;
            flex-direction: column;
            column-gap: 40px;
            border-radius: 10px;
            background-color: #ffffff;
            border: 1px solid #eeeeee;
            padding: 15px;
            position: relative;
        }

        .element {
            width: 100%;
            display: flex;
            border-radius: 10px;
            background-color: #ffffff;
            border: 1px solid #eeeeee;
            padding: 15px;
            margin: 0 0 15px 0;

            .table {
                width: 100%;
                display: flex;
                flex-direction: column;

                .tableRow {
                    display: flex;
                    justify-content: space-between;
                }

                .cell {
                    display: flex;
                    flex-direction: column;
                }

                .cellNormal {
                    font-size: 16px;
                    color: #2a2a2a;
                    font-weight: 400;
                    font-family: "Roboto";
                }

                .cellBold {
                    font-size: 16px;
                    color: #2a2a2a;
                    font-weight: 500;
                    font-family: "Roboto";
                    margin: 0 0 5px 0;
                }

                .cellCenter {
                    display: flex;
                    justify-content: center;
                }

                .cell1 {
                    max-width: 150px;
                    width: 100%;
                    text-align: center;
                }

                .cell2 {
                    max-width: 150px;
                    width: 100%;
                    text-align: center;
                }

                .cell3 {
                    max-width: 150px;
                    width: 100%;
                    text-align: center;
                }

                .cell4 {
                    max-width: 400px;
                    width: 100%;
                    text-align: center;
                }

                .cell5 {
                    max-width: 150px;
                    width: 100%;
                    text-align: center;
                }

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    height: 30px;
                    border-radius: 20px;
                    background-color: #f3f3f3;
                    border: 1px solid #d9d9d9;
                    font-size: 14px;
                    letter-spacing: 0px;
                    line-height: 18px;
                    color: #141414;
                    font-weight: 500;
                    font-family: "Roboto";
                    -webkit-user-select: none;
                    user-select: none;
                    cursor: pointer;
                    transition: 0.3s all ease-in-out;
                    padding: 5px 10px;
                }
            }
        }
    }
}