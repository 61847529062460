.pagination {
  display: flex;
  align-items: center;
  padding: unset;
  margin: 0 auto 32px;
  flex-wrap: unset;

  li {
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    margin: 0 7px;
  }
}

.paginationButton {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #f2f2f2;
  border: unset;
  cursor: pointer;
  padding: unset;
  transition: 0.3s;
}

.paginationButton.is-active {
  transform: scale(0.6);
  background-color: #df1319;
}

.carousel {
  width: 100%;
  padding: 0 40px;
  max-width: 1560px;
  margin: 0 auto;
}

@media screen and (max-width: 750px) {
  .carousel {
    padding: 0 30px;
  }
}

@media screen and (max-width: 500px) {
  .carousel {
    padding: 0 20px;
  }
}