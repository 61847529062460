.wrapper {
  margin-top: auto;
  border-bottom: 3px solid #df1319;

  .top {
    background-color: #3d3d3d;
    display: flex;
    padding: 65px 0 115px;
    position: relative;

    .itemsWrapper {
      display: flex;
      width: 100%;
      max-width: 1440px;
      padding: 0 40px;
      margin: 0 auto;
      justify-content: space-between;

      .itemWrapper {
        margin: 0 15px;
        display: flex;
        flex-flow: column;
        align-items: flex-start;

        .title {
          font-size: 24px;
          color: #fcfcfc;
          font-weight: 500;
          margin-bottom: 35px;
        }

        .point {
          font-size: 16px;
          padding: 2px 0;
          margin: 5px 0;
          color: #fcfcfc;
          font-weight: 400;
          white-space: nowrap;
          display: flex;
          flex-flow: wrap;

          span {
            margin-right: 5px;
          }
        }

        .pointLink {
          font-size: 16px;
          padding: 2px 0;
          margin: 5px 0;
          color: #fcfcfc;
          font-weight: 400;
          display: flex;
          flex-flow: wrap;
          transition: 0.3s all ease-in-out;
          user-select: none;

          &:hover{
            color: #ff5858;
          }

          span {
            margin-right: 5px;
          }
        }

        .itemWrapperMobile {
          display: none;
        }
      }

      .itemWrapper:first-child {
        margin-left: 0;
      }

      .itemWrapper:last-child {
        margin-left: 0;
      }
    }

    .arrowUp {
      background-image: url("../../assets/img/other/arrowUp.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 44px;
      height: 44px;
      position: absolute;
      right: 140px;
      bottom: 65px;
      cursor: pointer;
    }
  }

  .bottom {
    display: flex;
    width: 100%;
    padding: 55px 40px;
    background-color: #ffffff;
    max-width: 1700px;
    margin: 0 auto;
    align-items: flex-end;

    .bottomLogo {
      background-image: url("../../assets/img/other/logo.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 310px;
      height: 77px;
    }

    .bottomText {
      font-size: 14px;
      color: #3d3d3d;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1320px) {
  .wrapper {

    .top {

      .itemsWrapper {

        .itemWrapper {

          .title {
            font-size: 20px;
            margin-bottom: 30px;
          }

          .point {
            font-size: 14px;
            padding: 2px 0;
            margin: 5px 0;
          }
        }
      }

      .arrowUp {
        right: 110px;
        bottom: 50px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {

    .top {

      .arrowUp {
        right: 70px;
        bottom: 40px;
      }
    }

    .bottom {

      .bottomLogo {
        width: 248px;
        height: 61px;
      }

      .bottomText {
        margin-bottom: 15px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .wrapper {

    .top {

      .itemsWrapper {

        .itemWrapper {

          .itemWrapperMobile {
            display: flex;
            flex-flow: column;

            .title {
              margin-top: 30px;
            }
          }

          .point {
            margin: 3px 0;
          }
        }

        .itemWrapper:last-child {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .wrapper {

    .top {
      padding: 45px 0 75px;

      .itemsWrapper {
        flex-wrap: wrap;
        padding: 0 30px;

        .itemWrapper {
          width: calc(50% - 15px);

          .itemWrapperMobile {
            display: none;
            flex-flow: column;
          }
        }

        .itemWrapper:nth-child(2) {
          margin-right: unset;
          margin-left: 0;
        }

        .itemWrapper:nth-child(3) {
          margin-top: 30px;
          margin-left: unset;
        }

        .itemWrapper:last-child {
          margin-top: 30px;
          display: flex;
          margin-right: unset;
          margin-left: 0;
        }
      }

      .arrowUp {
        right: 40px;
        bottom: 20px;
      }
    }

    .bottom {
      padding: 40px 30px;
    }
  }
}

@media screen and (max-width: 500px) {
  .wrapper {

    .top {

      .itemsWrapper {
        padding: 0 20px;
      }
    }

    .bottom {
      padding: 30px 20px;

      .bottomLogo {
        width: 198px;
        height: 48px;
      }

      .bottomText {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .wrapper {

    .top {

      .itemsWrapper {

        .itemWrapper:nth-child(3) {
          order: 1;
          width: 100%;
        }

        .itemWrapper:last-child {
          width: 100%;
        }
      }
    }

    .bottom {

      .bottomLogo {
        width: 178px;
        height: 43px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .wrapper {

    .top {

      .itemsWrapper {

        .itemWrapper {
          width: 100%;
          margin-top: 30px;
        }

        .itemWrapper:first-child {
          margin-top: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .wrapper {

    .bottom {

      .bottomLogo {
        width: 160px;
        height: 40px;
      }

      .bottomText {
        font-size: 11px;
      }
    }
  }
}