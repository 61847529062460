.bottomContainer {
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffffee;
  padding: 15px;
  position: fixed;
  bottom: -130px;
  opacity: 0;
  box-shadow: 0px 0 10px 0 #505050;
  z-index: 10000;

  .text {
    width: 90%;
    font-size: 16px;
    color: #020202;
    font-weight: 400;
    font-family: "Roboto";
    text-align: center;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: 1px solid #e2e2e2;
    font-size: 18px;
    color: #020202;
    font-weight: 400;
    font-family: "Roboto";
    user-select: none;
    cursor: pointer;
    margin: 15px;
    padding: 15px;
  }
}

.animationGoUp{
  animation: goUp 1.0s forwards 3s;
}

.animationGoDown{
  animation: goDown 0.5s forwards;
}

@keyframes goUp {
  0% {
    bottom: -150px;
    opacity: 0;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes goDown {
  0% {
    bottom: 0px;
    opacity: 1;
  }

  100% {
    bottom: -150px;
    opacity: 0;
  }
}

@media screen and (max-width: 600px) {
  .bottomContainer {
    height: 150px;
    .text{
      font-size: 14px;
    }
  }
}