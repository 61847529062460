// value container
.wrapper {
  position: relative;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  //background-color: #ffffff;
}

.values {
  .text1 {
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    font-weight: 500;
    font-family: "Roboto";
    margin-right: 5px;
  }

  .text2 {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #484848;
    font-weight: 400;
    font-family: "Roboto";
  }
}

// slider container
.container {
  position: relative;
  width: 100%;
  margin-top: 15px;
  margin-left: 5px;
}

// In code is fill color, here background
.sliderTrack {
  width: 100%;
  height: 10px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  border: 1px solid #aaa9a9;
}

input[type="range"].button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;
}

//button Mozilla
input[type="range"].button::-moz-range-thumb {
  -webkit-appearance: none;
  height: 17px; //1.7em
  width: 17px; //1.7em
  cursor: pointer;
  border-radius: 25%;
  border: 1px solid #d3d3d3;
  background-color: #ededed;
  pointer-events: auto;
  transition: 0.5s all;

  &:hover{
    box-shadow: 0 0 5px rgba(#000000, 0.2);
  }
}

//button Chrome/Safari
input[type="range"].button::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px; //1.7em
  width: 20px; //1.7em
  cursor: pointer;
  border-radius: 25%;
  border: 1px solid #d3d3d3;
  background-color: #ededed;
  pointer-events: auto;

  transition: 0.5s all;

  &:hover{
    box-shadow: 0 0 5px rgba(#000000, 0.2);
  }
}

//button Edge
input[type="range"].button::-ms-thumb {
  appearance: none;
  height: 17px; //1.7em
  width: 17px; //1.7em
  cursor: pointer;
  border-radius: 25%;
  border-color: #d3d3d3;
  background-color: #ededed;
  pointer-events: auto;

  transition: 0.5s all;

  &:hover{
    box-shadow: 0 0 5px rgba(#000000, 0.2);
  }
}

//Button position Mozilla
input[type="range"].button::-moz-range-track {
  -moz-appearance: none;
  height: 5px;
}

//Button position Chrome/Safari
input[type="range"].button::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 20px;
}

//Button position Edge
input[type="range"].button::-ms-track {
  appearance: none;
  height: 5px;
}

// input[type="range"]:active::-webkit-slider-thumb {
//   background-color: #ffffff;
//   border: 3px solid #3264fe;
// }