.container {
  max-width: 620px;
  width: 100%;
  max-height: 400px;
  min-height: 350px;
  height: 100%;
  display: flex;
  position: relative;
  border-radius: 8px;
  filter: drop-shadow(1px 1.732px 5px rgba(0, 0, 0, 0.16));
  background-color: #ffffff;

  .imgBig {
    width: 100%;
    display: flex;
    padding-top: clamp(250px, 80%, 300px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
  }

  .imgSmall {
    width: 100%;
    display: flex;
    padding-top: clamp(200px, 80%, 255px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    position: relative;
  }

  .shadowLight {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #676767 0%, #dcdada 100%);
    mix-blend-mode: multiply;
    position: absolute;
    border-radius: 8px;
    top: 0;
  }

  .shadow {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #1a1a1a 0%, #b5b5b5 100%);
    mix-blend-mode: multiply;
    position: absolute;
    border-radius: 8px;
    top: 0;
  }

  .content {
    max-width: 620px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 30px;

    .tag {
      min-width: 164px;
      height: 28px;
      display: flex;
      align-items: center;
      font-size: 14px;
      letter-spacing: 0px;
      color: #e0131a;
      font-weight: 500;
      font-family: "Roboto";
      flex-shrink: 0;
    }

    .date {
      font-size: 11px;
      letter-spacing: 0px;
      color: #aaaec5;
      font-weight: 500;
      font-family: "Roboto";
      margin-top: clamp(20px, 2.5vw, 40px);
      margin-bottom: clamp(20px, 3.0vw, 35px);
    }

    .title {
      font-size: clamp(18px, 3.0vw, 22px);
      letter-spacing: 0px;
      color: #000000;
      font-weight: 500;
      font-family: "Roboto";
      margin-bottom: 20px;
    }

    .titleWhite {
      font-size: clamp(20px, 3.0vw, 30px);
      letter-spacing: 0px;
      color: #f9f9f9;
      font-weight: 500;
      font-family: "Roboto";
      margin-bottom: clamp(18px, 2.5vw, 22px)
    }

    .btnGo {
      display: flex;
      align-items: center;
      font-size: 18px;
      letter-spacing: 0px;
      color: #a3a3a3;
      font-weight: 600;
      font-family: "Encode Sans";
      cursor: pointer;
      user-select: none;
      position: absolute;
      bottom: 40px;
      z-index: 10;

      &::after {
        content: "";
        width: 7px;
        height: 12px;
        padding-right: 50px;
        background-image: url("../../../assets/img/other/arrowRight.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .bottom{
      bottom: 20px;
    }
  }

  .contentUnder {
    position: relative;
  }

}

.containerBig {
  flex-direction: column;
  max-height: unset;
  height: auto;
  filter: none;
  border: 1px solid #eeeeee;
}