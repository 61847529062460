.container {
    width: 100%;
    display: flex;
    padding: 15px;
}

.main {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 40px;
    row-gap: 40px;
}

.containerTop {
    // max-width: 780px;
    max-width: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 40px;
    row-gap: 40px;

    .content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;

        .select {
            max-width: 398px;
            width: 100%;
            height: 52px;
        }

        .right {
            display: flex;
            align-items: center;
            column-gap: 10px;

            .checkboxNormal {
                width: 21px;
                height: 21px;
                background-color: #ffffff;
                border: 1px solid #b2b2b2;
                vertical-align: middle;
                appearance: none;
                -webkit-appearance: none;
                outline: none;
                cursor: pointer;
            }

            .checkboxNormal:checked {
                background-color: gray;
            }
        }
    }
}

.containerMid {
    max-width: 900px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .columnLeft {
        display: flex;
        flex-direction: column;

        max-width: 400px;
        width: 100%;
        border: 1px solid #e9e9e9;
        background-color: #fdfdfd;
        border-radius: 25px;
        padding: 15px;
    }

    .columnCenter {
        display: flex;
        flex-direction: column;

        max-width: 400px;
        width: 100%;
        border: 1px solid #e9e9e9;
        background-color: #fdfdfd;
        border-radius: 25px;
        padding: 15px;
    }

    .selector {
        max-width: 400px;
        width: 100%;
        height: 52px;
        margin: 0;
    }

    .mid {
        margin: 50px 0 0 0;
    }

    .btnUpdate {
        font-size: 16px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 500;
        font-family: "Roboto";
        user-select: none;
        cursor: pointer;
        margin: 30px 0;
    }

    .disable {
        color: #5c5c5c;
        opacity: 0.5;
        pointer-events: none;
    }

    .opacityHalf {
        opacity: 0.5;
    }
}

.containerBot {
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
        max-width: 900px;
        width: 100%;
        display: flex;
        border-top: 1px solid #b2b2b2;

        .commentContainer {
            max-width: 600px;
            width: 100%;
            display: flex;
            padding: 20px 5px 20px 20px;
            margin: 25px 0 0 0;

            border-radius: 20px;
                background-color: #ffffff;
                border: 1px solid #b2b2b2;

            .input {
                width: 100%;
                height: 120px;
                
                border: unset;

                font-size: 19px;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
                font-family: "Roboto";
                padding: 5px 15px 5px 5px;
                resize: none;

                scrollbar-color: #ed000077 #d6252523;
            }

            .input::placeholder {
                font-size: 18px;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
                font-family: "Roboto";
                opacity: 1;
            }

            //another then mozilla
            .input::-webkit-scrollbar {
                width: 5px;
                background-color: #d6252523;
            }

            .input::-webkit-scrollbar-thumb {
                background: #ed000077;
                border-radius: 15px;
            }

            .btnSave {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;
                height: 50px;
                border-radius: 15px;
                background-color: #ffffff;
                border: 1px solid #b2b2b2;
                font-size: 18px;
                letter-spacing: 0px;
                color: #7f7f7f;
                font-weight: 400;
                font-family: "Roboto";
                text-align: center;
                user-select: none;
                cursor: pointer;
                transition: 0.5s all ease-in-out;

                &:hover {
                    box-shadow: 1px 1px 10px rgba(#000000, 0.2);
                }
            }
        }
    }

    .bot {
        width: 100%;
        height: 65px;
        display: flex;
        justify-content: flex-end;
    }

    .btnGrey {
        //all: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        max-width: 400px;
        min-width: 150px;
        width: 100%;
        max-height: 60px;
        height: 100%;
        border-radius: 20px;
        background-color: #f3f3f3;
        border: 1px solid #d9d9d9;
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 18px;
        color: #141414;
        font-weight: 500;
        font-family: "Roboto";
        user-select: none;
        cursor: pointer;
        margin: 5px 0;
        transition: 0.5s all ease-in-out;

        &:hover {
            box-shadow: 2px 2px 15px rgba(#000000, 0.1);
        }
    }
}

.content2 {
    width: 100%;
    display: flex;
    align-items: center;
    user-select: none;
}

.textSmall {
    font-size: 16px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    margin: 0;
}

.coverShow {
    display: flex;
}

.opacityFull {
    opacity: 1;
}

.textTitle {
    font-size: 24px;
}

.clickable{
    cursor: pointer;
}

@media screen and (max-width: 950px) {
    .containerMid {
        .columnRight {
            display: none;
        }
    }
}

@media screen and (max-width: 900px) {
    .containerTop {
        .content {
            display: block;
            max-width: 400px;
            width: 100%;
            height: 52px;
            margin: 0;
        }
    }

    .containerMid {
        flex-direction: column;
        row-gap: 40px;

        .mid {
            margin: 25px 0 0 0;
        }
    }

    .content2 {
        margin: 0;
    }

    .containerBot {

        .btnGrey {
            margin: 15px 0 0 0;
        }
    }
}

@media screen and (max-width: 600px) {
    .containerBot {
        .top {
            .commentContainer {
                .input {
                    height: 150px;
                    font-size: 16px;
                }

                .input::placeholder {
                    font-size: 14px;
                }
            }
        }
    }

}

@media screen and (max-width: 400px) {
    .textTitle {
        font-size: 20px;
    }

    .containerBot {
        .top {
            .commentContainer {
                .input {
                    height: 180px;
                }

            }
        }
    }
}