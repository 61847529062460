.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  width: calc(100%/4 - 15px); //297px
  min-width: 200px;
  height: auto;
  padding: 10px 10px 130px 10px;
  position: relative;
  transition: 0.5s all ease-in-out;
  overflow-y: hidden;
  overflow-x: hidden;
}

.productItem {
  min-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 10px 10px 110px 10px;
  position: relative;
  flex-shrink: 0;
}

.productTop {
  display: flex;
  width: 100%;

  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
}

.imageContainer {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
  position: relative;
}

.image {
  width: 150px;
  height: 125px;
  margin-top: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-shrink: 0;
}

.logo {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-shrink: 0;
  margin-left: 15px;
  margin-right: 20px;
}

.btnToProduct {
  width: calc(100% + 50px);
  height: calc(100% - 50px);
  position: absolute;
  top: 0;
  left: -25px;
  cursor: pointer;
}

.description {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 10px;
}

.descriptionContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 3px;
}

.productName {
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 500;
  font-family: "Roboto";
}

.text1 {
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 18px;
  color: #4a4949;
  font-weight: 400;
  font-family: "Roboto";
}

.text2 {
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 18px;
  color: #212121;
  font-weight: 500;
  font-family: "Roboto";
}

.price {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #eeeeee;
  padding: 10px 0px 10px 15px;
  margin-top: 10px;
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
}

.cost {
  border-right: 1px solid #eeeeee;
  width: 100%;
  //flex-shrink: 0;
  white-space: nowrap;
  padding-right: 10px;

  .priceNormalNet {
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
  }

  .priceNormalGross {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 24px;
    color: #666666;
    font-weight: 400;
    font-family: "Roboto";
  }

  .priceOld {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 24px;
    color: #c5c5c5;
    font-weight: 400;
    font-family: "Roboto";
    text-decoration: line-through;
  }

  .priceNew {
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #c41218;
    font-weight: 500;
    font-family: "Roboto";

  }
}

.cartButton {
  background-image: url("../../../assets/img/other/cartGray.png");
  background-position: center;
  background-size: 32px 28px;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 80px;
  height: 80px;
  cursor: pointer;
  transition: 0.5s all;

  &:hover {
    transform: scale(1.2);
  }
}

.cartInfo {
  width: calc(100%);
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  background-color: #e7e7e7;

  font-size: 18px;
  line-height: 35px;
  color: #000000;
  font-weight: 400;
  font-family: "Roboto";
  text-align: center;
  white-space: normal;
  position: absolute;
  top: 30%;
  left: 0px;
  transition: 0.5s all ease-in-out;
  padding: 0 10px;
  margin: auto auto 5px;
  opacity: 0;
}

.cartInfoOpacity {
  opacity: 0.9;
}

//horizontal
.productHorizontal {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  transition: 0.5s all ease-in-out;
  animation: showHorizontal 0.5s forwards;
  overflow-y: hidden;
  overflow-x: hidden;
}

@keyframes showHorizontal {
  0% {
    margin-right: 75%;
  }

  100% {
    margin-right: 0;
  }
}

.productTopHorizontal {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  padding: 10px;

  .right {
    display: flex;
    width: auto;
    height: 100%;
    align-items: flex-end;
  }
}

.imageContainerHorizontal {
  display: flex;
  align-items: center;
  position: relative;
}

.imageHorizontal {
  width: 150px;
  height: 125px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-shrink: 0;
}

.logoHorizontal {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-shrink: 0;
  margin-left: 15px;
  margin-right: 20px;
}

.btnToProductHorizontal {
  width: calc(100%);
  height: calc(100%);
  position: absolute;
  top: 0;
  left: -25px;
  cursor: pointer;
}

.descriptionHorizontal {
  width: 100%;
  padding: 15px 0;
}

.productNameHorizontal {
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 500;
  font-family: "Roboto";
}

.text1Horizontal {
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 18px;
  color: #4a4949;
  font-weight: 400;
  font-family: "Roboto";
}

.text2Horizontal {
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 18px;
  color: #212121;
  font-weight: 500;
  font-family: "Roboto";

  &::before {
    content: " ";
  }
}

.priceHorizontal {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  border-left: 1px solid #eeeeee;
}

//prices
.amountBig {

  font-size: 12px;
  line-height: 18px;
  color: #279439;
  font-weight: 400;
  font-family: "Roboto";
  display: flex;
  align-items: center;

  &::before {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #279439;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
  }
}

.amountSmall {
  font-size: 12px;
  line-height: 18px;
  color: #ff8502;
  font-weight: 400;
  font-family: "Roboto";
  display: flex;
  align-items: center;

  &::before {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #ff8502;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
  }
}

.amountNone {
  font-size: 12px;
  line-height: 18px;
  color: #e1131a;
  font-weight: 400;
  font-family: "Roboto";

  display: flex;
  align-items: center;

  &::before {
    content: "";
    height: 8px;
    width: 8px;
    background-color: #fff;
    border: 1px solid #e1131a;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
  }
}

.compareButton {
  width: 234px;
  height: 42px;
  border-radius: 15px;
  background-color: #e11319;
  border: 1px solid #e5e5e5;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  line-height: 14px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Roboto";
  white-space: nowrap;
}

.saleButton {
  width: 99px;
  height: 28px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 14px;
  text-transform: uppercase;
  color: #383838;
  font-weight: 500;
  font-family: "Roboto";
  padding: 10px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2)
  }

  &::before {
    content: "%";
    text-transform: uppercase;
    color: #e1131a;
    margin-right: 2px;
  }
}

.checkbox {
  display: block;
  position: relative;

  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-size: 13px;
  line-height: 14px;
  color: #000000;
  font-weight: 400;
  font-family: "Roboto";

  padding: 2px 0;
  padding-left: 20px;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  border-radius: 2px;
  background-color: #fdfdfd;
  border: 1px solid rgba(69, 69, 69, 0.66);
}

/* Create */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* if check color */
.checkbox input:checked~.checkmark {
  background-color: #e11319;
  border: 1px solid #e41017;
}

/* Show symbol */
.checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator */
.checkbox .checkmark:after {
  left: 5px;
  top: 1px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.productActive {
  border-radius: 5px;
  filter: drop-shadow(1px 1.732px 5px rgba(0, 0, 0, 0.16));
}

@media screen and (max-width: 1680px) {
  .product {
    width: calc(100%/3 - 15px);
  }
}

@media screen and (max-width: 1300px) {
  .product {
    width: calc(100%/2 - 15px);
  }
}

@media screen and (max-width: 1200px) {
  .product {
    width: calc(100%/3 - 15px);
  }
}

@media screen and (max-width: 1000px) {
  .product {
    width: calc(100%/2 - 10px);
  }
}

//change
@media screen and (max-width: 950px) {
  .productHorizontal {
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .productTopHorizontal {
    width: 100%;
    height: auto;
    flex-direction: row;

    .right {
      width: 100%;
      height: auto;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .imageContainerHorizontal {
    flex-direction: column;
    row-gap: 15px;
  }

  .descriptionHorizontal {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
  }

  .priceHorizontal {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid #eeeeee;
    padding: 10px 0px 10px 15px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .product {
    width: calc(100%);
  }

  .imageContainerHorizontal {
    margin-left: 10px;
  }

  .imageHorizontal {
    width: 75px; //150px;
    height: 60px; //125px;
    margin-top: 0px;
  }

  .logoHorizontal {
    width: 25px; //50px;
    height: 25px; //50px;
  }

  .productNameHorizontal {
    font-size: 16px;
  }

  // .text1Horizontal{
  //   font-size: 11px;
  // }
  // .text2Horizontal{
  //   font-size: 11px;
  // }
}

@media screen and (max-width: 400px) {
  .descriptionHorizontal {
    margin-left: 5px;
  }

  .productNameHorizontal {
    font-size: 14px;
  }
}

@media screen and (max-width: 350px) {
  .price {
    .cost {
      .priceNormalNet {
        font-size: 16px;
      }

      .priceNormalGross {
        font-size: 12px;
      }

      .priceOld {
        font-size: 12px;
      }

      .priceNew {
        font-size: 16px;
      }
    }
  }
}