.breadcrumbContainer {
    display: flex;
    justify-content: flex-start;
    box-shadow: inset 2.5px 4.33px 2px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    margin-bottom: 10px;
}

.breadcrumb {
    width: 100%;
    max-width: 1770px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px 115px 10px 115px;
    margin-left: auto;
    margin-right: auto;
    appearance: none;
    box-shadow: inset 2.5px 4.33px 2px rgba(0, 0, 0, 0.04);
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .containerMain{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 15px;
    }

    .image{
        width: 233px;
        height: 348px;
        background-image: url("../../assets/img/Shared/img-404.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: auto;
    }

}