@import '../../assets/styles/variables';

.wrapper {
  display: flex;
  width: 100%;
  flex-flow: column;
  margin-bottom: 30px;

  .top {
    display: flex;
    width: 100%;
    background-color: #3d3d3d;

    .container {
      display: flex;
      justify-content: space-between;
      max-width: 1770px;
      margin: 0 auto;
      padding: 14px 40px;
      width: 100%;

      .left {
        display: flex;
        align-items: center;
        margin-left: 75px;
        transition: 0.3s;

        .call {
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
        }

        .phone {
          font-size: 14px;
          font-weight: 500;
          @include left-right-hover(#ff6262);
        }

        .contact {
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;

          @include left-right-hover(#ff6262);

          &:before {
            content: "";
            width: 2px;
            background-color: #ffffff;
            display: flex;
            margin: 0 18px;
            height: 14px;
          }
        }

        .icon {
          background-image: url("../../assets/img/Top/phone.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% auto;
          width: 16px;
          height: 16px;
          margin-left: 8px;
          margin-right: 4px;
        }
      }

      .right {
        display: flex;
        align-items: center;
        column-gap: 35px;
        margin: 0 57.5px 0 0;

        .containerMenuGlobal {
          display: flex;
          position: relative;
        }

        .btnGlobal {
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          user-select: none;
          cursor: pointer;
          transition: 0.3s all ease-in-out;

          @include left-right-hover(#ff6262);

          // &:hover {
          //   color: #8f8f8f;
          // }
        }

        .sliderContainer {
          width: 100px;
          background-color: #3d3d3d;
          position: absolute;
          left: 0;
          top: 25px;
          z-index: 100;
          transition: 0.3s all ease-in-out;
          overflow-y: hidden;
          z-index: 2500;

          .btnSlider {
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            user-select: none;
            cursor: pointer;
            padding: 5px 10px;

            @include left-right-hover(#ff6262);
          }
        }

        .sliderOpen {
          height: auto !important;
        }
      }
    }
  }

  .middle {
    display: flex;
    width: 100%;
    max-width: 1770px;
    padding: 10px 40px 0;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;

    .logoWrapper {
      display: flex;
      flex-flow: column;
      align-items: flex-start;

      .logo {
        background-image: url("../../assets/img/other/logo.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 310px;
        height: 77px;
      }

      .logoText {
        font-size: 14px;
        color: #2a2a2a;
        font-weight: 500;
        margin-left: 75px;
        margin-top: -5px;
      }
    }

    .inputWrapper {
      display: flex;
      position: relative;
      width: 100%;
      max-width: 753px;
      margin-left: 45px;

      .input {
        width: 100%;
        max-width: 753px;
        height: 47px;
        border-radius: 10px;
        box-shadow: 0 0 3.5px rgba(157, 157, 157, 0.85);
        appearance: none; //!importatn on iphones 
        background-color: #ffffff;
        border: unset;
        font-size: 18px;
        color: #3d3d3d;
        font-weight: 400;
        padding-left: 22px;
        padding-right: 50px;
      }

      .input::placeholder {
        color: #8f8f8f;
      }

      .loop {
        all: unset;
        background-image: url("../../assets/img/Top/loop.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 21px;
        top: 11px;
        cursor: pointer;
      }
    }

    .rightWrapper {
      width: 170px;
      display: flex;
      justify-content: space-between;
      // margin: 0 57.5px;
      margin: 0 57.5px;
      flex-shrink: 0;

      .loginContainer {
        display: flex;
        position: relative;
        z-index: 1000;
      }

      .login {
        color: #3d3d3d;
        display: flex;
        flex-flow: column;
        align-items: center;
        cursor: pointer;
        width: 71px;
        height: 54px;
        position: relative;
        user-select: none;
        z-index: 1500;
        @include left-right-hover($color: #3d3d3d);

        .img {
          // background-image: url("../../assets/img/Top/login.svg");
          // background-size: contain;
          // background-repeat: no-repeat;
          background-color: #3d3d3d;
          -webkit-mask-size: cover;
          mask-size: cover;
          -webkit-mask-image: url("../../assets/img/Top/login.svg");
          mask-image: url("../../assets/img/Top/login.svg");
          width: 22px;
          height: 27px;
        }

        .text {
          font-size: 14px;
          font-weight: 500;
          font-family: "Roboto";
          margin-top: 10px;
          white-space: nowrap;
        }

        &.active{
          .img{
            background-color: red;
          }
          .text{
            color: red;
          }
        }
      }

      .accountMenuContainer {
        width: 350px;
        height: 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 10px;
        filter: drop-shadow(1px 1.732px 5px rgba(0, 0, 0, 0.15));
        background-color: transparent;
        z-index: 1000;
        position: relative;
        transition: 0.3s height ease-in-out;

        .backgroundImage {
          width: 350px;
          height: 530px;
          display: flex;
          background-image: url("../../assets/img/Header/menu.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 5px;
          left: 0px;
        }

        .btnGrey {
          z-index: 1003;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          max-width: 400px;
          width: 100%;
          height: 60px;
          border-radius: 20px;
          background-color: #f3f3f3;
          border: 1px solid #d9d9d9;
          font-size: 18px;
          letter-spacing: 0px;
          line-height: 18px;
          color: #141414;
          font-weight: 500;
          font-family: "Roboto";
          user-select: none;
          cursor: pointer;
          margin: 5px 0;
          transition: 0.5s all ease-in-out;

          &:hover {
            box-shadow: 2px 2px 15px rgba(#000000, 0.1);
          }
        }

        .mainContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 85px 10px 10px 13px;
        }
      }

      .cartMenuContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 10px;
        filter: drop-shadow(1px 1.732px 5px rgba(0, 0, 0, 0.15));
        //box-shadow: inset 0px 0px 2px #d9d9d9;
        background-color: transparent;
        //border: 1px solid #d9d9d9;
        width: 350px;
        height: 0px;
        z-index: 1000;
        position: relative;
        transition: 0.3s height ease-in-out;


        .shadow {
          width: 71%;
          height: 1px;
          box-shadow: inset 0px 0px 2px #d9d9d977;
          position: absolute;
          left: 0;
          top: 85px;
        }

        .shadow2 {
          width: 2px;
          height: 14%;
          box-shadow: inset 0px 0px 3px #d9d9d977;
          position: absolute;
          left: 71%;
          top: 0px;
        }

        .backgroundImage {
          width: 350px;
          height: 530px;
          display: flex;
          background-image: url("../../assets/img/Header/menu.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 5px;
          left: 0px;
        }

        .elemntListContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 100px 20px 30px 30px;
          z-index: 1000;
        }

        .element {
          display: flex;
          padding: 5px;
          z-index: 1000;

          .img {
            display: flex;
            width: 80px;
            height: 80px;
            background-size: contain;
            background-repeat: no-repeat;
            flex-shrink: 0;
            position: relative;

            .discount {
              font-size: 9px;
              letter-spacing: 0px;
              line-height: 14px;
              text-transform: uppercase;
              color: #000000;
              font-weight: 500;
              font-family: "Roboto";
              position: absolute;
              top: -10px;
              left: 0;

              &::before {
                content: "%";
                text-transform: uppercase;
                color: #e1131a;
                margin-right: 2px;
              }
            }
          }

          .title {
            width: 100%;
            display: flex;
            font-size: 14px;
            letter-spacing: 0px;
            color: #000000;
            font-weight: 400;
            font-family: "Roboto";
            position: relative;
            margin: 15px 5px 30px 30px;

            .amountSmall {
              height: 10px;
              width: 10px;
              background-color: #ff8502;
              border-radius: 50%;
              margin-right: 5px;
              display: inline-block;
              position: absolute;
              bottom: -15px;
              left: 0;
            }

            .amountBig {
              height: 10px;
              width: 10px;
              background-color: #279439;
              border-radius: 50%;
              margin-right: 5px;
              display: inline-block;
              position: absolute;
              bottom: -15px;
              left: 0;
            }

            .price {
              font-size: 14px;
              letter-spacing: 0px;
              line-height: 24px;
              color: #000000;
              font-weight: 500;
              font-family: "Roboto";
              position: absolute;
              bottom: -20px;
              right: 0;
            }

            .priceRed {
              font-size: 14px;
              letter-spacing: 0px;
              line-height: 24px;
              color: #e1131a;
              font-weight: 500;
              font-family: "Roboto";
              position: absolute;
              bottom: -20px;
              right: 0;
            }
          }
        }

        .bottomContainer {
          z-index: 1001;
          width: calc(100% - 20px);
          display: flex;
          flex-direction: column;
          background-color: #fafafa;
          height: 152px;
          border-radius: 4px;
          padding: 30px 20px 30px 30px;
          // position: absolute;
          // left: 0;
          // bottom: 0;
          position: relative;
          right: -12px;
          bottom: 5px;
          overflow: hidden;

          .sumary {
            z-index: 1002;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .text {
              font-size: 18px;
              letter-spacing: 0px;
              line-height: 24px;
              color: #000000;
              font-weight: 500;
              font-family: "Roboto";
            }
          }

          .btnGrey {
            z-index: 1003;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            max-width: 400px;
            width: 100%;
            height: 60px;
            border-radius: 20px;
            background-color: #f3f3f3;
            border: 1px solid #d9d9d9;
            font-size: 18px;
            letter-spacing: 0px;
            line-height: 18px;
            color: #141414;
            font-weight: 500;
            font-family: "Roboto";
            user-select: none;
            cursor: pointer;
            margin: 5px 0;
            transition: 0.5s all ease-in-out;

            &:hover {
              box-shadow: 2px 2px 15px rgba(#000000, 0.1);
            }
          }
        }
      }

      .parent {
        display: none;
        // filter: url('#goo');
        overflow: hidden;
        position: absolute;
        right: -35px;
        top: -15px;
        z-index: 1000;
      }

      .parentLogin {
        display: none;
        // filter: url('#goo');
        overflow: hidden;
        position: absolute;
        right: -23px;
        top: -15px;
        z-index: 1000;
      }

      .showAccountMenu {
        display: flex;
      }

      .heightAccountMenu {
        height: 540px;
      }

      .cartContainer {
        display: flex;
        position: relative;
        z-index: 1000;
      }

      .cart {
        width: 43px;
        height: 54px;
        display: flex;
        flex-flow: column;
        align-items: center;
        cursor: pointer;
        flex-shrink: 0;
        user-select: none;
        position: relative;
        z-index: 1500;
        @include left-right-hover($color: #3d3d3d);

        .img {
          display: flex;
          -webkit-mask-size: cover;
          mask-size: cover;
          -webkit-mask-image: url("../../assets/img/Top/cart.svg");
          mask-image: url("../../assets/img/Top/cart.svg");
          background-color: #3d3d3d;
          width: 32px;
          height: 27px;
          flex-shrink: 0;
          z-index: 1501;
        }

        .red {
          background-color: red;
        }

        .text {
          font-size: 14px;
          font-weight: 500;
          margin-top: 10px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        .count {
          font-size: 14px;
          letter-spacing: 0px;
          color: #f3070e;
          font-weight: 500;
          font-family: "Roboto";
          position: absolute;
          top: 30%;
          right: -10px;
        }

        &.active{
          .img{
            background-color: red;
          }
          .text{
            color: red;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    width: 100%;
    max-width: 1770px;
    margin: 30px auto 0;
    padding: 0 115px;
    z-index: 1;
  }
}

@media screen and (max-width: 1600px) {
  .wrapper {
    .top {
      .container {
        .left {
          margin-left: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .wrapper {
    .bottom {
      max-width: 1560px;
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    .top{
      .container{
        .right{
          margin: 0;
        }
      }
    }

    .middle {
      .rightWrapper {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    .middle {
      .logoWrapper {

        .logo {
          width: 248px;
          height: 61px;
        }

        .logoText {
          font-size: 12px;
          margin-left: 59px;
          margin-top: -10px;
        }
      }

      .inputWrapper {
        margin-left: 25px;

        .input {
          height: 37px;
          font-size: 16px;
          padding-right: 40px;
          padding-left: 15px;
        }

        .loop {
          top: 8px;
          width: 20px;
          height: 20px;
          right: 11px;
        }
      }

      .rightWrapper {
        .login {

          .loginText {
            margin-top: 5px;
            font-size: 13px;
          }
        }

        .cart {

          .cartText {
            margin-top: 5px;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .wrapper {
    margin-bottom: unset;

    .top {
      .container {
        justify-content: flex-end;

        .left {
          display: none;
        }
      }
    }

    .middle {
      flex-wrap: wrap;

      .inputWrapper {
        width: 100%;
        order: 2;
        margin: 15px auto;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .wrapper {
    .top {
      .container {
        padding: 14px 30px;
        flex-wrap: wrap;

        .right {
          margin-left: auto;
          .sliderContainer{
            .btnSlider{
              margin: 5px;
            }
          }
        }
      }
    }

    .middle {
      padding: 10px 30px 0;

      .logoWrapper {
        .logoText {
          max-width: 180px;
        }
      }

      .rightWrapper {
        width: 165px;
        margin: 0 15px 0 10px;
      }
    }

    .bottom {
      padding: 0 30px;
    }
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    .top {
      .container {
        padding: 14px 20px;
      }
    }

    .middle {
      padding: 15px 20px 0;

      .logoWrapper {
        .logo {
          width: 198px;
          height: 48px;
        }

        .logoText {
          display: none;
        }
      }

      .rightWrapper {
        .login {
          // width: 56px;
          // margin-left: 15px;

          // .img {
          //   width: 18px;
          //   height: 21px;
          // }

          .loginText {
            font-size: 11px;
          }
        }

        .cart {
          // margin-left: 15px;

          // .img {
          //   width: 25px;
          //   height: 21px;
          // }

          .cartText {
            font-size: 11px;
          }
        }
      }
    }

    .bottom {
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    .middle {
      .logoWrapper {
        .logo {
          width: 178px;
          height: 43px;
        }
      }

      .rightWrapper {
        width: auto;
        justify-content: right;

        .login {
          display: none;
        }

        .loginContainer {
          display: none;
        }


      }
    }
  }
}

@media screen and (max-width: 350px) {
  .wrapper {
    .middle {
      .rightWrapper {
        .cartMenuContainer {
          width: 315px;
          
          .backgroundImage {
            width: 315px;
            background-image: url("../../assets/img/Header/menuSmall.png");
            left: unset;
            right: 0;
          }

          .elemntListContainer{
            padding: 100px 15px 25px 15px;
          }
        }
      }
    }
  }
}