.breadcrumbContainer {
    display: flex;
    justify-content: flex-start;
    box-shadow: inset 2.5px 4.33px 2px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    margin-bottom: 10px;
}

.breadcrumb {
    width: 100%;
    max-width: 1770px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px 115px 10px 115px;
    margin-left: auto;
    margin-right: auto;
    appearance: none;
    box-shadow: inset 2.5px 4.33px 2px rgba(0, 0, 0, 0.04);
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .containerMain {
        max-width: 1000px;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 15px;

        .infoContainer {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .column {
            display: flex;
            flex-direction: column;
        }

        .title {
            font-size: 25px;
            line-height: 30px;
            color: #020202;
            font-weight: 500;
            font-family: "Roboto";
        }

        .subTitle {
            font-size: 22px;
            line-height: 26px;
            color: #464646;
            font-weight: 500;
            font-family: "Roboto";
        }

        .text {
            font-size: 16px;
            line-height: 26px;
            color: #464646;
            font-weight: 400;
            font-family: "Roboto";

            tr {
                display: flex;
            }

            td {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

@media screen and (max-width: 650px) {
.container{
    .containerMain{
        .text{
            tr{
                flex-direction: column;
            }
        }
    }
}
}