.spaceForHeader{
  height: 200px;
}

.containerBackground {
  display: flex;
  width: 100%;
  max-height: 500px;

  .background {
    display: flex;
    width: 100vw;
    height: 0;
    padding-top: clamp(140px, 26%, 500px);
    max-height: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
  }

  .shadow {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #cb1b1b 0%, #cb1b1b 25%, #0f0e37 100%);
    mix-blend-mode: multiply;
    position: absolute;
    border-radius: 8px;
    opacity: 0.52;
    top: 0;
  }

  .backgroundText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: clamp(70px, 10%, 200px);
    font-size: clamp(14px, 4.0vw, 50px);
    color: #ffffff;
    font-weight: 600;
    font-family: "Encode Sans";
    text-align: center;
    text-shadow: 1px 1px 10px #000000;
    z-index: 10;
    position: absolute;
  }
}