.container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px;
}

.mainContainer {
    max-width: 1600px;
    width: 100%;
    display: flex;

    .containerTop {
        max-width: 850px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        row-gap: 25px;
        flex-wrap: wrap-reverse;
    }

    .selector{
        max-width: 400px;
        width: 100%;
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 400px;
        min-width: 200px;
        width: 100%;
        height: 61px;
        border-radius: 20px;
        background-color: #f3f3f3;
        border: 1px solid #d9d9d9;
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 18px;
        color: #141414;
        font-weight: 500;
        font-family: "Roboto";
        user-select: none;
        cursor: pointer;
        transition: 0.3s all ease-in-out;


        &:hover {
            box-shadow: 1px 1px 10px rgba(#000000, 0.2);
        }
    }
}