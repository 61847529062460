.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  transition: opacity 0.2s linear, visibility 0.3s linear 0.3ms;
  opacity: 0.5;
  z-index: 1000000;
  visibility: visible;
}

.modalBackgroundHidden {
  opacity: 0;
  visibility: hidden;
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001000;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  transition: opacity 0.2s linear, visibility 0.3s linear 0.3ms;
  visibility: visible;
  opacity: 1;
  display: flex;

  .modalContainer {
    display: flex;
    max-width: 1500px;
    transition: transform 0.3s ease-out;
    width: 100%;
    padding: 40px;
    margin: auto;
    transform: translate(0, 0);
    position: relative;

    .modal {
      width: 100%;
      height: 100%;
      background-color: #f29400;
      padding: 80px;
      position: relative;
      border-radius: 10px;
    }

    .close {
      background-image: url("../../assets/img/buttons/xButtonBig.png");
      width: 22px;
      height: 18px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      right: 75px;
      top: 75px;
      cursor: pointer;
      transition: 0.1s all ease-in-out;
      z-index: 1000000;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.modalWrapperHidden {
  opacity: 0;
  visibility: hidden;

  .modalContainer {
    transform: translate(0, -20%);
  }
}

// @media screen and (max-width: 1000px) {
//   .modalWrapper {

//     .modalContainer {
//       padding: 30px;

//       .modal {
//         padding: 50px;

//         // .close {
//         //   width: 36px;
//         //   height: 36px;
//         //   right: 15px;
//         //   top: 15px;
//         // }
//       }
//     }
//   }
// }

@media screen and (max-width: 650px) {
  .modalWrapper {

    .modalContainer {
      padding: 15px;

      .modal {
        padding: 30px;
      }
      .close {
          right: 50px;
          top: 50px;
        }
    }
  }
}

@media screen and (max-width: 400px) {
  .modalWrapper {

    .modalContainer {

      .modal {
        padding: 20px;
      }
    }
  }
}