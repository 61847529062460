.container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fullPreviewContainer {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#000000, 0.5);
  z-index: -100;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.5s opacity ease-in-out;
}

.fullPreviewGalleryHeight {
  width: calc(100% - 20px);
  max-width: calc(100vh + 50px);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.fullPreview {
  width: 100vh;
  height: auto;
  margin-left: 35px;
  margin-right: 35px;
}

.fullPreviewGalleryWidth {
  width: calc(100vw - 20px);
  max-width: calc(100vh + 50px);
  height: calc(100vw - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.preview {
  width: 400px;
}

.show {
  display: flex;
  z-index: 100;
}

.show2{
  opacity: 1;
}

.blockScroll {
  overflow: hidden;
}

.carousel {
  width: 410px;
  display: flex;
  margin-top: 20px;
  position: relative;
}

.arrow {
  background-image: url("../../../../assets//img/other/arrowRightGray.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 25px;
  outline: unset;
  border: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff; //transparent;
  cursor: pointer;
  transition: 0.5s opacity;
}

.arrowBigPreviwe {
  background-image: url("../../../../assets//img/other/arrowRightGray.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 25px;
  outline: unset;
  border: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  cursor: pointer;
  transition: 0.5s opacity;
}

.arrow:disabled {
  opacity: 0;
  pointer-events: none;
}

.arrowBigPreviwe:disabled {
  opacity: 0;
  pointer-events: none;
}

.leftArrowThum {
  left: -70px;
  transform: rotate(180deg) translateY(50%);
  transition: transform 0.5s;

  &:hover {
    transform: rotate(180deg)translateY(50%) scale(1.2);
  }
}

.rightArrowThum {
  right: -70px;
  transition: transform 0.5s;

  &:hover {

    transform: scale(1.2) translateY(-40%);
  }
}

.leftArrowBigPrev {
  left: -30px;
  transform: rotate(180deg) translateY(50%);
  transition: transform 0.5s;

  &:hover {
    transform: rotate(180deg)translateY(50%) scale(1.2);
  }
}

.rightArrowBigPrev {
  right: -30px;
  transition: transform 0.5s;

  &:hover {

    transform: scale(1.2) translateY(-40%);
  }
}

.img1 {
  width: 400px;
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.clickable{
  cursor: pointer;
}

.img1BigHeight {
  width: 100%;//calc(100vh - 100px);
  height: calc(100vh - 100px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  cursor: pointer;
}

.img1BigWidth {
  width: 100%;
  height: calc(100vw - 80px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  cursor: pointer;

}

.img2 {
  width: 95px;
  height: 95px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75px 75px;

  cursor: pointer;
  margin-top: 2px;
  border: 1px solid #e0e0e0;

  transition: 0.3s all ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.item {
  display: flex;
  justify-content: center;
}

.itemActive {
  border: 5px solid #e0e0e0;
}

.none {
  content: "";
  background-image: url("../../../../assets/img/other/cartGray.png");
  background-position: center;
  background-size: 32px 28px;
  background-repeat: no-repeat;
  width: 300px; //32
  height: 300px; //28
  margin: 0 auto;
}

.thum {
  width: 410px;
  height: 100px;
  padding: 0 5px !important;
}

.loopPlus {
  display: flex;
  width: 18px;
  height: 18px;
  background-image: url("../../../../assets/img/buttons/galeryLoopPlus.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  right: 0;
  transition: 0.15s transform;

  &:hover {
    transform: scale(1.2);
  }
}

.loopMinus {
  display: flex;
  width: 18px;
  height: 18px;
  background-image: url("../../../../assets/img/buttons/galeryLoopMinus.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: 5px;
  right: -30px;
  transition: 1.0s transform;

  &:hover {
    transform: scale(1.2);
  }
}

//no right menu
@media screen and (max-width: 980px) {
  .carousel {
    left: 0;
  }
}

//imgae size
@media screen and (max-width: 580px) {
  .img1 {
    width: 300px;
    height: 300px;

  }

  .preview {
    width: 300px;
    margin-left: 10px;
  }

  .carousel {
    width: 320px;
    margin-top: 10px;
  }

  .thum {
    width: 320px;
  }

  .leftArrowThum {
    left: -40px;
  }

  .rightArrowThum {
    right: -40px;
  }
}

@media screen and (max-width: 500px) {
  .leftArrowThum {
    left: -30px;
  }

  .rightArrowThum {
    right: -30px;
  }
}

@media screen and (max-width: 400px) {
  .img1 {
    width: 250px;
    height: 250px;

  }

  .img2 {
    width: 80px;
    height: 80px;
    background-size: 60px 60px;
  }

  .preview {
    width: 250px;
  }

  .carousel {
    width: 270px;
  }

  .thum {
    width: 270px;
  }
}

@media screen and (max-width: 350px) {
  .img2 {
    width: 85px;
    height: 85px;
    background-size: 70px 70px;
  }

  .leftArrowThum {
    left: -15px;
  }

  .rightArrowThum {
    right: -15px;
  }

  .carousel {
    width: 250px;
  }

  .thum {
    width: 250px;
  }
}