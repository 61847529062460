.carousel {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.pagination {
  position: absolute;
  bottom: 0;
  padding-left: 0;
  padding-bottom: clamp(10px, 4.0vw, 60px);

  li {
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    margin: 0 7px;
  }
}

.paginationButton {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #f2f2f2;
  border: unset;
  cursor: pointer;
  padding: unset;
  transition: 0.3s;

  .is-active {
    transform: scale(0.6);
    background-color: #df1319;
  }
}

