.product {
  min-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 15px;
}

.img {
  width: 100%;
  height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  flex-shrink: 0;
}

.descriptionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.productName {
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 500;
  font-family: "Roboto";
}

.price {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // border-top: 1px solid #eeeeee;
  // padding: 10px 0px 10px 15px;
  margin-top: 10px;
}

.cost {
  // border-right: 1px solid #eeeeee;
  width: 100%;
  white-space: nowrap;
  padding-right: 10px;

  .priceNormalNet {
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
  }

  .priceNormalGross {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 24px;
    color: #666666;
    font-weight: 400;
    font-family: "Roboto";
  }

  .priceOld {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 24px;
    color: #c5c5c5;
    font-weight: 400;
    font-family: "Roboto";
    text-decoration: line-through;
  }

  .priceNew {
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #c41218;
    font-weight: 500;
    font-family: "Roboto";

  }
}

.cartButton {
  background-image: url("../../../assets/img/other/cartGray.png");
  background-position: center;
  background-size: 32px 28px;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 80px;
  //height: 100%;
  height: 80px;
  cursor: pointer;
  transition: 1.0s all;

  &:hover {
    transform: scale(1.2);
  }
}