@import "../../assets/styles/variables";

.wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;

  .mainSlider {
    display: flex;
    width: 100%;
    background-image: linear-gradient(180deg, #f4f4f4 0%, #f4f4f4 17%, #ffffff 76%, #ffffff 100%);
    box-shadow: 0px 7px 11px rgb(0 0 0 / 9%);

    .slideWrapper {
      display: flex;
      width: 100%;
      margin: 70px 0;

      .left {
        width: 50%;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
      }

      .img {
        max-width: 600px;
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-bottom: 10px;
      }

      .points {
        display: flex;
        flex-flow: column;
        margin-left: auto;
        max-width: 600px;
        width: 100%;

        .point {
          display: flex;
          font-size: 18px;
          color: #2a2a2a;
          font-weight: 500;
          align-items: center;

          &:before {
            content: "";
            background-image: url("../../assets/img/other/arrowRight.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 7px;
            height: 12px;
            margin-right: 11px;
          }
        }
      }

      .right {
        width: 50%;
        margin-left: 60px;

        .titleGrey {
          font-size: 30px;
          color: #a3a3a3;
          font-weight: 300;
          font-family: "Encode Sans", sans-serif;
        }

        .titleColor {
          font-size: 48px;
          color: #e1131a;
          font-weight: 400;
          font-family: "Encode Sans", sans-serif;
          display: flex;
          flex-wrap: wrap;

          span:first-child {
            margin-right: 8px;
          }

          span:last-child {
            font-size: 48px;
            color: #2a2a2a;
            font-weight: 600;
            font-family: "Encode Sans", sans-serif;
          }
        }

        .text {
          color: #8a8a8a;
          font-size: 16px;
          line-height: 24px;
          margin-top: 25px;
          max-width: 580px;
        }

        .button {
          max-width: 265px;
          width: 100%;
          height: 66px;
          border-radius: 20px;
          box-shadow: 1px 2px 8.5px rgba(0,0,0,0.17);
          background-color: #f9f9f9;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-top: 25px;
          margin-left: 15px;
          font-size: 18px;
          color: #323232;
          font-weight: 600;
          font-family: "Encode Sans", sans-serif;
          transition: 0.3s all ease-in-out;

          &:after {
            content: "";
            background-image: url("../../assets/img/other/arrowRight.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 7px;
            height: 12px;
            margin-left: 30px;
          }

          &:hover {
            box-shadow: 3px 7px 20px rgba(0,0,0,0.3);
          }
        }

        .buttonBig {
          max-width: 265px;
          //width: 100%;
          height: 66px;
          border-radius: 20px;
          box-shadow: 1px 2px 8.5px rgba(0,0,0,0.17);
          background-color: #f9f9f9;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-top: 25px;
          margin-left: 15px;
          font-size: 18px;
          color: #323232;
          font-weight: 600;
          font-family: "Encode Sans", sans-serif;
          transition: 0.3s all ease-in-out;
          position: relative;

          &:after {
            content: "";
            background-image: url("../../assets/img/other/arrowRight.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 7px;
            height: 12px;
            margin-left: 30px;
            position: absolute;
            right: 5px;
            top: 29px;
          }

          &:hover {
            box-shadow: 3px 7px 20px rgba(0,0,0,0.3);
          }
        }
      }
    }
  }

  .menu {
    display: flex;
    width: 100%;
    background-color: #f9f9f9;
    flex-flow: column;
    align-items: center;
    margin-top: 70px;

    .menuWrapper {
      display: flex;
      width: 100%;
      margin-top: 50px;

      .menuItem {
        display: flex;
        width: calc(100% / 4);
        flex-flow: column;
        align-items: center;
        background-image: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 80%, rgba(0,0,0,0) 80%);
        padding-bottom: 40px;
        transition: 0.3s all;

        .title {
          font-size: 30px;
          color: #000000;
          font-weight: 500;
          text-align: center;
          display: flex;
          align-items: center;
          margin-top: 50px;
          margin-bottom: 30px;
          cursor: pointer;

          &:after {
            content: "";
            background-image: url("../../assets/img/other/arrowRight.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 9px;
            height: 16px;
            margin-left: 20px;
          }
        }

        .subWrapper {
          display: flex;
          flex-flow: column;
          max-width: 340px;
          margin: 0 20px;

          .image {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 125px;
            height: 125px;
            align-self: center;
            margin-top: 20px;
            margin-bottom: 45px;
          }

          .image1 {
            background-image: url("../../assets/img/MainPage/menu1.jpg");
          }

          .image2 {
            background-image: url("../../assets/img/MainPage/menu2.jpg");
          }

          .image3 {
            background-image: url("../../assets/img/MainPage/menu3.jpg");
          }

          .image4 {
            background-image: url("../../assets/img/MainPage/menu4.jpg");
          }

          .text {
            p{
              font-size: 16px;
              line-height: 24px;
              color: #2a2a2a;
              font-weight: 400;
              @include ellipsis(3)
            }
            
          }

          .list {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            margin-top: 20px;

            .point {
              font-size: 16px;
              line-height: 24px;
              color: #848484;
              font-weight: 400;
              transition: 0.3s all;
              cursor: pointer;

              &:hover {
                color: #e0131a;
              }
            }
          }
        }

        &:hover {
          background-image: linear-gradient(0deg, rgba(0,0,0,0) 5%, rgba(255,255,255,1) 5%, rgba(255,255,255,1) 100%, rgba(0,0,0,0) 100%);
        }
      }
    }

    .moreCategory {
      font-size: 24px;
      text-decoration: underline;
      color: #000000;
      font-weight: 500;
      text-align: center;
      user-select: none;
      cursor: pointer;
      margin-top: 45px;
      margin-bottom: 55px;
    }
  }

  .configure {
    display: flex;
    width: 100%;
    margin-top: 50px;

    .left {
      display: flex;
      flex-flow: column;
      width: 50%;
      min-width: 830px;
      padding-top: 20px;

      .top {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;

        .image {
          background-image: url("../../assets/img/MainPage/configure.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 249px;
          height: 182px;
          margin-left: 80px;
        }

        .titleWrapper {
          display: flex;
          flex-flow: column;
          margin-left: 20px;
          margin-right: 40px;

          .title {
            font-size: 36px;
            color: #161616;
            font-weight: 500;
            display: flex;
            align-items: center;

            &:after {
              content: "";
              background-image: url("../../assets/img/other/arrowRight.svg");
              background-size: contain;
              background-repeat: no-repeat;
              width: 16px;
              height: 31px;
              margin-left: 30px;
              transform: rotate(90deg);
              flex-shrink: 0;
            }
          }

          .subTitle {
            font-size: 18px;
            color: #000000;
            font-weight: 500;
            margin: 15px 0 20px;
          }
        }
      }

      .bottom {
        background-color: #f9f9f9;
        display: flex;
        justify-content: flex-end;

        .formWrapper {
          display: flex;
          flex-flow: column;
          margin-right: 80px;
          max-width: 390px;
          width: 100%;
          margin-top: 50px;

          .line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;

            .label {
              font-size: 24px;
              color: #2a2a2a;
              font-weight: 500;
            }

            .input {
              width: 85px;
              height: 59px;
              border-radius: 20px;
              background-color: #ffffff;
              font-size: 36px;
              color: #6c6c6c;
              font-weight: 500;
              border: unset;
              padding: 15px;
              text-align: center;
            }
          }

          .confirm {
            width: 208px;
            height: 59px;
            border-radius: 20px;
            background-color: #e0131a;
            font-size: 18px;
            color: #ffffff;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: 0.3s all;
            margin-bottom: 35px;
            margin-top: 5px;

            &:hover {
              box-shadow: 0 0 13px 8px rgba(0, 0, 0, 0.2)
            }
          }
        }
      }

    }

    .right {
      display: flex;
      flex-flow: column;
      width: 50%;
      padding-top: 20px;
      border-left: 2px solid #f2f2f2;

      .top {
        display: flex;
        height: 182px;
        align-items: flex-end;
        flex-shrink: 0;

        .title {
          font-size: 36px;
          color: #161616;
          font-weight: 500;
          display: flex;
          align-items: center;
          margin-left: 70px;
          margin-bottom: 56px;

          &:after {
            content: "";
            background-image: url("../../assets/img/other/arrowRight.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 16px;
            height: 31px;
            margin-left: 30px;
            transform: rotate(90deg);
            flex-shrink: 0;
          }
        }
      }

      .bottom {
        display: flex;
        justify-content: flex-start;
        background-color: #f9f9f9;
        width: 100%;
        height: 100%;

        .textWrapper {
          display: flex;
          align-items: flex-start;
          flex-flow: column;
          width: 100%;
          padding-left: 70px;
          background-color: #ffffff;
          height: 100%;
          padding-right: 50px;
          max-width: 585px;

          .text {
            font-size: 18px;
            letter-spacing: 0px;
            line-height: 24px;
            color: #838383;
            font-weight: 400;
          }

          .moreButton {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 208px;
            height: 59px;
            border-radius: 20px;
            background-color: #eeeeee;
            font-size: 18px;
            color: #3f3f3f;
            font-weight: 500;
            margin-top: 35px;
            margin-bottom: 95px;
            cursor: pointer;
            transition: 0.3s all;

            &:hover {
              box-shadow: 0 0 13px 8px rgba(0, 0, 0, 0.2)
            }
          }

          .advSearch {
            font-size: 24px;
            color: #e0131a;
            font-weight: 500;
            display: flex;
            align-items: center;
            user-select: none;
            cursor: pointer;
            transition: 0.3s all ease-in-out;
            border-bottom: 1px solid transparent;

            &:after {
              content: "";
              background-image: url("../../assets/img/other/arrowRight.svg");
              background-size: contain;
              background-repeat: no-repeat;
              width: 9px;
              height: 16px;
              margin-left: 20px;
              flex-shrink: 0;
            }

            &:hover{
              border-bottom: 1px solid #e0131a;
            }
          }
        }
      }
    }
  }

  .products {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-top: 115px;
    padding-bottom: 106px;

    .topWrapper {
      display: flex;
      flex-flow: column;
      max-width: 1770px;
      padding: 0 40px;
      margin: 0 auto;
      width: 100%;

      .title {
        font-size: 36px;
        color: #000000;
        font-weight: 500;
      }

      .typeWrapper {
        display: flex;
        margin-top: 30px;
        margin-bottom: 20px;

        .change {
          font-size: 18px;
          color: #8b8989;
          font-weight: 500;
          user-select: none;
          cursor: pointer;
          transition: 0.3s all;

          &:not(.active):hover{
            color: #e0131a;
          }
        }

        .active {
          color: #000000;
        }

        .separator {
          width: 2px;
          background-color: #8b8989;
          margin: 0 10px;
        }
      }
    }

    .productsWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 30px 30px 0;
      flex-wrap: wrap;

      .product {
        width: calc((100% - 120px) / 4 );
        box-shadow: 0 2px 10px -2px rgb(0 0 0 / 20%);
        transition: 0.3s height, 0.3s box-shadow;
        display: flex;
        flex-flow: column;
        align-items: center;
        position: relative;
        padding: 0 15px;
        margin-bottom: 100px;

        .imageProduct {
          background-position: center;
          background-size: contain;
          width: 185px;
          height: 160px;
          margin-top: 36px;
          margin-bottom: 22px;
          background-repeat: no-repeat;
        }

        .productName {
          font-size: 20px;
          color: #000000;
          font-weight: 500;
          text-align: center;
        }

        .starWrapper {
          display: flex;
          align-items: center;
          margin-right: 15px;
          margin-bottom: 5px;

          .star {
            background-image: url("../../assets/img/other/star.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }

          .rating {
            font-size: 18px;
            color: #e0131a;
            font-weight: 500;
            text-align: center;
          }

          .separator {
            width: 2px;
            height: 14px;
            background-color: #777777;
            margin: 0 5px;
          }

          .numbOfRatings {
            font-size: 18px;
            color: #777777;
            font-weight: 500;
            text-align: center;
          }
        }

        .imageLogo {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          margin-top: 12px;
          margin-bottom: 20px;
        }

        .imageMicro {
          background-image: url("../../assets/img/other/micro.svg");
          width: 89px;
          height: 47px;
        }

        .priceNett {
          font-size: 24px;
          color: #000000;
          font-weight: 500;
          text-align: center;
        }

        .priceBrutt {
          font-size: 14px;
          color: #323232;
          font-weight: 500;
          text-align: center;
          margin-top: 2px;
        }

        .descriptionWrapper {
          margin: 25px auto;
          max-width: calc(100% - 40px);
          align-self: flex-start;
          width: 100%;

          .line {
            margin: unset;

            span {
              font-size: 14px;
              line-height: 18px;
              color: #4a4949;
              font-weight: 400;
            }

            span:last-child {
              font-weight: 500;
              margin-left: 3px;
            }
          }
        }

        .buttons {
          height: 0;
          background-color: #ffffff;
          transition: 0.3s all;
          width: 100%;
          position: absolute;
          top: 100%;
          overflow: hidden;
          z-index: 1000;

          .cartButton {
            margin: 0 12px;
            font-size: 18px;
            color: #232323;
            font-weight: 500;
            text-align: center;
            width: calc(100% - 24px);
            padding: 20px 0;
            background-color: #f2f2f2;
            user-select: none;

            &.disable{
              opacity: 0.4;
              filter: blur(0.3px);
            }

            &:not(.disable){
              cursor: pointer;
            }
          }

          .moreInfo {
            font-size: 18px;
            color: #232323;
            font-weight: 500;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 20px 0;

            &:after {
              content: "";
              background-image: url("../../assets/img/other/arrowRightBlack.svg");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              width: 5px;
              height: 10px;
              margin-left: 10px;
              margin-right: -15px;
            }
          }
        }

        &:hover {
          box-shadow: 0 4px 10px 3px rgb(0 0 0 / 20%);

          .buttons {
            height: 124px;
            box-shadow: 0 10px 10px 2px rgb(0 0 0 / 20%);
          }
        }
      }
    }
  }

  .workWithUs {
    display: flex;
    flex-flow: column;

    .left {
      display: flex;
      flex-flow: column;
      width: 100%;
      background-image: url("../../assets/img/MainPage/workWithUs.jpg");
      background-repeat: no-repeat;
      background-position: right top;
      background-size: 996px auto;
      max-width: 1840px;
      margin-left: auto;
      padding-left: 40px;

      .title {
        font-size: 36px;
        color: #000000;
        font-weight: 500;
        padding-bottom: 45px;
        max-width: 720px;
        background-color: #ffffff;
      }

      .textWrapper {
        background-color: #f9f9f9;
        padding: 55px 70px;
        display: flex;
        flex-flow: column;
        width: 100%;
        max-width: 1060px;
        flex-shrink: 0;

        .text {
          font-size: 18px;
          line-height: 30px;
          color: #4a4949;
          font-weight: 400;
        }

        .textBold {
          font-size: 18px;
          line-height: 30px;
          color: #4a4949;
          font-weight: 500;
        }
      }

      .iconsWrapper {
        display: flex;
        max-width: 720px;
        width: 100%;
        align-items: flex-end;
        padding-top: 70px;
        background-color: #ffffff;
        justify-content: space-between;
        padding-right: 30px;

        .iconWrapper {
          display: flex;
          flex-flow: column;
          align-items: center;

          .img1 {
            background-image: url("../../assets/img/MainPage/workWithUs1.png");
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: contain;
            width: 53px;
            height: 59px;
          }

          .img2 {
            background-image: url("../../assets/img/MainPage/workWithUs2.png");
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: contain;
            width: 50px;
            height: 66px;
          }

          .img3 {
            background-image: url("../../assets/img/MainPage/workWithUs3.png");
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: contain;
            width: 78px;
            height: 55px;
          }

          .text {
            font-size: 20px;
            color: #131313;
            font-weight: 500;
            text-align: center;
            margin-top: 25px;
          }
        }
      }

      .iconWrapper:nth-child(1) {

        .text {
          max-width: 200px;
        }
      }

      .iconWrapper:nth-child(2) {

        .text {
          max-width: 200px;
        }
      }

      .iconWrapper:nth-child(3) {

        .text {
          max-width: 200px;
        }
      }
    }

    .knowMoreContainer{
      max-width: 1720px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin: 80px 0;
    }

    .knowMore {
      font-size: 24px;
      color: #000000;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      //margin: 80px auto 80px auto;
      
      padding: 10px 40px;


      &:after {
        content: "";
        background-image: url("../../assets/img/other/arrowRight.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 9px;
        height: 16px;
        margin-left: 30px;
      }
    }
  }

  .doYouKnow {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-bottom: 45px;

    .titleWrapper {
      display: flex;
      padding: 0 40px;
      background-color: #fcfcfc;
      justify-content: flex-start;

      .title {
        font-size: 36px;
        font-weight: 500;
        margin: 0 auto;
        max-width: 1700px;
        width: 100%;
        padding-top: 85px;
        padding-bottom: 10px;
      }
    }

    .articleWrapper {
      background-color: #ffffff;
      display: flex;
      width: 100%;

      .article {
        padding: 65px 40px 55px;
        width: 100%;
        display: flex;
        max-width: 1340px;
        margin: 0 auto;

        .imageWrapper {
          display: flex;

          .img1 {
            background-image: url("../../assets/img/MainPage/doYouKnow1.jpg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 600px;
            height: 338px;
            margin-right: 35px;
          }

          .img2 {
            background-image: url("../../assets/img/MainPage/doYouKnow2.jpg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 600px;
            height: 400px;
            margin-left: 35px;
          }
        }

        .textWrapper {
          padding: 0 25px;

          .title {
            font-size: 30px;
            color: #000000;
            font-weight: 500;
            margin-top: 10px;
            margin-bottom: 25px;
          }

          .text {
            font-size: 14px;
            line-height: 20px;
            color: #4a4949;
            font-weight: 400;
          }

          .img1 {
            display: none;
          }

          .img2 {
            display: none;
          }
        }
      }
    }

    .gray {
      background-color: #fcfcfc;
    }
  }
}

@media screen and (max-width: 1800px) {
  .wrapper {

    .workWithUs {

      .left {
        background-size: auto 100%;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {

    .menu {

      .menuWrapper {
        margin-top: 40px;

        .menuItem {
          background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 80%, rgba(0, 0, 0, 0) 80%);

          .title {
            font-size: 25px;
          }

          .subWrapper {

            .image {
              width: 110px;
              height: 110px;
            }

            .text {
              font-size: 14px;
              line-height: 20px;
            }

            .list {

              .point {
                font-size: 14px;
                line-height: 21px
              }
            }
          }
        }
      }
    }

    .configure {
      margin-top: 40px;

      .left {
        min-width: 600px;
        padding-top: 16px;

        .top {

          .image {
            width: 159px;
            height: 116px;
            margin-left: 40px;
          }

          .titleWrapper {
            margin-left: 16px;
            margin-right: 40px;

            .title {
              font-size: 28px;

              &:after {
                width: 12px;
                height: 24px;
                margin-left: 24px;
              }
            }

            .subTitle {
              font-size: 14px;
              margin: 12px 0 16px;
            }
          }
        }

        .bottom {

          .formWrapper {
            margin-right: 64px;
            max-width: 312px;
            margin-top: 40px;

            .line {
              margin-bottom: 10px;

              .label {
                font-size: 19px;
              }

              .input {
                width: 68px;
                height: 47px;
                border-radius: 16px;
                font-size: 28px;
                padding: 12px;
              }
            }

            .confirm {
              width: 166px;
              height: 47px;
              border-radius: 16px;
              font-size: 14px;
              margin-bottom: 28px;
              margin-top: 4px;
            }
          }
        }

      }

      .right {
        padding-top: 16px;
        border-left: 2px solid #f2f2f2;

        .top {
          height: 116px;

          .title {
            font-size: 28px;
            margin-left: 40px;
            margin-bottom: 44px;

            &:after {
              width: 12px;
              height: 24px;
              margin-left: 24px;
            }
          }
        }

        .bottom {

          .textWrapper {
            padding-left: 40px;
            padding-right: 40px;
            max-width: 468px;

            .text {
              font-size: 14px;
              line-height: 18px;
            }

            .moreButton {
              width: 166px;
              height: 47px;
              border-radius: 16px;
              font-size: 14px;
              margin-top: 28px;
              margin-bottom: 76px;
            }

            .advSearch {
              font-size: 19px;

              &:after {
                width: 7px;
                height: 12px;
                margin-left: 16px;
              }
            }
          }
        }
      }
    }

    .workWithUs {

      .left {
        display: flex;
        flex-flow: column;
        width: 100%;
        background-image: url("../../assets/img/MainPage/workWithUs.jpg");
        background-repeat: no-repeat;
        background-position: right top;
        background-size: auto 100%;
        max-width: 1840px;
        margin-left: auto;
        padding-left: 40px;

        .title {
          font-size: 30px;
          padding-bottom: 35px;
          max-width: 600px;

        }

        .textWrapper {
          padding: 40px 55px;
          max-width: 770px;
          flex-shrink: 0;

          .text {
            font-size: 16px;
            line-height: 26px;
          }

          .textBold {
            font-size: 16px;
            line-height: 26px;
          }
        }

        .iconsWrapper {
          max-width: 600px;
          padding-top: 50px;
          padding-right: 30px;

          .iconWrapper {

            .img1 {
              width: 42px;
              height: 47px;
            }

            .img2 {
              width: 40px;
              height: 52px;
            }

            .img3 {
              width: 62px;
              height: 44px;
            }

            .text {
              font-size: 17px;
              margin-top: 20px;
            }
          }
        }

        .iconWrapper:nth-child(1) {

          .text {
            max-width: 200px;
          }
        }

        .iconWrapper:nth-child(2) {

          .text {
            max-width: 200px;
          }
        }

        .iconWrapper:nth-child(3) {

          .text {
            max-width: 200px;
          }
        }
      }

      .knowMore {
        font-size: 20px;
        margin: 60px auto 60px auto;
      }
    }

    .products {

      .productsWrapper {

        .product {
          width: calc((100% - 90px) / 3);
        }

        .product:last-child {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {

    .mainSlider {

      .slideWrapper {
        margin: 50px 0;

        .points {

          .point {
            font-size: 16px;
          }
        }

        .right {
          margin-left: 30px;

          .titleGrey {
            font-size: 23px;
            margin-bottom: 4px;
          }

          .titleColor {
            font-size: 40px;

            span:first-child {
              margin-right: 6px;
            }

            span:last-child {
              font-size: 40px;
            }
          }

          .text {
            font-size: 14px;
            line-height: 22px;
            margin-top: 20px;
          }

          .button {
            max-width: 230px;
            height: 55px;
            margin-top: 20px;
            margin-left: 10px;
            font-size: 16px;
          }
        }
      }
    }

    .menu {
      margin-top: 50px;

      .menuWrapper {
        flex-wrap: wrap;
        justify-content: space-evenly;

        .menuItem {
          width: calc((100% - 16.66vw) / 2);
          background-image: unset;
          background-color: #ffffff;
          margin-bottom: 40px;

          .title {
            margin-top: 40px;
            margin-bottom: 10px;
          }

          &:hover {
            background-image: unset;
          }
        }

        .menuItem:nth-child(3) {
          margin-bottom: unset;
        }

        .menuItem:nth-child(4) {
          margin-bottom: unset;
        }
      }

      .moreCategory {
        font-size: 20px;
        margin-top: 35px;
        margin-bottom: 35px;
      }
    }

    .workWithUs {

      .left {
        padding-left: unset;
        margin-left: 40px;
        width: calc(100% - 40px);
        background-position: calc(100% + 4vw) top;

        .title {
          font-size: 25px;
          padding-bottom: 30px;
          max-width: 450px;
        }

        .textWrapper {
          padding: 24px 35px;
          max-width: 620px;

          .text {
            font-size: 15px;
            line-height: 22px;
          }

          .textBold {
            font-size: 15px;
            line-height: 22px;
          }
        }

        .iconsWrapper {
          max-width: 450px;
          padding-top: 40px;
          padding-right: 20px;

          .iconWrapper {

            .img1 {
              width: 33px;
              height: 37px;
            }

            .img2 {
              width: 32px;
              height: 41px;
            }

            .img3 {
              width: 49px;
              height: 35px;
            }

            .text {
              font-size: 15px;
              margin-top: 15px;
            }
          }
        }
      }

      .knowMore {
        font-size: 18px;
        margin: 40px auto;
      }
    }

    .doYouKnow {
      margin-bottom: 35px;

      .titleWrapper {
        padding: 0 40px;

        .title {
          font-size: 32px;
          padding-top: 65px;
          padding-bottom: 0;
        }
      }

      .articleWrapper {

        .article {
          padding: 55px 40px 45px;
          max-width: 1340px;

          .imageWrapper {

            .img1 {
              background-size: cover;
              background-position: center;
              width: 40vw;
              height: 100%;
              margin-right: 25px;
            }

            .img2 {
              background-size: cover;
              width: 40vw;
              height: 100%;
              margin-left: 25px;
              background-position: center;
            }
          }

          .textWrapper {
            padding: 0 20px;

            .title {
              font-size: 25px;
              margin-top: 5px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {

    .configure {

      .left {
        min-width: unset;

        .top {
          height: 116px;
          justify-content: flex-end;

          .image {
            display: none;
          }

          .titleWrapper {
            margin-left: 40px;
          }
        }

        .bottom {
          padding-left: 40px;
        }
      }

      .right {

        .top {
          margin-right: 40px;
        }
      }
    }

    .products {

      .productsWrapper {

        .product {
          width: calc((100% - 40px) / 2);

          .starWrapper {
            margin-right: 10px;

            .star {
              width: 14px;
              height: 14px;
            }

            .rating {
              font-size: 16px;
            }

            .separator {
              height: 13px;
            }

            .numbOfRatings {
              font-size: 16px;
            }
          }

          .descriptionWrapper {
            width: calc(100% - 20px);
          }
        }

        .product:nth-child(3) {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .wrapper {

    .mainSlider {

      .slideWrapper {
        margin: 40px 0;

        .points {

          .point {
            font-size: 14px;
          }
        }

        .right {
          margin-left: 20px;

          .titleGrey {
            font-size: 20px;
            margin-bottom: 5px;
          }

          .titleColor {
            font-size: 35px;

            span:first-child {
              margin-right: 3px;
            }

            span:last-child {
              font-size: 35px;
            }
          }

          .text {
            font-size: 13px;
            line-height: 18px;
            margin-top: 15px;
          }

          .button {
            max-width: 200px;
            height: 50px;
            margin-top: 15px;
            margin-left: 5px;
            font-size: 14px;
          }
        }
      }
    }

    .workWithUs {

      .left {
        background-position: calc(100% + 12vw) top;

        .title {
          max-width: 330px;
        }

        .textWrapper {
          padding: 25px 30px;
          max-width: 450px;
        }

        .iconsWrapper {
          max-width: 330px;
          padding-top: 40px;
          padding-right: 20px;
          flex-wrap: wrap;
          justify-content: space-around;
        }

        .iconWrapper:nth-child(1) {

          .text {
            max-width: 150px;
          }
        }

        .iconWrapper:nth-child(2) {

          .text {
            max-width: 150px;
          }
        }

        .iconWrapper:nth-child(3) {
          margin-top: 30px;

          .text {
            max-width: 150px;
          }
        }
      }

      .knowMore {
        font-size: 18px;
        margin: 40px auto;
      }
    }

    .doYouKnow {

      .articleWrapper {

        .article {

          .imageWrapper {

            .img1 {
              margin-right: 40px;
            }

            .img2 {
              margin-left: 40px;
            }
          }

          .textWrapper {
            padding: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .wrapper {

    .configure {

      .left {
        min-width: unset;

        .top {
          height: 116px;
          justify-content: flex-end;

          .image {
            display: none;
          }

          .titleWrapper {
            margin-left: 40px;

            .title {
              font-size: 25px;
            }

            .subTitle {
              font-size: 13px;
            }
          }
        }

        .bottom {
          padding-left: 40px;

          .formWrapper {
            max-width: 310px;
            margin-right: 35px;

            .line {

              .label {
                font-size: 16px;
              }

              .input {
                width: 54px;
                height: 37px;
                border-radius: 12px;
                font-size: 23px;
                padding: 5px;
              }
            }
          }
        }
      }

      .right {

        .top {
          margin-right: 40px;

          .title {
            font-size: 25px;
          }
        }
      }
    }

    .doYouKnow {

      .articleWrapper {

        .article {

          .imageWrapper {
            display: none;
          }

          .textWrapper {

            .img1 {
              background-image: url("../../assets/img/MainPage/doYouKnow1.jpg");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              width: calc(100% - 60px);
              height: 340px;
              margin-left: 30px;
              margin-right: 30px;
              display: flex;
              margin-bottom: 30px;
            }

            .img2 {
              background-image: url("../../assets/img/MainPage/doYouKnow2.jpg");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: top;
              width: calc(100% - 60px);
              height: 340px;
              margin-left: 30px;
              margin-right: 30px;
              display: flex;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .wrapper {

    .menu {

      .menuWrapper {
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 30px;

        .menuItem {
          width: calc((100% - 30px) / 2);
          background-image: unset;
          background-color: #ffffff;
          margin-bottom: 30px;

          .title {
            font-size: 22px;
          }
        }
      }
    }

    .configure {
      flex-flow: column;

      .left {
        width: 100%;

        .top {
          justify-content: center;

          .image {
            display: block;
            margin-left: 30px;
            width: 127px;
            height: 92px;
          }

          .titleWrapper {
            margin-right: 30px;
            margin-left: 15px;

            .title {
              font-size: 24px;
            }
          }
        }

        .bottom {
          padding: 0 30px;

          .formWrapper {
            margin: 40px auto 0;
          }
        }
      }

      .right {
        width: 100%;
        border-left: unset;
        border-top: 2px solid #f2f2f2;
        border-bottom: 2px solid #f2f2f2;
        padding-bottom: 30px;

        .top {
          height: 90px;

          .title {
            margin-left: 30px;
            margin-bottom: 30px;
            font-size: 24px;
          }
        }

        .bottom {

          .textWrapper {
            max-width: unset;
            padding-left: 30px;
            padding-right: 30px;
            flex-flow: row;
            flex-wrap: wrap;

            .text {
              width: calc(100% - 166px);
              padding-right: 20px;
            }

            .moreButton {
              margin: auto 0;
            }

            .advSearch {
              margin-right: auto;
              margin-top: 30px;
            }
          }
        }
      }
    }

    .products {
      margin-top: 80px;
      padding-bottom: 180px;

      .topWrapper {
        padding: 0 30px;

        .title {
          font-size: 31px;
        }

        .typeWrapper {
          margin-top: 20px;
          margin-bottom: 10px;

          .change {
            font-size: 15px;
          }
        }
      }

      .productsWrapper {

        .product {
          width: calc((100% - 30px) / 2);

          .imageProduct {
            width: 166px;
            height: 144px;
            margin-top: 26px;
            margin-bottom: 18px;
          }

          .productName {
            font-size: 18px;
          }

          .imageMicro {
            width: 80px;
            height: 42px;
          }

          .priceNett {
            font-size: 20px;
          }

          .priceBrutt {
            font-size: 12px;
          }

          .descriptionWrapper {
            display: flex;
            flex-flow: column;
            line-height: 14px;
            margin: 15px auto;
            max-width: unset;

            .line {
              line-height: 1;

              span {
                font-size: 12px;
              }
            }
          }

          .buttons {

            .cartButton {
              padding: 15px 0;
              font-size: 16px;
            }

            .moreInfo {
              margin: 15px 0;
              font-size: 16px;
            }
          }

          &:hover {

            .buttons {
              height: 99px;
            }
          }

        }
      }
    }

    .workWithUs {

      .left {
        margin-left: 30px;
        width: calc(100% - 30px);
      }
    }

    .doYouKnow {

      .titleWrapper {
        padding-left: 30px;
        padding-right: 30px;
      }

      .articleWrapper {

        .article {
          padding-left: 30px;
          padding-right: 30px;

          .textWrapper {

            .img1 {
              margin-left: 0;
              margin-right: 0;
              width: 100%;
            }

            .img2 {
              margin-left: 0;
              margin-right: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}



@media screen and (max-width: 700px) {
  .wrapper {

    .mainSlider {

      .slideWrapper {
        margin: 40px 0;

        .left {
          display: none;
        }

        .img {
          margin: 30px auto 10px;
          max-width: 450px;
          height: 200px;
        }

        .points {
          margin-left: unset;
          margin-right: unset;

          .point {
            font-size: 14px;
          }
        }

        .right {
          margin-left: 0;
          width: 100%;

          .titleGrey {
            font-size: 20px;
            margin-bottom: 5px;
          }

          .titleColor {
            font-size: 35px;

            span:first-child {
              margin-right: 3px;
            }

            span:last-child {
              font-size: 35px;
            }
          }

          .text {
            font-size: 13px;
            line-height: 18px;
            margin-top: 15px;
            max-width: unset;
          }

          .button {
            max-width: 200px;
            height: 50px;
            margin-top: 15px;
            margin-left: 7px;
            font-size: 14px;

            &:hover {
              box-shadow: 1px 2px 8.5px rgb(0 0 0 / 17%);
            }
          }
        }
      }
    }

    .workWithUs {

      .left {
        background-position: calc(100% + 30vw) top;

        .title {
          max-width: 250px;
          font-size: 22px;
          padding-bottom: 20px;
        }

        .textWrapper {
          padding: 20px 25px;
          max-width: 390px;

          .text {
            font-size: 14px;
            line-height: 20px;
          }

          .textBold {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .iconsWrapper {
          max-width: 250px;
          padding-top: 30px;
          padding-right: 20px;
          flex-wrap: wrap;
          justify-content: space-around;

          .iconWrapper {

            .text {
              font-size: 14px;
            }
          }
        }

        .iconWrapper:nth-child(1) {

          .text {
            max-width: 150px;
          }
        }

        .iconWrapper:nth-child(2) {
          margin-top: 20px;

          .text {
            max-width: 150px;
          }
        }

        .iconWrapper:nth-child(3) {
          margin-top: 20px;

          .text {
            max-width: 150px;
          }
        }
      }

      .knowMore {
        font-size: 16px;
        padding: 30px;
        margin: 30px auto;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper {

    .menu {

      .menuWrapper {

        .menuItem {
          width: 100%;
          background-image: unset;
          background-color: #ffffff;
          margin-bottom: 30px;
        }

        .menuItem:nth-child(3) {
          margin-bottom: 20px;
        }
      }
    }

    .workWithUs {

      .left {
        background-image: unset;
        margin-left: unset;
        width: 100%;

        .title {
          max-width: unset;
          margin-left: 30px;
          margin-right: 30px;
        }

        .textWrapper {
          padding: 30px 30px;
          max-width: 100%;
        }

        .iconsWrapper {
          max-width: unset;
          padding-right: 30px;
          padding-left: 30px;
        }

        .iconWrapper:nth-child(1) {

          .text {
            max-width: 140px;
          }
        }

        .iconWrapper:nth-child(2) {
          margin-top: 20px;

          .text {
            max-width: 130px;
          }
        }
      }

      .knowMore {
        font-size: 16px;
        margin: 30px auto;
      }
    }

    .doYouKnow {

      .articleWrapper {

        .article {

          .textWrapper {

            .img1 {
              height: 56.7vw;
            }

            .img2 {
              height: 56.7vw;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .wrapper {

    .menu {

      .menuWrapper {
        padding: 0 20px;

        .menuItem {
          width: 100%;
          background-image: unset;
          background-color: #ffffff;
          margin-bottom: 20px;
        }
      }
    }

    .configure {

      .left {

        .top {

          .image {
            display: none;
          }

          .titleWrapper {
            margin-left: 20px;
            margin-right: 20px;
          }
        }

        .bottom {
          padding: 0 20px;

          .formWrapper {
            margin: 40px auto 0;
            max-width: 290px;
          }
        }
      }

      .right {
        width: 100%;

        .top {
          height: 90px;

          .title {
            margin-left: 20px;
            margin-bottom: 30px;
            font-size: 24px;
          }
        }

        .bottom {

          .textWrapper {
            max-width: unset;
            padding-left: 20px;
            padding-right: 20px;
            flex-flow: column;

            .text {
              width: 100%;
            }

            .moreButton {
              margin-top: 20px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .products {
      margin-top: 60px;

      .topWrapper {
        padding: 0 20px;

        .title {
          font-size: 28px;
        }

        .typeWrapper {
          flex-wrap: wrap;
          margin-top: 15px;
          margin-bottom: 5px;

          .change {
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 5px;
          }

          .separator {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }
      }

      .productsWrapper {
        padding: 30px 20px 0;

        .product {
          width: 100%;
        }

        .product:nth-child(2) {
          display: none;
        }
      }
    }

    .workWithUs {

      .left {
        background-image: unset;
        margin-left: unset;
        width: 100%;

        .title {
          max-width: unset;
          margin-left: 20px;
          margin-right: 20px;
        }

        .textWrapper {
          padding: 30px 20px;
          max-width: 100%;
        }

        .iconsWrapper {
          max-width: unset;
          padding-right: 20px;
          padding-left: 20px;
          padding-top: 10px;
        }
      }

      .knowMore {
        font-size: 16px;
        margin: 20px auto;
        padding: 20px;
        justify-content: flex-start;

        &:after {
          margin-left: 20px;
        }
      }
    }

    .doYouKnow {

      .titleWrapper {
        padding-left: 20px;
        padding-right: 20px;

        .title {
          padding-top: 35px;
          font-size: 28px;
        }
      }

      .articleWrapper {

        .article {
          padding: 30px 20px;

          .textWrapper {

            .title {
              font-size: 21px;
            }

            .img1 {
              height: 56.7vw;
            }

            .img2 {
              height: 56.7vw;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 400px) {
  .wrapper {

    .mainSlider {

      .slideWrapper {

        .right {

          .titleGrey {
            font-size: 18px;
          }

          .titleColor {
            font-size: 30px;

            span:first-child {
              margin-right: 3px;
            }

            span:last-child {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .wrapper {

    .configure {

      .left {

        .top {

          .image {
            display: none;
          }

          .titleWrapper {

            .title {
              font-size: 22.6px;
            }
          }
        }

        .bottom {

          .formWrapper {
            max-width: 280px;
          }

        }
      }
    }
  }
}