.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .main {
    max-width: 1295px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;

    .titleContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 50px 0 clamp(24px, 9vw, 50px) 0;

      .title {
        min-width: 200px;
        font-size: 24px;
        color: #050505;
        font-weight: 600;
        font-family: "Encode Sans";

      }

      .title2 {
        min-width: 200px;
        font-size: 18px;
        letter-spacing: 0px;
        color: #636363;
        font-weight: 500;
        font-family: "Roboto";
      }

      .btnContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        //padding-top: 25px;
      }

      .btnGo {
        display: flex;
        align-items: center;
        font-size: clamp(14px, 3.5vw, 18px);
        text-transform: uppercase;
        color: #050505;
        font-weight: 600;
        font-family: "Encode Sans";
        cursor: pointer;
        user-select: none;

        &::after {
          content: "";
          width: 7px;
          height: 12px;
          padding-right: 10px;
          background-image: url("../../assets/img/other/arrowRight.svg");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    .containerWhite {
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #ffffff;
    }

    .containerGrey {
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f7f7f7;
    }

    .content {
      max-width: 1265px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 15px;
    }

    .moreSpace {
      height: 60px;
    }

    .postContainerHorizontal {
      width: 100%;
      display: flex;
      justify-content: space-between;
      column-gap: 25px;
      row-gap: 25px;
    }

    .postContainerVertical {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      column-gap: 25px;
      row-gap: 25px;
    }

    .postContainerHorizontalFirstBig {
      width: 100%;
      display: flex;
      justify-content: space-between;
      column-gap: 25px;
      row-gap: 25px;
    }

    .postContainerHorizontalFirstBig>div:nth-child(n+2) {
      max-width: 300px;
    }

    .postList {
      width: 100%;
      height: clac(320px * 4);
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      column-gap: 25px;
      row-gap: 25px;
      margin-bottom: 80px;
      transition: 0.5s all ease-in-out;
      overflow-y: hidden;
    }

    .postList>div {
      width: calc(50% - 14px);
    }

    .heightAuto {
      height: auto !important;
    }

    .btnMore {
      display: flex;
      align-items: center;
      font-size: 18px;
      text-transform: uppercase;
      color: #050505;
      font-weight: 600;
      font-family: "Encode Sans";
      cursor: pointer;
      user-select: none;
      margin-left: 30px;

      &::after {
        content: "";
        width: 7px;
        height: 12px;
        padding-right: 30px;
        background-image: url("../../assets/img/other/arrowRight.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transform: rotate(90deg);
      }
    }

    .bottom {
      width: 100%;
      display: flex;
      justify-content: space-between;
      column-gap: 25px;

      .title {
        font-size: 18px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 700;
        font-family: "Encode Sans";
        margin-bottom: 20px;
      }

      .description {
        font-size: 16px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
      }

      .left {
        max-width: 400px;
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .right {
        max-width: 780px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 200px;
      }
    }

    .extraPadding {
      padding-left: 15px;
      padding-right: 15px;
    }

    .inputContainer {
      width: 100%;
      height: 50px;
      display: flex;
      position: relative;
      align-items: center;
    }
    
    .input {
      width: 100%;
      height: 47px;
      border-radius: 10px;
      box-shadow: 0 0 3.5px rgba(157, 157, 157, 0.85);
      appearance: none; //!importatn on iphones 
      background-color: #ffffff;
      border: unset;
      font-size: 18px;
      color: #3d3d3d;
      font-weight: 400;
      padding-left: 20px;
      padding-right: 50px;
    }
    
    .loop {
      all: unset;
      background-image: url("../../assets/img/Top/loop.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 25px;
      height: 25px;
      position: absolute;
      right: 20px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 800px) {
  .container .main .bottom {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 700px) {
  .container .main .postList>div {
    width: 100%;
    max-width: 700px;
  }
}

@media screen and (max-width: 500px) {
  .container .main .titleContainer .btnContainer {
    width: 100%;
    margin-top: 25px;
  }
}