.reactSlidedown {
  height: 0;
  width: 100%;
  transition-property: none;
  transition-duration: 1.0s;
  transition-timing-function: ease-in-out;
}

.transitioning {
  overflow-y: hidden;
}

.closed {
  display: none;
}

@media screen and (max-width: 600px) {
  .reactSlidedown {
    transition-duration: 1.5s;
  }
}