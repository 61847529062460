.carousel {
  width: 100%;
  display: flex;
}

.s {
  width: 100%;
  -webkit-user-select: none; //Chrome all / Safari all
  -moz-user-select: none; //Firefox all */
  -ms-user-select: none; // IE 10+ */
  user-select: none; // Likely future */
}

.arrowButton {
  margin: 10px;
  font-size: 36px;
  letter-spacing: 0px;
  line-height: 24px;
  color: #939393;
  font-weight: 250;
  font-family: "Roboto";
  border: none;
  background: none;
  cursor: pointer;
  padding: unset;
  transition: 0.3s;

}

.arrow {
  background-image: url("../../../../assets//img/other/arrowRightGray.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 25px;
  outline: unset;
  border: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff; //transparent;
  cursor: pointer;
  transition: 0.5s opacity;
}

.arrow:disabled {
  opacity: 0;
  pointer-events: none;
}

.leftArrowThum {
  left: -30px;
  transform: rotate(180deg) translateY(50%);
  transition: transform 0.5s;

  &:hover {
    transform: rotate(180deg)translateY(50%) scale(1.2);
  }
}

.rightArrowThum {
  right: -30px;
  transition: transform 0.5s;

  &:hover {
    transform: scale(1.2) translateY(-40%);
  }
}

.splide__arrow:disabled {
  opacity: 0;
  pointer-events: none;
}

.splide__arrow--prev {
  transform: rotate(180deg);
}

.arrowPrev {
  transform: rotate(180deg);
}

.item {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1500px) {
  .carousel {
    width: 95%;
  }
}

@media screen and (max-width: 1200px) {
  .carousel {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .carousel {
    width: 90%;
  }
}

@media screen and (max-width: 450px) {
  .carousel {
    width: 85%;
  }
}