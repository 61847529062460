.container {
    width: 100%;
    max-height: 400px;
    display: flex;
    justify-content: center;
    padding: 15px;
}

.main {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 40px;
}

.vertical {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        min-height: 20px;
        font-size: 16px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        margin: 5px 0;
    }

    a {
        min-height: 20px;
        font-size: 16px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        margin: 20px 0;
        margin-left: 10px;
    }

    .error {
        font-size: 16px;
        letter-spacing: 0px;
        color: transparent;
        font-weight: 400;
        font-family: "Roboto";
        margin: 5px 0;
        transition: 1.0s all ease-in-out;
        opacity: 0;
    }

    .red {
        color: #ff0000;
        opacity: 1;
    }

    .inputContainer {
        display: flex;
        align-items: center;
        max-width: 400px;
        width: 100%;
        min-height: 50px;
        border-radius: 15px;
        background-color: #ffffff;
        border: 1px solid #b2b2b2;
        margin: 4px 0;
        position: relative;

        input::placeholder{
            -webkit-text-security: initial;
        }

        .animationContainer {
            display: flex;
            position: absolute;
            padding: 0 15px;
            opacity: 1;
            transition: 0.5s all ease-in-out;
            pointer-events: none;
        }

        span {
            font-size: 16px;
            letter-spacing: 0px;
            color: #ffffff;
            font-weight: 400;
            font-family: "Roboto";
            animation: show 0.5s forwards;
            opacity: 0.8;
            user-select: none;

        }

        @keyframes show {
            from {
                color: #000000;
            }

            to {
                color: #000000;
            }
        }
    }

    input {
        all: unset;
        max-width: 400px;
        width: 100%;
        min-height: 50px;
        border-radius: 15px;
        padding: 0 15px;
        pointer-events: auto;
    }

    input::placeholder {
        all: unset;
        font-size: 16px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        opacity: 0.8;
    }

    .hide {
        opacity: 0 !important;
    }

    .btnGrey {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 400px;
        width: 100%;
        height: 60px;
        border-radius: 20px;
        background-color: #707070;
        border: 1px solid #707070;
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 18px;
        color: #ffffff;
        font-weight: 500;
        font-family: "Roboto";
        user-select: none;
        cursor: pointer;
        margin: 5px 0;
    }

    .btnWhite {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 400px;
        width: 100%;
        height: 60px;
        border-radius: 20px;
        background-color: #ffffff;
        border: 1px solid #303030;
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 18px;
        color: #141414;
        font-weight: 500;
        font-family: "Roboto";
        text-align: center;
        user-select: none;
        cursor: pointer;
        margin: 5px 0;
    }

    .iconOk {
        display: none;
        width: 14px;
        height: 11px;
        background-image: url("../../../assets/img/Login/iconOk.png");
        background-size: contain;
        background-repeat: no-repeat;
        user-select: none;
        position: absolute;
        right: 20px;
    }

    .iconShow {
        display: inline;
    }
}

.horizontal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.verticalLineContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .verticalLine {
        height: 100%;
        border-right: 1px solid #d4d4d4;
    }

    .text {
        font-size: 18px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 500;
        font-family: "Roboto";
        margin-top: 15px;
    }

}

.horizontalLineContainer {
    width: 100%;
    display: none;
    position: relative;

    .line {
        width: 100%;
        border-bottom: 1px solid #d4d4d4;
        position: relative;
        bottom: 10px;
        margin: 0 5px;
    }

    .text {
        font-size: 18px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 500;
        font-family: "Roboto";
        margin-top: 15px;
    }

}

.padding {
    padding-left: 60px;
}

.titleCreate {
    display: flex;
    align-items: center;
    font-size: 24px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 500;
    font-family: "Roboto";
    margin-bottom: 0px;
}

.titleLogin {
    display: flex;
    align-items: center;
    font-size: 24px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 500;
    font-family: "Roboto";
    margin-bottom: 25px;
}

.titleCreate::before {
    content: "";
    width: 41px;
    height: 41px;
    background-image: url("../../../assets/img/Login/iconAccountAdd.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 17px;
    flex-shrink: 0;
}

.titleLogin::before {
    content: "";
    width: 38px;
    height: 46px;
    background-image: url("../../../assets/img/Login/iconAccount.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 20px;
    flex-shrink: 0;
}

@media screen and (max-width: 900px) {
    .container{
        max-height: unset;
    }
    
    .main{
        height: 100%;
        flex-direction: column;
        align-items: center;
    }

    .verticalLineContainer{
        display: none;
    }

    .horizontalLineContainer{
        display: flex;
    }

    .vertical{
        max-width: 400px;
        width: 100%;
    }

    .padding{
        padding-left: 0px;
    }

    .titleLogin{
        margin-top: 30px;
        margin-bottom: 5px;
    }
}