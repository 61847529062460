.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 700px;
  position: relative;

  .img {
    display: flex;
    width: 100vw;
    padding-top: clamp(550px, 50%, 700px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
  }

  .shadow {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #1a1a1a 0%, #1a1a1a 14%, #b5b5b5 100%);
    mix-blend-mode: multiply;
    position: absolute;
    border-radius: 8px;
    top: 0;
  }

  .content {
    max-width: 1260px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    padding-left: 15px;
    padding-right: 15px;

    .title, h2 {
      font-size: clamp(20px, 5.0vw, 54px);
      color: #ffffff;
      font-weight: 600;
      font-family: "Encode Sans";
      margin-top: clamp(20px, 6.5vw, 140px);
      margin-bottom: 0;
    }

    .description  {
      max-width: 935px;
      font-size: clamp(16px, 4.5vw, 24px);
      letter-spacing: 0px;
      line-height: clamp(20px, 7.5vw, 36px);
      color: #eeeeee;
      font-weight: 400;
      font-family: "Roboto";
      margin: 0;

      p{
        margin: clamp(10px, 4.5vw, 60px) 0 clamp(10px, 4.5vw, 60px);
      }
    }

    .btn {
      width: 250px;
      height: 66px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      letter-spacing: 0px;
      color: #323232;
      font-weight: 600;
      font-family: "Encode Sans";
      border-radius: 20px;
      filter: drop-shadow(1px 1.732px 3.5px rgba(0, 0, 0, 0.17));
      background-image: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
      cursor: pointer;
      user-select: none;
      z-index: 10;
    }
  }
}