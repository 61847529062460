.paginationButton {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #f2f2f2;
  border: unset;
  cursor: pointer;
  padding: unset;
  transition: 0.3s;
}

.paginationButton .is-active {
  transform: scale(0.6);
  background-color: #df1319;
}