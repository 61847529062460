.loader{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 100000000;
  background-color: white;
  flex-flow: column;
  opacity: 1;
  visibility: visible;
  transition: 0.4s all;
  cursor: pointer;
}

.loaderInner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  margin-left: calc(50vw - 60px);
  background-image: url("../../assets/img/other/globe.png");
  background-position: center;
  background-size: contain;
}

.one{
  height: 120px;
  width: 120px;
  animation: pulse linear infinite;
  animation-duration: 1.5s;
  border: 2px solid #e0141a;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
}
.two{
  height: 120px;
  width: 120px;
  animation: pulse linear infinite;
  animation-duration: 1.5s;
  border: 2px solid #e0141a;
  border-radius: 50%;
  animation-delay: 0.75s;
  position: absolute;
  opacity: 0;
}


@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  25% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
  0% {
    transform: scale(1);
    opacity: 0;
  }
}