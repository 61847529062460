@import '../../assets/styles/variables';

.breadcrumbContainer {
    display: flex;
    justify-content: flex-start;
    box-shadow: inset 2.5px 4.33px 2px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    margin-bottom: 10px;
}

.breadcrumb {
    width: 100%;
    max-width: 1770px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px 115px 10px 115px;
    margin-left: auto;
    margin-right: auto;
    appearance: none;
}

.container {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    width: 100%;
    max-width: 1710px;
    column-gap: 50px;
    padding-left: 85px;
    padding-right: 25px;
    margin-left: auto;
    margin-right: auto;

    .containerLeft {
        display: flex;
        flex-flow: column;
        justify-content: center;
        width: 100%;
        max-width: 1110px;

        .littleInfo {
            display: flex;
            flex-flow: row;
            column-gap: 100px;

            .right {
                width: 100%;
                display: flex;
                flex-direction: column;

                .top {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .topLine {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-end;

                        .left {
                            display: flex;
                            align-items: flex-end;

                            .image {
                                display: flex;
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center;
                                width: 89px;
                                height: 47px;
                                margin-left: 30px;
                                margin-right: 30px;
                                flex-shrink: 0;
                                // cursor: pointer;
                                transition: 0.3s all ease-in-out;

                                // &:hover {
                                //     transform: scale(1.1);
                                // }
                            }
                        }

                        .right {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                        }

                    }

                    .title {
                        font-size: 24px;
                        letter-spacing: 0px;
                        color: #000000;
                        font-weight: 500;
                        font-family: "Roboto";
                        margin-top: 30px;
                    }

                    .rating {
                        display: flex;
                        align-items: flex-start;
                        margin-top: 15px;
                        margin-bottom: 15px;

                        .star {
                            display: flex;
                            align-items: flex-start;
                            width: auto;

                            font-size: 18px;
                            letter-spacing: 0px;
                            color: #e0131a;
                            font-weight: 500;
                            font-family: "Roboto";
                            text-align: center;
                            cursor: pointer;
                            transition: 0.5s all;

                            &::before {
                                content: "";
                                background-image: url("../../assets/img/other/star.svg");
                                background-size: contain;
                                background-repeat: no-repeat;
                                width: 18px;
                                height: 18px;
                                margin-right: 10px;

                            }

                            &::first-letter {
                                color: #e0131a;
                            }

                            &::after {
                                content: "| 5";
                                color: #555555;
                                margin-left: 5px;
                            }
                        }
                    }

                    .text {
                        font-size: 14px;
                        letter-spacing: 0px;
                        line-height: 24px;
                        color: #555555;
                        font-weight: 400;
                        font-family: "Roboto";
                        margin-left: 20px;
                    }


                    .description {
                        font-size: 14px;
                        letter-spacing: 0px;
                        line-height: 24px;
                        color: #555555;
                        font-weight: 400;
                        font-family: "Roboto";
                    }
                }

                .bottom {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding-top: 30px;
                    margin-top: 10px;
                    border-top: 1px solid #e0e0e0;

                    .description {
                        font-size: 16px;
                        line-height: 30px;
                        color: #484848;
                        font-weight: 400;
                        font-family: "Roboto";
                    }

                    .btnParametersContainer {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        margin-top: 20px;
                        user-select: none;
                        z-index: 1;

                        .text {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 16px;
                            letter-spacing: 0px;
                            line-height: 18px;
                            color: #000000;
                            font-weight: 400;
                            font-family: "Roboto";
                            padding: 5px;
                            transition: 0.3s all;
                            border-bottom: 1px solid transparent;
                            z-index: 5;

                            @include underline-hover(#000000);


                        }

                        .char {
                            content: "";
                            width: 12px;
                            height: 8px;
                            display: flex;
                            background-image: url("../../assets/img/other/arrowDownBlack.png");
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            margin-left: 15px;
                        }
                    }

                    .btnParametersPlace {
                        display: flex;
                        position: relative;
                    }
                }

                .bottomButton {
                    display: flex;
                    font-size: 13px;
                    line-height: 14px;
                    color: #6a6a6a;
                    font-weight: 400;
                    font-family: "Roboto";
                    white-space: nowrap;
                    margin-top: 50px;
                    user-select: none;

                    .printButton {
                        width: auto;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        transition: 0.3s all;
                        margin-top: 10px;

                        &::before {
                            content: "";
                            background-image: url("../../assets/img/buttons/printer.png");
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: left;
                            width: 15px;
                            height: 15px;
                            padding-right: 10px;
                        }

                    }

                    .shareButton {
                        width: auto;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        transition: 0.3s all;
                        margin-top: 10px;
                        margin-left: 50px;

                        &::before {
                            content: "";
                            background-image: url("../../assets/img/buttons/share.png");
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: left;
                            width: 15px;
                            height: 15px;
                            padding-right: 10px;
                        }

                    }

                    .saveButton {
                        width: auto;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        transition: 0.3s all;
                        margin-top: 10px;
                        margin-left: 50px;

                        &::before {
                            content: "";
                            background-image: url("../../assets/img/buttons/save.png");
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: left;
                            width: 15px;
                            height: 15px;
                            padding-right: 10px;
                        }
                    }
                }
            }
        }

        .moreInfo {
            width: 100%;
            max-width: 800px;
            display: flex;
            flex-flow: column;
            margin-top: 120px;
            margin-left: 60px;

            .title {
                font-size: 18px;
                letter-spacing: 0px;
                line-height: 18px;
                color: #000000;
                font-weight: 400;
                font-family: "Roboto";
            }

            .text {
                width: 100%;
                margin-top: 15px;
                font-size: 14px;
                letter-spacing: 0px;
                line-height: 24px;
                color: #484848;
                font-weight: 400;
                font-family: "Roboto";

                p {
                    width: 100%;
                    margin-top: 15px;
                    font-size: 14px;
                    letter-spacing: 0px;
                    line-height: 24px;
                    color: #484848;
                    font-weight: 400;
                    font-family: "Roboto";
                }
            }
        }

        .detailedInformation {
            width: 100%;
            max-width: 1065px; //1100-35
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: flex-start;
            margin-left: 60px;
            margin-top: 50px;

            .title {

                font-size: 18px;
                letter-spacing: 0px;
                line-height: 18px;
                color: #000000;
                font-weight: 400;
                font-family: "Roboto";
                margin-bottom: 15px;
            }
        }

        .compareLast {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;
            margin-left: 75px;
        }
    }

    .containerRight {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        width: 100%;
        max-width: 335px;
        row-gap: 20px;
        margin-top: 60px;
        margin-right: 0px;

        .stats {
            border: 1px solid #e0e0e0;
            padding-bottom: 55px;
            width: 100%;

            .top {
                display: flex;
                flex-flow: column;
                margin-top: 30px;

                .price1 {
                    font-size: 26px;
                    letter-spacing: 0px;
                    line-height: 27px;
                    color: #000000;
                    font-weight: 500;
                    font-family: "Roboto";
                    margin-left: 50px;
                }

                .price2 {
                    font-size: 20px;
                    letter-spacing: 0px;
                    line-height: 27px;
                    color: #c5c5c5;
                    font-weight: 500;
                    font-family: "Roboto";
                    margin-left: 50px;
                    margin-top: 5px;
                }

                .cartButton {
                    width: calc(100% - 25px); //width: 304px;
                    height: 57px;
                    border-radius: 5px;
                    background-color: #ffffff;
                    border: 1px solid #e0e0e0;
                    padding-left: 20px;
                    margin-top: 50px;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    cursor: pointer;
                    transition: 0.5s all;
                    white-space: nowrap;
                    user-select: none;
                    position: relative;

                    &:hover {
                        box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.2)
                    }
                }

                .amount {
                    margin: 10px 10px 15px 55px;
                }
            }

            .center {
                width: 100%;
                font-size: 18px;
                letter-spacing: 0px;
                line-height: 18px;
                color: #484848;
                font-weight: 400;
                font-family: "Roboto";
                padding: 20px;
                padding-left: 55px;
                border-top: 1px solid #e0e0e0;
                border-bottom: 1px solid #e0e0e0;
            }

            .bottom {
                display: flex;
                flex-direction: column;
                row-gap: 15px;
                width: 100%;
                font-size: 18px;
                letter-spacing: 0px;
                line-height: 24px;
                color: #484848;
                font-weight: 400;
                font-family: "Roboto";
                padding: 0 5px 0 55px;
                margin-top: 30px;
                margin-bottom: 10px;
            }
        }

        .bottom2 {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 20px;

            .text {
                width: 250px;
                font-size: 18px;
                letter-spacing: 0px;
                line-height: 24px;
                color: #484848;
                font-weight: 400;
                font-family: "Roboto";
                margin-left: 55px;
            }

            .askButton {
                width: calc(100% - 40px);
                height: 57px;
                border-radius: 5px;
                background-color: #f4f4f4;
                border: 1px solid #e0e0e0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                letter-spacing: 0px;
                line-height: 18px;
                color: #000000;
                font-weight: 400;
                font-family: "Roboto";
                margin-top: 0px;
                margin-left: 20px;
                margin-right: 20px;
                cursor: pointer;
                transition: 0.5s all;
                user-select: none;

                &:hover {
                    box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.2)
                }
            }
        }

    }
}

.cost {
    width: 100%;
    padding-left: 48px;

    .priceNormalNet {
        font-size: 26px;
        letter-spacing: 0px;
        line-height: 27px;
        color: #000000;
        font-weight: 500;
        font-family: "Roboto";
        padding: 2px;
    }

    .priceNormalGross {
        font-size: 20px;
        letter-spacing: 0px;
        line-height: 27px;
        color: #c5c5c5;
        font-weight: 500;
        font-family: "Roboto";
        padding: 2px;

    }

    .priceOld {
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 24px;
        color: #c5c5c5;
        font-weight: 400;
        font-family: "Roboto";
        text-decoration: line-through;
        padding: 2px;
    }

    .priceNew {
        font-size: 26px;
        letter-spacing: 0px;
        line-height: 18px;
        color: #c41218;
        font-weight: 500;
        font-family: "Roboto";
        padding: 2px;
    }
}

.costPadding {
    padding: 15px 0 0 0;
}

.compareContainer {
    display: flex;

    left: 0;
    width: 100vw;
    height: 132px;

    filter: drop-shadow(1px 1.732px 23px rgba(0, 0, 0, 0.22));
    background-color: #ffffff;
    z-index: 100;

    position: fixed;

    top: 50px;
    transition: 0.8s all ease;
}

.compareButton {
    width: 100%;
    max-width: 300px;
    height: 57px;
    border-radius: 5px;
    background-color: #f4f4f4;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    cursor: pointer;
    transition: 0.5s all;
    user-select: none;

    &:hover {
        box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.2)
    }
}

.compareProducts {
    display: flex;
    width: 100%;
    max-width: 1000px;
    padding-left: 40px;
    padding-right: 20px;
}

.compareButtonContainer {
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 10px;
}

.compareItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90px;
    border-right: 1px solid #e0e0e0;

    .itemImg {
        width: 80px;
        height: 50px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .itemText {
        font-size: 12px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        margin-top: 15px;
    }

    .itemButton {
        width: 11px;
        height: 9px;
        background-image: url("../../assets/img/buttons/xButton.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        transition: 0.5s all;
        cursor: pointer;

        &:hover {
            transform: scale(1.2);
        }
    }
}

.arrowContainer {
    width: 35px;
    height: 25px;
    border-radius: 5px;
    background-color: #ffffff;

    position: relative;
    top: -65px;
    right: -60px; //calc(460 / 1900 * 100%); //460px;
    transition: 0.5s all;

    &:hover {
        transform: scale(1.2);
        box-shadow: 0 1px 8px rgba(#000000, 0.2);
    }
}

.compareArrow {
    width: 35px;
    height: 25px;
    background-image: url("../../assets/img/other/arrowDownGrayCompare.png");
    background-size: 17px 11px;
    background-repeat: no-repeat;
    background-position: center;
    transition: 1.0s all;
}

.arrowRotate {
    transform: rotate(180deg);
}

.containerShift {
    top: -80px !important;
}

.containerShiftOut {
    top: -50px;
}

.scrolledUp {
    top: -200px !important;
}

.scrolledDown {
    top: calc(100vh + 10px) !important;
}

.scrolledDownShowButton {
    top: 100vh !important;
}

.table {
    height: 70px;
    width: 100%;
    border-collapse: collapse;
    //border: 1px solid;

    tr {
        td {
            border-top: 1px solid;

            border-color: #e0e0e0;
            font-size: 14px;
            letter-spacing: 0px;
            line-height: 18px;
            color: #000000;
            font-weight: 350;
            font-family: "Roboto";
            padding-left: 10px;


        }

        td:nth-last-child(n) {
            border-bottom: 1px solid #e0e0e0;
        }

        td:first-child {

            border-right: 1px solid #e0e0e0;
            font-weight: 400;
        }


    }
}

.table2 {
    width: 100%;
    display: flex;
    flex-direction: column;

    .row {
        width: 100%;
        display: flex;
        border-color: #e0e0e0;
        font-size: 14px;
        letter-spacing: 0px;
        line-height: 18px;
        color: #000000;
        font-weight: 350;
        font-family: "Roboto";


        .element {
            width: 50%;
            padding-left: 20px;
            padding-top: 15px;
            padding-bottom: 15px;

            border-top: 1px solid #e0e0e0;
        }

        .element:first-child {
            border-right: 1px solid #e0e0e0;
            font-weight: 400;
        }

        .noLine {
            border-top: none;
        }
    }
}

.table2:nth-last-child(n) {
    border-bottom: 1px solid #e0e0e0;
}

.option {
    max-width: 220px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .optionText {
        font-size: 14px;
        letter-spacing: 0px;
        line-height: 18px;
        color: #484848;
        font-weight: 400;
        font-family: "Roboto";
        margin-top: 10px;
    }
}

.CartNew {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow-x: hidden;

    div:first-child {
        font-size: 18px;
        color: #000000;
        font-weight: 500;
        padding-left: 20px;
    }

    .cartIcon {
        background-image: url("../../assets/img/other/cartBlack.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 32px;
        height: 28px;
        margin: 0 auto;
    }


    .animationGo {
        animation: animationCartIcon 1.0s forwards;
    }

    @keyframes animationCartIcon {
        0% {
            transform: translateX(0px);
        }

        20% {
            transform: translateX(-10px);
        }

        80% {
            width: 32px;
            height: 28px;
            transform: translateX(80px);
        }

        80.1% {
            width: 0px;
            height: 0px;

        }

        81% {
            transform: translateX(0px);
        }

        100% {
            width: 32px;
            height: 28px;
            transform: scale(1);
            transform: translateX(0px);
        }
    }
}

.cartNew2 {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: hidden;

    div:first-child {
        width: calc(90% - 32px); //85%;
        font-size: 22px;
        color: #000000;
        font-weight: 500;
        padding-left: 10px;
    }

    .cartIcon {
        background-image: url("../../assets/img/other/cartBlack.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 32px;
        height: 28px;
        margin: 0 auto;
        flex-shrink: 0;
    }

    .animationGo2 {
        animation: animationCartIcon2 1.0s forwards;
    }

    @keyframes animationCartIcon2 {
        0% {
            transform: translateX(0px);
        }

        20% {
            transform: translateX(-10px);
        }

        80% {
            width: 32px;
            height: 28px;
            transform: translateX(80px);
        }

        80.1% {
            width: 0px;
            height: 0px;

        }

        81% {
            transform: translateX(0px);
        }

        100% {
            width: 32px;
            height: 28px;
            transform: scale(1);
            transform: translateX(0px);
        }
    }
}

.cartInfo {
    width: 100%;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #f4f4f4;
    ;
    box-shadow: 2px 2px 10px rgba($color: #000000, $alpha: 0.2);
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    text-align: center;
    white-space: normal;
    position: absolute;
    top: -45px;
    left: 0;
    transition: 0.5s all ease-in-out;
    padding: 0 10px;
    margin: auto auto 5px;
    opacity: 0;
}

.cartInfoOpacity {
    opacity: 0.75;
}

.saleButton {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    letter-spacing: 0px;
    line-height: 14px;
    text-transform: uppercase;
    color: #383838;
    font-weight: 500;
    font-family: "Roboto";
    padding: 10px;
    cursor: pointer;
    transition: 0.3s all;
    user-select: none;

    &:hover {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2)
    }

    &::before {
        content: "%";
        text-transform: uppercase;
        color: #e1131a;
        margin-right: 2px;
    }
}

.amountBig {

    font-size: 12px;
    line-height: 18px;
    color: #279439;
    font-weight: 400;
    font-family: "Roboto";
    //padding-left: 55px;
    //margin-top: 10px;
    //margin-bottom: 10px;
    display: flex;
    align-items: center;

    &::before {
        content: "";
        height: 10px;
        width: 10px;
        background-color: #279439;
        border-radius: 50%;
        margin-right: 5px;
        display: inline-block;
    }
}

.amountSmall {
    font-size: 12px;
    line-height: 18px;
    color: #ff8502;
    font-weight: 400;
    font-family: "Roboto";
    //padding-left: 55px;
    //margin-top: 10px;
    //margin-bottom: 10px;
    display: flex;
    align-items: center;

    &::before {
        content: "";
        height: 10px;
        width: 10px;
        background-color: #ff8502;
        border-radius: 50%;
        margin-right: 5px;
        display: inline-block;
    }
}

.amountNone {
    font-size: 12px;
    line-height: 18px;
    color: #e1131a;
    font-weight: 400;
    font-family: "Roboto";
    //padding-left: 55px;
    //margin-top: 10px;
    //margin-bottom: 10px;

    display: flex;
    align-items: center;

    &::before {
        content: "";
        height: 8px;
        width: 8px;
        background-color: #fff;
        border: 1px solid #e1131a;
        border-radius: 50%;
        margin-right: 5px;
        display: inline-block;
    }
}

.containerBottom {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 1740px;
    padding-left: 70px;
    padding-right: 70px;
    margin: 50px auto 100px auto;

    .title {
        font-size: 18px;
        text-transform: uppercase;
        color: #141414;
        font-weight: 400;
        font-family: "Roboto";
        position: relative;
    }

    .productList {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        column-gap: 20px;
        row-gap: 20px;
        margin-top: 30px;
        overflow: hide;
    }

}

.gallery {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
}

.checkbox {
    display: block;
    position: relative;

    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 13px;
    line-height: 14px;
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";

    padding: 2px 0;
    padding-left: 20px;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    border-radius: 2px;
    background-color: #fdfdfd;
    border: 1px solid rgba(69, 69, 69, 0.66);
}

/* Create */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* On mouse color*/
// .checkbox:hover input~.checkmark {
//     background-color: #e1131aa1;
// }

/* if check color */
.checkbox input:checked~.checkmark {
    background-color: #e11319;
    border: 1px solid #e41017;
}

/* Show symbol */
.checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator */
.checkbox .checkmark:after {
    left: 5px;
    top: 1px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.shopMenu {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border: 1px solid #e0e0e0;

    .containerTop {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .top1 {
            display: flex;
            flex-direction: column;
            margin: 10px;
            padding-left: 25px;

            .price1 {
                width: auto;
                font-size: 26px;
                letter-spacing: 0px;
                line-height: 27px;
                color: #000000;
                font-weight: 500;
                font-family: "Roboto";
            }

            .price2 {
                width: auto;
                font-size: 20px;
                letter-spacing: 0px;
                line-height: 27px;
                color: #c5c5c5;
                font-weight: 500;
                font-family: "Roboto";
            }
        }

        .top2 {
            display: flex;
            flex-direction: column;

            .cartButton {
                width: calc(100% - 25px); //width: 304px;
                height: 57px;
                border-radius: 5px;
                background-color: #ffffff;
                border: 1px solid #e0e0e0;

                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                transition: 0.3s all;
                white-space: nowrap;
                margin: 40px 10px 10px;
                padding-left: 20px;
                user-select: none;
                position: relative;

                &:hover {
                    box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.2)
                }
            }

            .amount {
                margin: 10px 10px 10px 45px;
                white-space: nowrap;
            }
        }
    }

    .containerCenter {
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 24px;
        color: #484848;
        font-weight: 400;
        font-family: "Roboto";
        padding-left: 45px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
    }

    .containerBottom1 {
        display: flex;
        align-items: flex-end;
        column-gap: 25%;
        margin-left: 45px;
        padding-top: 10px;
        padding-bottom: 10px;

        .left {
            font-size: 18px;
            letter-spacing: 0px;
            line-height: 24px;
            color: #484848;
            font-weight: 400;
            font-family: "Roboto";
        }

        .left>div {
            margin-top: 10px;
        }

        .right {
            white-space: nowrap;
            width: 200px;
            flex-shrink: 0.5;
        }
    }


}

.containerBottom2 {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
        width: 100%;
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 24px;
        color: #484848;
        font-weight: 400;
        font-family: "Roboto";
        text-align: center;
        text-overflow: clip;
    }

    .askButton {
        width: calc(100% - 20px);
        max-width: 400px;
        height: 57px;
        border-radius: 5px;
        background-color: #f4f4f4;
        border: 1px solid #e0e0e0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 18px;
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
        transition: 0.5s all;
        user-select: none;

        &:hover {
            box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.2)
        }
    }
}

@media screen and (max-width: 1700px) {
    .container {
        padding-left: 115px;
        padding-right: 40px;

        .containerLeft {
            .littleInfo {
                column-gap: 50px;
            }
        }
    }
}

//move info
@media screen and (max-width: 1520px) {
    .container {
        .containerLeft {
            .littleInfo {
                flex-direction: column;
                column-gap: 0px;

                .gallery {
                    margin-bottom: 20px;
                    align-self: center;
                    margin-left: 0;
                }
            }

            .moreInfo {
                margin-left: 0px;
            }

            .detailedInformation {
                margin-left: 0px;
            }

            .compareLast {
                margin-left: 20px;
            }
        }

        .containerRight {
            margin-right: 0;
        }
    }

    .containerBottom {
        .productList {
            margin-top: 0;
        }
    }

    .compareProducts {
        max-width: 800px;
    }
}

//padding
@media screen and (max-width: 1320px) {
    .breadcrumb {
        padding: 10px 40px 10px 40px;
    }

    .container {
        padding-left: 40px;
        padding-right: 40px;
    }

    .containerBottom {
        .title {
            left: -25px;
        }
    }

    .arrowContainer {
        right: 0px;
    }
}

@media screen and (max-width: 1150px) {
    .compareProducts {
        max-width: 600px;
    }
}

//small menu
@media screen and (max-width: 1100px) {
    .container {

        .containerRight {
            width: 30%;
            min-width: 220px;

            .stats {
                .top {
                    .price1 {
                        font-size: 24px; //26px;
                        margin-left: 25px; //50px;
                    }

                    .price2 {
                        font-size: 18px; //20px;
                        margin-left: 25px; //50px;
                    }

                    .cartButton {
                        width: calc(100% - 25px); //304px;
                        height: 57px;
                        font-size: 14px; //18px;
                    }

                    .amount {
                        margin: 10px 10px 10px 35px;
                    }
                }

                .center {
                    font-size: 16px; //18px;
                    padding-left: 35px;
                }

                .bottom {
                    font-size: 16px; //18px;
                    padding-left: 35px;
                }
            }

            .bottom2 {
                .askButton {
                    font-size: 16px; //18px;
                }

                .text {
                    font-size: 16px; //18px;
                }
            }
        }
    }

    .CartNew {
        div:first-child {
            font-size: 16px; //18px;
            padding-left: 0px; //20px;
        }

        .cartIcon {
            width: 22px; //32px; * 0.7
            height: 19px; //28px;
            margin: 0 auto;
        }

        @keyframes animationCartIcon {
            0% {
                transform: translateX(0px);
            }

            20% {
                transform: translateX(-10px);
            }

            80% {
                width: 22px;
                height: 19px;
                transform: translateX(80px);
            }

            80.1% {
                width: 0px;
                height: 0px;

            }

            81% {
                transform: translateX(0px);
            }

            100% {
                width: 22px;
                height: 19px;
                transform: scale(1);
                transform: translateX(0px);
            }
        }
    }

    .containerBottom {
        padding-left: 60px;
        padding-right: 60px;

        .title {

            left: -20px;
        }
    }
}

// no right menu
@media screen and (max-width: 950px) {
    .container {
        .containerLeft {
            .littleInfo {
                .right {
                    .bottomButton {
                        margin-top: 20px;

                        .printButton {
                            margin-bottom: 20px;
                        }

                        .shareButton {
                            margin-bottom: 20px;
                        }

                        .saveButton {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }

        .containerRight {
            display: none;
        }
    }

    .gallery {
        align-items: center;
    }

    .containerBottom2 {
        .askButton {
            margin-bottom: 20px;
        }
    }

    .compareProducts {
        max-width: 400px;
    }

    .option{
        padding: 0;
    }
}

//padding
@media screen and (max-width: 750px) {
    .breadcrumb {
        padding: 10px 20px 10px 20px;
    }

    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 650px) {
    .compareContainer {
        flex-wrap: wrap;
        height: 200px;
    }

    .compareButtonContainer {
        margin-left: 0;
        max-width: unset;
        justify-content: center;
    }

    .compareProducts {
        max-width: unset;
    }

    .arrowContainer {
        position: absolute;
        top: -10px;
        right: 10px;
    }

    .compareButton {
        max-width: 300px;
        margin-bottom: 10px;
    }

    .containerShift {
        top: -25px !important;
    }
}

@media screen and (max-width: 500px) {
    .container {
        .containerLeft {
            .littleInfo {
                .right {
                    .top {
                        .topLine {
                            flex-wrap: wrap;

                            .left {
                                width: 100%;
                                justify-content: space-between;

                                .image {
                                    margin: 0;
                                }
                            }

                            .right {
                                margin-top: 20px;
                            }
                        }
                    }

                    .bottomButton {
                        flex-direction: column;

                        .shareButton {
                            margin-left: 0;
                        }

                        .saveButton {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    .shopMenu {
        .containerBottom1 {
            align-items: flex-start;
            flex-direction: column;

            .right {
                margin-top: 10px;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .compareItemContainer {
        border: none;
    }
}

@media screen and (max-width: 350px) {
    .compareButton {
        max-width: 250px;
    }
}