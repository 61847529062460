.wrapper {
  display: flex;
  // max-width: 1090px;
  max-width: 1160px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 5000;

  .menuContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .menuSubContainer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      .itemsWrapper {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        //for mozilla
        scrollbar-color: #ed000077 #d6252523;

        .title {
          font-size: 18px;
          color: #000000;
          font-weight: 500;
          padding: 10px 0 0 0;
          text-align: left;
          border-top: 1px solid #777777;
          margin: 0 20px 5px 0;
        }

        .itemWrapper {
          height: 60px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          position: relative;
          margin: 0 10px;

          .maniCat {
            font-size: 18px;
            color: #2a2a2a;
            font-weight: 500;
            padding: 10px 0;
            user-select: none;
            text-align: center;
            transition: 0.3s all ease-in-out;

            &:hover {
              color: #e0131a;
            }
          }

          .maniCatActive {
            color: #e0131a;

            &:hover {
              color: #e0131a;
            }
          }
        }

        .itemWrapper:first-child {
          margin-left: unset;
        }

        .itemWrapper:last-child {
          margin-right: unset;
        }
      }

      //another then mozilla
    .itemsWrapper::-webkit-scrollbar {
      width: 5px;
      background-color: #d6252523;
    }

    .itemsWrapper::-webkit-scrollbar-thumb {
      background: #ed000077;
      border-radius: 15px;
    }

      .contactWrapper {
        display: flex;
        border-top: 2px solid #2a2a2a;
        margin-top: auto;
        align-items: center;
        padding-top: 10px;
        margin: 0 20px 0 0;

        .icon {
          background-image: url("../../assets/img/Top/phone_black.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% auto;
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }

        .phone {
          color: #2a2a2a;
          font-size: 16px;
          font-weight: 500;
        }

        .contact {
          color: #2a2a2a;
          font-size: 16px;
          font-weight: 500;
          display: flex;
          align-items: center;
          transition: 0.3s all ease-in-out;

            &:hover {
              color: #e0131a;
            }

          &:before {
            content: "";
            width: 2px;
            background-color: #2a2a2a;
            display: flex;
            margin: 0 18px;
            height: 14px;
          }
        }
      }
    }

    
  }
}

.btn {
  width: 40px;
  cursor: pointer;
  flex-shrink: 0;
  z-index: 1;
  display: none;

  span {
    display: block;
    width: 100%;
    height: 4px;
    background: #3d3d3d;
    transition: all .3s;
    position: relative;
    border-radius: 10px;

    +span {
      margin-top: 8px;
    }
  }
}

.activeMain {}

.active {
  margin-left: -4px;
  margin-bottom: 20px;
}

.active span:nth-child(1) {
  top: 12px;
  transform: rotate(45deg);
}

.notActive span:nth-child(1) {
  top: 0;
  transform: rotate(0deg);
}

.active span:nth-child(2) {
  transform: scale(0);
}

.notActive span:nth-child(2) {
  transform: scale(1);
}

.active span:nth-child(3) {
  bottom: 12px;
  transform: rotate(135deg);
}

.notActive span:nth-child(3) {
  bottom: 0;
  transform: rotate(0);
}


@media screen and (max-width: 1320px) {
  .wrapper {

    .menuContainer {

      .menuSubContainer {

        .itemsWrapper {

          .itemWrapper {

            .maniCat {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .btn {
    display: block;
  }

  .wrapper {
    width: unset;
    margin-right: 20px;

    .menuContainer {
      position: fixed;
      width: 100%;
      z-index: 1000;
      transition: 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
      align-items: unset;
      top: 0;
      left: 0;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      visibility: hidden;

      .menuSubContainer {
        max-width: 300px;
        flex-flow: column;
        align-items: unset;
        background-color: #ffffff;
        padding: 20px 1px 20px 20px;
        transform: translateX(-100%);
        transition: 0.5s cubic-bezier(0.39, 0.58, 0.57, 1);
        justify-content: unset;

        .itemsWrapper {
          flex-flow: column;
          align-items: unset;
          overflow-y: auto;

          .itemWrapper {
            margin: unset;
            justify-content: flex-start;
          }

          .itemWrapper:last-child {
            margin-bottom: auto;
          }
        }
      }
    }

    .menuContainerShow {
      background-color: rgba(0, 0, 0, 0.5);
      visibility: visible;

      .menuSubContainer {
        transform: translateX(0);
      }
    }


  }
}