.container {
  //max-width: 620px;
  width: 100%;
  display: flex;
  flex-direction: column;



  .img {
    width: 100%;
    display: flex;
    padding-top: clamp(250px, 80%, 400px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    border-radius: 12px;
    filter: drop-shadow(1px 1.732px 8px rgba(0, 0, 0, 0.09));
    box-shadow: inset 1px 1.732px 16px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
  }

  .shadow {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #d2d4e7 0%, #d2d4e7 25%, #0f0e37 100%);
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    border-radius: 12px;
    opacity: 0.18;
  }

  .textContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    margin-top: 40px;

    .title ,h1 {
      font-size: 36px;
      color: #000000;
      font-weight: 600;
      font-family: "Encode Sans";
    }

    .description {
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      font-weight: 400;
      font-family: "Roboto";
      margin: 30px 0;
      
      p{
        font-size: 18px;
      line-height: 24px;
      color: #000000;
      font-weight: 400;
      font-family: "Roboto";
      margin: 30px 0;
      }
      
      span{
        color: #e31111;
      }
    }

    .point {
      font-size: 18px;
      letter-spacing: 0px;
      color: #000000;
      font-weight: 500;
      font-family: "Roboto";
      margin: 8px;
    }

    .red {
      color: #e31111;
    }

    .moreSpace {
      height: 20px;
    }
  }
}

.topLine {
  border-top: 1px solid #d2d4e7;
}