.carousel {
  width: 100%;
}

.carouselBig {
  width: 100%;
}

.carouselSmall {
  width: 100%;
}

.arrow {
  background-image: url("../../../../assets/img/other/arrowRightGray.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 25px;
  outline: unset;
  border: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  cursor: pointer;
  transition: 0.5s opacity 0.5s transform;
}

.arrow:disabled {
  opacity: 0;
  pointer-events: none;
}

.leftArrowThum {
  left: -25px;
  transform: rotate(180deg) translateY(50%);
  transition: transform 0.5s;

  &:hover {
    transform: rotate(180deg)translateY(50%) scale(1.2);
  }
}

.rightArrowThum {
  right: -25px;
  transition: transform 0.5s;

  &:hover {
    transform: scale(1.2) translateY(-40%);
  }
}

@media screen and (max-width: 1000px) {
  .carouselSmall {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 900px) {
  .carouselBig {
    width: calc(100% - 40px);
  }
}