html  {
  height: 100%;
}

body {
  height: 100%;
  display: flex;
  margin: 0 auto;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  width: 100%;
  background-color: white;
}

#root {
  width: 100%;
}

* {
  outline: 0;
  box-sizing: border-box;
  line-height: 1.2;
  font-family: "Roboto", sans-serif;
}

*:hover {
  outline: 0;
}

*:focus {
  outline: 0;
}

a {
  outline: 0;
  text-decoration: unset;
}

a:hover, a:active, a:focus {
  outline: 0;
}

input {
  outline: 0;
}

input:hover, input:active, input:focus {
  outline: 0;
}

button {
  outline: 0;
}

button:hover, button:active, button:focus {
  outline: 0;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Italic.eot');
  src: url('../fonts/Roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Italic.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Italic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Black.eot');
  src: url('../fonts/Roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Black.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Black.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-LightItalic.eot');
  src: url('../fonts/Roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-LightItalic.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-LightItalic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Thin.eot');
  src: url('../fonts/Roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Thin.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Thin.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Medium.eot');
  src: url('../fonts/Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Medium.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Bold.eot');
  src: url('../fonts/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Bold.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-ThinItalic.eot');
  src: url('../fonts/Roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-ThinItalic.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-ThinItalic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BoldItalic.eot');
  src: url('../fonts/Roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-BoldItalic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BlackItalic.eot');
  src: url('../fonts/Roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-BlackItalic.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-BlackItalic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-MediumItalic.eot');
  src: url('../fonts/Roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-MediumItalic.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-MediumItalic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Light.eot');
  src: url('../fonts/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Light.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Light.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.eot');
  src: url('../fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Regular.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Encode Sans';
  src: url('../fonts/EncodeSans/EncodeSans-Black.eot');
  src: url('../fonts/EncodeSans/EncodeSans-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EncodeSans/EncodeSans-Black.woff2') format('woff2'),
  url('../fonts/EncodeSans/EncodeSans-Black.woff') format('woff'),
  url('../fonts/EncodeSans/EncodeSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Encode Sans';
  src: url('../fonts/EncodeSans/EncodeSans-Bold.eot');
  src: url('../fonts/EncodeSans/EncodeSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EncodeSans/EncodeSans-Bold.woff2') format('woff2'),
  url('../fonts/EncodeSans/EncodeSans-Bold.woff') format('woff'),
  url('../fonts/EncodeSans/EncodeSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Encode Sans';
  src: url('../fonts/EncodeSans/EncodeSans-ExtraBold.eot');
  src: url('../fonts/EncodeSans/EncodeSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EncodeSans/EncodeSans-ExtraBold.woff2') format('woff2'),
  url('../fonts/EncodeSans/EncodeSans-ExtraBold.woff') format('woff'),
  url('../fonts/EncodeSans/EncodeSans-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Encode Sans';
  src: url('../fonts/EncodeSans/EncodeSans-Regular.eot');
  src: url('../fonts/EncodeSans/EncodeSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EncodeSans/EncodeSans-Regular.woff2') format('woff2'),
  url('../fonts/EncodeSans/EncodeSans-Regular.woff') format('woff'),
  url('../fonts/EncodeSans/EncodeSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Encode Sans';
  src: url('../fonts/EncodeSans/EncodeSans-Medium.eot');
  src: url('../fonts/EncodeSans/EncodeSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EncodeSans/EncodeSans-Medium.woff2') format('woff2'),
  url('../fonts/EncodeSans/EncodeSans-Medium.woff') format('woff'),
  url('../fonts/EncodeSans/EncodeSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Encode Sans';
  src: url('../fonts/EncodeSans/EncodeSans-Thin.eot');
  src: url('../fonts/EncodeSans/EncodeSans-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EncodeSans/EncodeSans-Thin.woff2') format('woff2'),
  url('../fonts/EncodeSans/EncodeSans-Thin.woff') format('woff'),
  url('../fonts/EncodeSans/EncodeSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Encode Sans';
  src: url('../fonts/EncodeSans/EncodeSans-Light.eot');
  src: url('../fonts/EncodeSans/EncodeSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EncodeSans/EncodeSans-Light.woff2') format('woff2'),
  url('../fonts/EncodeSans/EncodeSans-Light.woff') format('woff'),
  url('../fonts/EncodeSans/EncodeSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Encode Sans';
  src: url('../fonts/EncodeSans/EncodeSans-SemiBold.eot');
  src: url('../fonts/EncodeSans/EncodeSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EncodeSans/EncodeSans-SemiBold.woff2') format('woff2'),
  url('../fonts/EncodeSans/EncodeSans-SemiBold.woff') format('woff'),
  url('../fonts/EncodeSans/EncodeSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Encode Sans';
  src: url('../fonts/EncodeSans/EncodeSans-ExtraLight.eot');
  src: url('../fonts/EncodeSans/EncodeSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EncodeSans/EncodeSans-ExtraLight.woff2') format('woff2'),
  url('../fonts/EncodeSans/EncodeSans-ExtraLight.woff') format('woff'),
  url('../fonts/EncodeSans/EncodeSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro/MyriadPro-Bold.eot');
  src: url('../fonts/MyriadPro/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MyriadPro/MyriadPro-Bold.woff2') format('woff2'),
      url('../fonts/MyriadPro/MyriadPro-Bold.woff') format('woff'),
      url('../fonts/MyriadPro/MyriadPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro/MyriadPro-BoldCond.eot');
  src: url('../fonts/MyriadPro/MyriadPro-BoldCond.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MyriadPro/MyriadPro-BoldCond.woff2') format('woff2'),
      url('../fonts/MyriadPro/MyriadPro-BoldCond.woff') format('woff'),
      url('../fonts/MyriadPro/MyriadPro-BoldCond.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro/MyriadPro-Cond.eot');
  src: url('../fonts/MyriadPro/MyriadPro-Cond.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MyriadPro/MyriadPro-Cond.woff2') format('woff2'),
      url('../fonts/MyriadPro/MyriadPro-Cond.woff') format('woff'),
      url('../fonts/MyriadPro/MyriadPro-Cond.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro/MyriadPro-BoldIt.eot');
  src: url('../fonts/MyriadPro/MyriadPro-BoldIt.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MyriadPro/MyriadPro-BoldIt.woff2') format('woff2'),
      url('../fonts/MyriadPro/MyriadPro-BoldIt.woff') format('woff'),
      url('../fonts/MyriadPro/MyriadPro-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro/MyriadPro-Light.eot');
  src: url('../fonts/MyriadPro/MyriadPro-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MyriadPro/MyriadPro-Light.woff2') format('woff2'),
      url('../fonts/MyriadPro/MyriadPro-Light.woff') format('woff'),
      url('../fonts/MyriadPro/MyriadPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro/MyriadPro-Regular.eot');
  src: url('../fonts/MyriadPro/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MyriadPro/MyriadPro-Regular.woff2') format('woff2'),
      url('../fonts/MyriadPro/MyriadPro-Regular.woff') format('woff'),
      url('../fonts/MyriadPro/MyriadPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro/MyriadPro-BoldCondIt.eot');
  src: url('../fonts/MyriadPro/MyriadPro-BoldCondIt.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MyriadPro/MyriadPro-BoldCondIt.woff2') format('woff2'),
      url('../fonts/MyriadPro/MyriadPro-BoldCondIt.woff') format('woff'),
      url('../fonts/MyriadPro/MyriadPro-BoldCondIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro/MyriadPro-Semibold.eot');
  src: url('../fonts/MyriadPro/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MyriadPro/MyriadPro-Semibold.woff2') format('woff2'),
      url('../fonts/MyriadPro/MyriadPro-Semibold.woff') format('woff'),
      url('../fonts/MyriadPro/MyriadPro-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro/MyriadPro-CondIt.eot');
  src: url('../fonts/MyriadPro/MyriadPro-CondIt.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MyriadPro/MyriadPro-CondIt.woff2') format('woff2'),
      url('../fonts/MyriadPro/MyriadPro-CondIt.woff') format('woff'),
      url('../fonts/MyriadPro/MyriadPro-CondIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro/MyriadPro-SemiboldIt.eot');
  src: url('../fonts/MyriadPro/MyriadPro-SemiboldIt.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MyriadPro/MyriadPro-SemiboldIt.woff2') format('woff2'),
      url('../fonts/MyriadPro/MyriadPro-SemiboldIt.woff') format('woff'),
      url('../fonts/MyriadPro/MyriadPro-SemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}