.container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px;
}

.mainContainer {
    max-width: 1600px;
    width: 100%;
    display: flex;
}

.main {
    max-width: 1450px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    column-gap: 40px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    padding: 15px;
    padding-bottom: 150px;
    position: relative;
}

.vertical {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.verticalLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 60px;
}

.verticalRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text1 {
    min-height: 20px;
    font-size: 16px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 500;
    font-family: "Roboto";
    margin: 25px 0 5px;
}

.text2 {
    min-height: 20px;
    font-size: 16px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    margin: 5px 0;
}

.text3 {
    max-width: 800px;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";

    a {
        font-size: 14px;
        letter-spacing: 0px;
        color: #ff0000;
        font-weight: 400;
        font-family: "Roboto";
    }
}

.error {
    min-height: 20px;
    font-size: 16px;
    letter-spacing: 0px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Roboto";
    margin: 5px 0;
    transition: 0.3s all ease-in-out;
    user-select: none;
    opacity: 0;
}

.red {
    color: #ff0000;
}

.opacityFull{
    opacity: 1;
}

.inputContainer {
    display: flex;
    align-items: center;
    max-width: 400px;
    width: 100%;
    min-height: 50px;
    border-radius: 15px;
    background-color: #ffffff;
    border: 1px solid #b2b2b2;
    margin: 4px 0;
    position: relative;

    .labelContainer {
        display: flex;
        position: absolute;
        padding: 0 15px;
        opacity: 1;
        transition: 0.5s all ease-in-out;
        pointer-events: none;
    }

    .text,
    .textReq {
        font-size: 16px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        animation: show 0.5s forwards;
        opacity: 0.8;
        user-select: none;
    }

    .textReq::after {
        content: " *";
        color: #ff0000;
    }
}

.input {
    all: unset;
    max-width: 400px;
    width: 100%;
    min-height: 50px;
    border-radius: 15px;
    padding: 0 15px;
    pointer-events: auto;
}

.input::placeholder {
    all: unset;
    font-size: 16px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    opacity: 0.8;
    -webkit-text-security: initial;
}

.borderRed {
    border: 1px solid #ff0000;
}

.iconOk {
    display: none;
    width: 14px;
    height: 11px;
    background-image: url("../../../assets/img/Login/iconOk.png");
    background-size: contain;
    background-repeat: no-repeat;
    user-select: none;
    position: absolute;
    right: 20px;
}

.iconShow {
    display: inline;
}

.checkboxRound {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: #ffffff;
    border: 1px solid #000000;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkboxRound:checked {
    background-color: gray;
}

.checkboxNormal {
    width: 21px;
    height: 21px;
    background-color: #ffffff;
    border: 1px solid #b2b2b2;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkboxNormal:checked {
    background-color: gray;
}

.horizontal,
.horizontalMain,
.horizontalBottom {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 20px;
}

.colorRed {
    background-color: #ff0000 !important;
}

.horizontalCenter {
    max-width: 400px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.padding {
    padding-left: 60px;
}

.maxHeight {
    height: 100%;
}

.center {
    justify-content: center;
    align-items: center;
}

.myLimitWidht {
    max-width: 400px;
}

.paddingSmall {
    padding-left: 15px;
}

.title {
    display: flex;
    align-items: center;
    font-size: 24px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 500;
    font-family: "Roboto";
    margin: 35px 0 0;

    &::before {
        content: "";
        width: 58px;
    }
}

.titleWithImage {
    display: flex;
    align-items: center;
    font-size: 24px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 500;
    font-family: "Roboto";
    margin: 35px 0 0;

    &::before {
        content: "";
        width: 46px;
        height: 43px;
        background-image: url("../../../assets/img/Cart/fast-cart.svg");
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 12px;
        flex-shrink: 0;
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    min-width: 200px;
    width: calc(50% - 20px);
    height: 61px;
    border-radius: 20px;
    background-color: #f3f3f3;
    border: 1px solid #d9d9d9;

    font-size: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #141414;
    font-weight: 500;
    font-family: "Roboto";
    user-select: none;
    cursor: pointer;
    transition: 0.3s all ease-in-out;

    position: absolute;
    bottom: 30px;

    &:hover {
        box-shadow: 1px 1px 10px rgba(#000000, 0.2);
    }
}

.right {
    right: 30px;
}

.left {
    left: 30px;
}

.hide {
    display: none;
}

.searchList {
    width: 100%;
    height: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 48px;
    left: 0px;
    background-color: #ffffff;
    overflow-y: hidden;
    transition: 0.5s all ease-in-out;
    z-index: 110;
}

.searchListOpen {
    height: 300px;
}

.searchElement {
    font-size: 16px;
    color: #505050;
    font-weight: 400;
    font-family: "Roboto";
    user-select: none;
    transition: 0.3s all ease-in-out;
    background-color: transparent;
    padding: 5px 15px;
    z-index: 110;
}

.searchElement:nth-child(odd) {
    background-color: #fafafa81;

    &:hover {
        background-color: #ff8080;
    }
}

.searchElement:nth-child(even) {
    background-color: #fefefe65;

    &:hover {
        background-color: #ff8080;
    }
}

@media screen and (max-width: 1000px) {
    .horizontal {
        flex-direction: column;
    }

    .horizontalMain {
        justify-content: space-between;
    }

    .horizontalBottom {
        padding: 0;
    }

    .verticalLeft {
        padding: 0;
    }

    .verticalRight {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .horizontalMain {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .verticalLeft {
        align-items: center;
    }

    .verticalRight {
        align-items: center;
    }

    .vertical {
        align-items: center;
        justify-content: center;
    }

    .btn{
        width: 100%;
        position: relative;
        bottom: unset;
        margin: 15px 0;
    }
}