.menuContainer {
    display: none;
    position: absolute;
    width: 100%;
    max-width: 410px;
    height: 100%;
    left: 0;
}

.filterMenuContainer {
    display: flex;
    flex-flow: row;
    width: 100%;
    max-width: 410px;
    overflow: hidden;
    z-index: 1020;
    height: 100vh;
    pointer-events: all;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.filterButton {
    background-image: url("../../assets/img/buttons/sort.png");
    background-size: 32px 28px;
    background-repeat: no-repeat;
    background-position: center;
    width: 32px;
    height: 28px;
    padding: 25px;
    border-radius: 15px;
    background-color: #efefef;
    border: 1px solid #e5e5e5;
    cursor: pointer;

    position: absolute;
    right: 0px;
    top: 0;
    z-index: 2000;
    margin-top: 10px;
    opacity: 0.6;
    transition: 1.0s opacity;
    user-select: none;

    &:hover {
        opacity: 1;
    }
}

.filterMenu {
    width: 100%;
    max-width: 350px;
    height: 100vh;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    user-select: none;
    padding: 0 15px;

    .menu {
        display: flex;
        flex-direction: column;

        width: 100%;
        height: auto; //462px;
        border-radius: 5px;
        background-color: #ffffff;

        margin-bottom: 20px;
        white-space: nowrap;

        margin-bottom: 20px;
        padding: 20px;

        .menuTitle {
            font-size: 18px;
            letter-spacing: 0px;
            text-transform: uppercase;
            color: #000000;
            font-weight: 500;
            font-family: "Roboto";
            margin-bottom: 15px;
        }

        .point {
            width: 100%;
            display: flex;
            flex-direction: column;

            .myLink {
                width: auto;
                display: inline-block;
                font-size: 16px;
                letter-spacing: 0px;
                line-height: 30px;
                color: #141414;
                font-weight: 400;
                font-family: "Roboto";
                cursor: pointer;
                text-decoration: underline;
                text-decoration-color: transparent;
                transition: 0.3s all ease-in-out;

                &:hover {
                    text-decoration-color: #141414;
                }
            }

            .title {
                width: 100%;
                display: flex;
                white-space: nowrap;
                font-size: 16px;
                letter-spacing: 0px;
                line-height: 30px;
                color: #141414;
                font-weight: 400;
                font-family: "Roboto";
                cursor: pointer;

                .char {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    font-size: 16px;
                    letter-spacing: 0px;
                    line-height: 24px;
                    color: #e1131a;
                    font-weight: 250;
                    font-family: "Roboto";
                    margin-right: 10px;

                    .open {
                        &::before {
                            content: "-";
                            padding-right: 2px;
                        }

                    }

                    .close {
                        &::before {
                            content: "+";
                        }
                    }
                }
            }

            .titleOpen {
                color: #e0131a;
                font-weight: 500;
            }

            .titleClose {
                color: #141414;
            }

            .subPoints {
                display: flex;
                flex-flow: column;
                width: calc(100% - 5px);
                margin-left: 5px;
                align-items: flex-start;
                transition: height 0.5s ease-out;
                height: 0;
                overflow: hidden;

                .subPoint {
                    font-size: 16px;
                    letter-spacing: 0px;
                    line-height: 30px;
                    color: #292929;
                    font-weight: 400;
                    font-family: "Roboto";
                    cursor: pointer;
                }
            }
        }
    }

    .menuBorder {
        border: 1px solid #e5e5e5;
    }

    .menuMobile {
        border-bottom: 1px solid #e5e5e5;
        padding: 20px 5px;
    }

    .filter {
        width: 100%;

        .fTitle {
            font-size: 18px;
            letter-spacing: 0px;
            text-transform: uppercase;
            color: #141414;
            font-weight: 400;
            font-family: "Roboto";
            margin-left: 5px;
        }

        .filterContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            padding-left: 10px;
            padding-right: 10px;

            .category {
                margin-top: 5px;
                padding-bottom: 5px;
                margin-bottom: 5px;
                border-bottom: 1px solid #e0e0e0;

                .title {
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #141414;
                    font-weight: 500;
                    font-family: "Roboto";
                    cursor: pointer;
                }

                .categoryContainer {
                    display: flex;
                    flex-wrap: wrap;
                    overflow-y: hidden;
                    height: 30px; //195px;
                    max-height: 195px;
                    overflow-x: hidden;
                    transition: 1.0s height ease-in-out;

                    .categoryElement {
                        width: calc(100%/2 - 5px);
                        margin-top: 5px;
                    }
                }
            }

            .price {
                width: 100%;
                display: flex;
                flex-direction: column;

                .pTitle {
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #141414;
                    font-weight: 400;
                    font-family: "Roboto";
                }

                .slider {
                    width: calc(100% - 10px);
                    margin-bottom: 10px;

                    .someText {
                        font-size: 14px;
                    }
                }
            }

            .filtersButton {
                width: 100%;
                display: flex;
                margin-top: 15px;
                margin-bottom: 30px;
                user-select: none;
            }
        }
    }
}

.filterTransition {
    transition: all 1.0s ease-in-out;
}

.show {
    display: block;

}

.menuOpen {
    pointer-events: all;
    transform: translate(0px, 0);
}

.menuClose {
    transform: translate(-350px, 0);
}

.buttonOpen {
    right: 10px;
    margin: unset;
    opacity: 1;
}

.buttonClose {
    right: 0px;
    margin-top: 10px;
}

@media screen and (max-width: 1000px) {
    .menuContainer {
        display: flex;
    }
}

@media screen and (max-width: 410px) {
    .filterMenuContainer {
        width: 100%; //310px;
    }

    .filterMenu {
        max-width: 360px;
        width: calc(100vw - 50px); //270px;

        .menu {
            font-size: 14px;

            .point {
                .title {
                    font-size: 14px;
                }

                .subPoints {
                    font-size: 14px;
                }
            }
        }

        .filter {
            .fTitle {
                font-size: 16px;
            }
        }
    }

    .filterButton {
        background-size: 22px 19px;
        width: 22px; //32px;
        height: 19px; //28px;
        padding: 19px;
        border-radius: 15px;
        //right: -5px;
    }

    .menuClose {
        transform: translate(calc((100vw - 50px) * -1), 0);
    }
}