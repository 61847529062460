.container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px;

    .mainContainer {
        max-width: 1600px;
        width: 100%;
        display: flex;

        .list {
            max-width: 1450px;
            width: 100%;
            display: flex;
            flex-direction: column;
            column-gap: 40px;
            border-radius: 10px;
            background-color: #ffffff;
            border: 1px solid #eeeeee;
            padding: 15px;
            position: relative;
        }

        .element {
            width: 100%;
            display: flex;
            border-radius: 10px;
            background-color: #ffffff;
            border: 1px solid #eeeeee;
            padding: 15px;
            margin: 0 0 15px 0;

            .table {
                width: 100%;
                display: flex;
                flex-direction: column;

                .tableRow {
                    display: flex;
                    justify-content: space-between;
                }

                .cell {
                    display: flex;
                    flex-direction: column;
                }

                .cellNormal {
                    font-size: 16px;
                    color: #2a2a2a;
                    font-weight: 400;
                    font-family: "Roboto";
                }

                .cellBold {
                    font-size: 16px;
                    color: #2a2a2a;
                    font-weight: 500;
                    font-family: "Roboto";
                    margin: 0 0 5px 0;
                }

                .cellCenter {
                    display: flex;
                    justify-content: center;
                }

                .cell1 {
                    max-width: 150px;
                    width: 100%;
                    text-align: center;
                }

                .cell2 {
                    max-width: 150px;
                    width: 100%;
                    text-align: center;
                }

                .cell3 {
                    max-width: 150px;
                    width: 100%;
                    text-align: center;
                }

                .cell4 {
                    max-width: 400px;
                    width: 100%;
                    text-align: center;
                }

                .cell5 {
                    max-width: 150px;
                    width: 100%;
                    text-align: center;
                }

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    height: 30px;
                    border-radius: 20px;
                    background-color: #f3f3f3;
                    border: 1px solid #d9d9d9;
                    font-size: 14px;
                    letter-spacing: 0px;
                    line-height: 18px;
                    color: #141414;
                    font-weight: 500;
                    font-family: "Roboto";
                    -webkit-user-select: none;
                    user-select: none;
                    cursor: pointer;
                    transition: 0.3s all ease-in-out;
                    padding: 5px 10px;
                }
            }
        }
    }
}



.container {
    width: 100%;
    display: flex;
    padding: 15px 0;
}

.main {
    max-width: 1230px;
    width: 100%;
    min-height: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 40px;
            border-radius: 10px;
            background-color: #ffffff;
            border: 1px solid #eeeeee;
            padding: 15px;
            position: relative;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        column-gap: 40px;

        .left {
            max-width: 450px;
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .right {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .column {
            display: flex;
            flex-direction: column;
        }

        .adress {
            display: flex;
            justify-content: space-between;
            margin: 20px 0;
        }

        .tableContainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 10px;
            margin-top: 20px;
        }

        .item {
            height: 100px;
            display: flex;
            align-items: center;

            &:not(:last-child) {
                border-bottom: 1px solid #eeeeee;
            }

            .icon {
                width: 80px;
                height: 80px;
                display: flex;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin: 0px 30px 0px 20px;
                flex-shrink: 0;
                user-select: none;
                cursor: pointer;
            }

            .descriptionContainer {
                width: 100%;
                display: flex;
                flex-direction: column;

                .ultraSmallText {
                    font-size: 12px;
                    letter-spacing: 0px;
                    color: #6a6a6a;
                    font-weight: 400;
                    font-family: "Roboto";
                }
            }

            .totalPrice {
                width: 200px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
                font-family: "Roboto";
                border-left: 1px solid #eeeeee;
                border-radius: 10px;
            }
        }

        .summary {
            display: flex;
            justify-content: space-between;
            padding: 25px 15px 0px 25px;

            .endText {
                font-size: 16px;
                letter-spacing: 0px;
                color: #000000;
                font-family: "Myriad Pro";
            }
        }

        .total {
            display: flex;
            justify-content: space-between;
            background-color: #fbfbfb;
            padding: 0px 15px 0px 25px;
            margin-top: 25px;
        }
    }

    .containerBottom {
        height: 170px;
        display: flex;
        justify-content: flex-end;
        margin-top: 35px;
    }
}

@media screen and (max-width: 800px) {
    .top {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 500px) {
    .main {
        .top {
            .item {
                height: auto !important;
                flex-direction: column;
                padding: 5px 15px;

                .descriptionContainer {
                    margin: 15px 0;
                }

                .totalPrice {
                    width: 100%;
                    border-left: unset !important;
                    justify-content: flex-end;
                }
            }
        }
    }
}