.container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px;
    appearance: none;
    box-shadow: inset 2.5px 4.33px 2px rgba(0, 0, 0, 0.04);
}

.main {
    max-width: 1570px;
    width: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 40px;
    padding: 0 15px;
    position: relative;
}

.breadcrumb {
    padding: 15px 0 40px;
}

.nav {
    max-width: 855px;
    width: 100%;
    display: flex;

    .item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 3px solid #eeeeee;

        .text {
            font-size: 16px;
            letter-spacing: 0px;
            color: #7f7e7e;
            font-weight: 500;
            font-family: "Roboto";
            padding-bottom: 15px;
            user-select: none;
            cursor: pointer;
        }

        .deactivate {
            color: #9b9b9b;
            opacity: 0.5;
        }
    }

    .active {
        border-bottom: 3px solid #f3070e;
    }

    .active .text {
        color: #000000;
    }
}

.loginContainer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.endContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
        font-size: 24px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 500;
        font-family: "Roboto";
        padding-bottom: 15px;
        user-select: none;
    }
}

.cover1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    position: absolute;
    opacity: 1;
    z-index: 100;
    padding-top: 375px;
    top: 0px;
    left: 0;
}

.cover2 {
    width: 100%;
    height: calc(100% - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    opacity: 1;
    z-index: 100;
    top: 120px;
    left: 0;
}

.cover3 {
    width: calc(100% + 30px);
    height: calc(100% - 120px);
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    position: absolute;
    opacity: 1;
    z-index: 10000;
    top: 120px;
    left: -15px;
    padding-top: 200px;
}

.cover4 {
    width: 100%;
    height: calc(100% - 120px);
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    position: absolute;
    // transition: 1.0s opacity ease-in-out;
    opacity: 1;
    z-index: 100;
    top: 120px;
    padding-top: 200px;
    left: 0;
}

.coverShow {
    display: flex;
}

.opacityFull {
    opacity: 1;
}

.opacityNone {
    opacity: 0;
}

.opacityTransition {
    transition: 0.3s opacity ease-in-out;
}

.hide {
    display: none;
}

@media screen and (max-width: 600px) {
    .main {
        padding: 0;
    }
}

@media screen and (max-width: 500px) {
    .nav {
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 25px;

        .item {
            width: auto;
            margin: auto auto auto 0;
        }
    }

    .cover1 {
        padding-top: 115px;
    }

    .cover3{
        top: 300px;
        padding-top: 110px;
    }

    .cover4{
        top: 300px;
        padding-top: 110px;
    }
}