.wrapper {
  width: 100%;
  max-width: 1262px;
  display: flex;
  flex-flow: row;
  align-items: center;
  user-select: none;

  .separator {    
    display: flex;
    width: 7px;
    height: 10px;
    background-image: url("../../assets/img/other/arrowSmallGray.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 8px;
    margin-right: 8px;
  }

  //with link - back to main
  a {
    font-size: 14px;
    letter-spacing: 0px;
    color: #222222;
    font-weight: 500;
    font-family: "Roboto";
  }

  //first, midle
  .breadcrumb {
    display: flex;
    align-items: center;

    font-size: 14px;
    letter-spacing: 0px;
    color: #222222;
    font-weight: 500;
    font-family: "Roboto";
  }

  //last
  .lastItem {
    font-size: 14px;
    letter-spacing: 0px;
    color: #626262;
    font-weight: 500;
    font-family: "Roboto";
  }
}