.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 40px;
}

.main {
    max-width: 1377px;
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    .title {
        font-size: 18px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        margin-bottom: 30px;
    }
}

.cover {
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    background-color: #ffffff;
    padding-top: 75px;
    transition: 1.0s opacity ease-in-out;
    opacity: 0;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
}

.coverShow {
    display: flex;
}

.opacityFull {
    opacity: 1;
}

.labelContainer {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 30px;
    margin-bottom: 15px;
    flex-shrink: 1;

    .labelText {
        font-size: 14px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
    }

    .labelText:nth-child(1) {
        max-width: 655px;
        width: 100%;
    }

    .labelText:nth-child(2) {
        max-width: 240px;
        width: 100%;
    }

    .labelText:nth-child(3) {
        max-width: 320px;
        width: 100%;
    }

    .labelText:nth-child(4) {
        max-width: 130px;
        width: 100%;
    }
}

.tableContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;
}

.item {
    max-height: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    row-gap: 15px;
    position: relative;
    overflow: hidden;
    transition: 0.3s all ease-in-out;

    &:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
    }

    .icon {
        width: 80px;
        height: 80px;
        display: flex;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: 40px 30px 40px 20px;
        flex-shrink: 0;
        user-select: none;
        cursor: pointer;
    }

    .descriptionContainer {
        max-width: 550px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .text {
            font-size: 18px;
            letter-spacing: 0px;
            color: #000000;
            font-weight: 400;
            font-family: "Roboto";
        }

        .ultraSmallText {
            font-size: 12px;
            letter-spacing: 0px;
            color: #6a6a6a;
            font-weight: 400;
            font-family: "Roboto";
        }

        .amountBig {
            font-size: 12px;
            line-height: 18px;
            color: #279439;
            font-weight: 400;
            font-family: "Roboto";
            display: flex;
            align-items: center;

            &::before {
                content: "";
                height: 10px;
                width: 10px;
                background-color: #279439;
                border-radius: 50%;
                margin-right: 5px;
                display: inline-block;
            }
        }

        .amountSmall {
            font-size: 12px;
            line-height: 18px;
            color: #ff8502;
            font-weight: 400;
            font-family: "Roboto";
            display: flex;
            align-items: center;

            &::before {
                content: "";
                height: 10px;
                width: 10px;
                background-color: #ff8502;
                border-radius: 50%;
                margin-right: 5px;
                display: inline-block;
            }
        }

        .amountNone {
            font-size: 12px;
            line-height: 18px;
            color: #e1131a;
            font-weight: 400;
            font-family: "Roboto";
            display: flex;
            align-items: center;

            &::before {
                content: "";
                height: 8px;
                width: 8px;
                background-color: #fff;
                border: 1px solid #e1131a;
                border-radius: 50%;
                margin-right: 5px;
                display: inline-block;
            }
        }
    }

    .price {
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        margin-right: 70px;
        flex-shrink: 0;
    }

    .totalPrice {
        width: 200px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 500;
        font-family: "Roboto";
        border-left: 1px solid #eeeeee;
        border-radius: 10px;
        flex-shrink: 0;
    }

    .counterContainer {
        display: flex;
        column-gap: 10px;

        .btnContainer {
            width: 20px;
            display: flex;
        }

        .btnValue {
            width: 20px;
            font-size: 30px;
            letter-spacing: 0px;
            color: #7b7b7b;
            font-weight: 500;
            font-family: "Roboto";
            text-align: center;
            user-select: none;
            cursor: pointer;
        }

        .counter {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90px;
            height: 40px;
            border-radius: 10px;
            background-color: #ffffff;
            border: 1px solid #eeeeee;
        }

        .textStrong {
            font-size: 18px;
            letter-spacing: 0px;
            line-height: 18px;
            color: #000000;
            font-weight: 500;
            font-family: "Roboto";
            text-align: center;
        }
    }

    .btnTrash {
        width: 21px;
        height: 22px;
        background-image: url("../../../assets/img/Cart/trash.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 90px;
        margin-right: 40px;
        flex-shrink: 0;
        cursor: pointer;
        user-select: none;
        transition: 0.3s all ease-in-out;

        &:hover {
            transform: scale(1.1);
        }
    }
}

.bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left {
        max-width: 400px;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 35px;

        .placeForCode {
            min-height: 100px;
        }

        .input {
            height: 50px;
            max-width: 360px;
            width: 100%;
            border-radius: 11px;
            background-color: #ffffff;
            border: 1px solid #b1b1b1;
            font-size: 16px;
            letter-spacing: 0px;
            line-height: 18px;
            color: #000000;
            font-weight: 400;
            font-family: "Roboto";
            padding: 0px 25px;
        }

        .input::placeholder {
            font-size: 16px;
            letter-spacing: 0px;
            line-height: 18px;
            color: #000000;
            font-weight: 400;
            font-family: "Roboto";
            opacity: 1;
        }
    }

    .right {
        max-width: 375px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .top {
            display: flex;
            justify-content: space-between;
            column-gap: 25px;

            .columnLeft {
                display: flex;
                flex-direction: column;

                .text1 {
                    font-size: 16px;
                    letter-spacing: 0px;
                    color: #000000;
                    font-family: "Myriad Pro";
                    margin: 15px 0 0;
                }

                .text2 {
                    font-size: 16px;
                    letter-spacing: 0px;
                    color: #000000;
                    font-family: "Myriad Pro";
                    margin: 10px 0 15px;
                }

                .text3 {
                    font-size: 24px;
                    letter-spacing: 0px;
                    color: #000000;
                    font-weight: 500;
                    font-family: "Roboto";
                    margin: 15px 0;
                }
            }

            .columnRight {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .text1 {
                    font-size: 20px;
                    letter-spacing: 0px;
                    color: #000000;
                    font-weight: 500;
                    font-family: "Roboto";
                    margin: 15px 0 16px;
                }

                .text2 {
                    font-size: 20px;
                    letter-spacing: 0px;
                    color: #000000;
                    font-weight: 500;
                    font-family: "Roboto";
                    margin: 10px 0;
                }

                .text3 {
                    font-size: 24px;
                    letter-spacing: 0px;
                    color: #000000;
                    font-weight: 500;
                    font-family: "Roboto";
                    margin: 15px 0;
                }
            }
        }

    }
}

.btnPrev {
    max-width: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 60px;
    user-select: none;
    cursor: pointer;

    p {
        font-size: 18px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        margin: 0 0 10px 5px;
    }

    &::before {
        content: " ";
        width: 8px;
        height: 16px;
        background-image: url("../../../assets/img/Cart/btnBackBlack.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: - 15px;
    }

}

.btnGrey {
    //all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 400px;
    min-width: 180px;
    width: 100%;
    max-height: 60px;
    height: 100%;
    border-radius: 20px;
    background-color: #f3f3f3;
    border: 1px solid #d9d9d9;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #141414;
    font-weight: 500;
    font-family: "Roboto";
    user-select: none;
    cursor: pointer;
    margin: 5px 0;
    transition: 0.5s all ease-in-out;

    &:hover {
        box-shadow: 2px 2px 15px rgba(#000000, 0.1);
    }
}

@media screen and (max-width: 1100px) {
    .labelContainer {
        display: none;
    }

    .item {
        height: 320px;
        max-height: unset;
        flex-direction: column;
        padding: 15px;

        .icon {
            margin: 0 0;
        }

        .price {
            margin-right: 0;
        }

        .btnTrash {
            margin-left: 0;
            margin-right: 0;
            position: absolute;
            right: 15px;
            bottom: 15px;
        }

        .totalPrice {
            height: auto;
            border-left: unset;
        }
    }
}

@media screen and (max-width: 830px) {
    .bottom {
        flex-wrap: wrap;
        justify-content: center;

        .left {
            max-width: unset;
            align-items: center;
            padding: 0;
        }

        .right {
            max-width: unset;
        }
    }

    .btnPrev {
        margin-top: 0;
    }
}

@media screen and (max-width: 600px) {
    .btnPrev {
        margin-left: 10px;

        p {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 400px) {
    .item {
        .descriptionContainer {
            .text {
                font-size: 14px;
            }
        }
    }

    .btnPrev {
        p {
            font-size: 14px;
        }
    }
}